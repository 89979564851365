import DocumentAPI from '@api/documents.js';

const state = {
    document: {},
    documents: [],
    document_types: [],
};
 
const getters = {
    getDocument(state) {
        return state.document;
    },
    getDocuments(state) {
        return state.documents;
    },
    getDocumentTypes(state) {
        return state.document_types;
    },
};

const actions = { 
    async setDocument({commit}, document = {}) {
        commit('setDocument', document)
    },

    // async getDocument({commit}, id) {
    //     await axios.get(`/api/v1/documents/${id}`)
    //         .then( response => {
    //             commit('setDocument', response.data);
    //         })
    // }

    async getDocumentTypes({commit}) {
        await axios.get(`/api/v1/document_types`)
            .then( response => {
                commit('setDocumentTypes', response.data.document_types);
            })
    },

    async deleteDocument({commit}, id) {
        let deleted = await DocumentAPI.delete(id);
    }

    // async deleteDocument({commit, dispatch, state})
    // {
    //     let id = state.document.id;
    //     await axios.delete(`/api/v1/documents/${id}`)
    //         .then( response => {
    //             if(response.data.deleted)
    //             {
    //                 commit('deleteDocument');
    //                 // Remove from appliance documents
    //                 dispatch('appliances/removeDocument', id, {root: true});
    //             }
    //         })
    // }
};

const mutations = {
    setDocument(state, document)
    {
        state.document = document;
    },
    setDocuments(state, documents)
    {
        state.documents = documents;
    },
    setDocumentTypes(state, document_types)
    {
        state.document_types = document_types;
    },
    deleteDocument(state)
    {
        state.document = {};
        // console.log(state.document);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
