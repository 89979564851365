import JobAPI from '@api/project_jobs';

const state = {
    job: {
        project: {},
        rebates: [],
        grants: [],
        loans: [],
        documents: []
    }
};
 
const getters = {
    getJob(state)
    {
        return state.job;
    }
};

const actions = { 
    /**
     * Get a job by id.
     * @return {bool} successful
     */
    async getJob({commit}, id)
    {
        let job = await JobAPI.find(id);
        commit('setJob', job);
        return;
    },

    /**
    * Create a job.
    */
    async createJob({commit}, fields) {
        let job = await JobAPI.create(fields);
        commit('setJob', job);
        return job;
    },

    /**
     * Update a job.
     *
     */
    async updateJob({commit}, fields) {
        let successful = await JobAPI.update(fields.id, fields);
        return successful;
    },
    /**
    * Delete job.
    */
    async deleteJob({commit}, id) {
        let successful = await JobAPI.delete(id);
        return successful;
    },

    /**
    * Attach a grant to an job.
    * @return {bool} successful
    */
    async attachGrant({commit, state}, fields)
    {
        let successful = await JobAPI.attachGrant(state.job.id, fields);
        return successful;
    },
    /**
    * Detach a grant to am job.
    * @return {bool} successful
    */
    async detachGrant({commit, state}, fields)
    {
        let successful = await JobAPI.detachGrant(fields.job_id, fields.grant_id);
        return successful;
    },
    /**
    * Attach a rebate to an job.
    * @return {bool} successful
    */
    async attachRebate({commit, state}, fields)
    {
        let successful = await JobAPI.attachRebate(state.job.id, fields);
        return successful;
    },
    /**
    * Detach a rebate to am job.
    * @return {bool} successful
    */
    async detachRebate({commit, state}, fields)
    {
        let successful = await JobAPI.detachRebate(fields.job_id, fields.rebate_id);
        return successful;
    },

      /**
    * Attach a loan to an job.
    * @return {bool} successful
    */
      async attachLoan({commit, state}, fields)
      {
          let successful = await JobAPI.attachLoan(state.job.id, fields);
          return successful;
      },
      /**
      * Detach a loan to am job.
      * @return {bool} successful
      */
      async detachLoan({commit, state}, fields)
      {
          let successful = await JobAPI.detachLoan(fields.job_id, fields.loan_id);
          return successful;
      },

    /**
    * Create a component for a job.
    */
    async createComponent({commit, state}, fields) {
        let component = await JobAPI.createComponent(state.job.id ,fields);
        commit('addComponent', component);
    },

    /**
    * Delete a component for a job.
    */
    async deleteComponent({commit, state}, id) {
        let successful = await JobAPI.deleteComponent(state.job.id, id);
        
        if(successful) {
            commit('removeComponent', id);
        }

        return successful;
    },

    

    // Document
    addDocument({commit}, document)
    {   
        commit('addDocument', document);
    },
};

const mutations = {
    setJob(state, job = {})
    {
        state.job = job;
    },
    addDocument(state, document)
    {
        state.job.documents.push(document);
    },
    addComponent(state, component)
    {
        state.job.components.push(component);
    },
    removeComponent(state, id)
    {
        state.job.components = state.job.components.filter(component => component.id !== id);
    }
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
