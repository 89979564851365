<template>
    <Modal :name="name" width="600" height="auto" :scrollable="true" @before-open="beforeOpen">

        <div class="p-6 pt-16 md:p-16">
            <div>
            <!-- Title -->
            <SectionTitle>Update Room</SectionTitle>

                <!-- Form -->
                <div id="update-room-form" class="mb-8">

                    <!-- Room Name -->
                    <FormRow>
                        <Label for="name">Room Type</Label>
                        <SelectInput name="name" v-model="form.name" class="2/3">
                            <option v-for="(name, index) in room_names" :key="index" :value="name">{{name}}</option>
                            <option value="Other">Other</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('name')">{{form.errors.first('name')}}</Errors>
                    </FormRow>

                    <!-- Other Name -->
                    <FormRow v-show="form.name == 'Other'">
                        <Label for="other_name">Custom Name</Label>
                        <TextInput name="other_name" v-model="form.other_name" class="2/3" placeholder="ie. Secret Room"/>
                        <Errors v-if="form.errors.has('other_name')">{{form.errors.first('other_name')}}</Errors>
                    </FormRow>

                    <!-- Nickname -->
                    <FormRow>
                        <Label for="nickname">Nickname (Optional)</Label>
                        <TextInput name="nickname" v-model="form.nickname" class="2/3"/>
                        <Errors v-if="form.errors.has('nickname')">{{form.errors.first('nickname')}}</Errors>
                    </FormRow>

                    <!-- Storey -->
                    <FormRow>
                        <Label for="floor_type">Storey</Label>
                        <TextInput name="storey" type="number" v-model="form.storey" class="2/3" placeholder="1"/>
                        <Errors v-if="form.errors.has('storey')">{{form.errors.first('storey')}}</Errors>
                        <p class="text-sm mt-2">Please use 1 for ground-level, 2 for your second level, and -1 for a basement.</p>
                    </FormRow>

                    <!-- Floor Type -->
                    <FormRow>
                        <Label for="floor_type">Floor Type (Optional)</Label>
                        <SelectInput name="floor_type" v-model="form.floor_type" class="2/3">
                            <option v-for="(floor_type, index) in floor_types" :key="index" :value="floor_type">{{floor_type}}</option>
                            <!-- <option value="Other">Other</option> -->
                        </SelectInput>
                        <Errors v-if="form.errors.has('floor_type')">{{form.errors.first('floor_type')}}</Errors>
                    </FormRow>
                    
                </div>

                <!-- Actions -->
                <FormRow>
                    <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                    <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
                </FormRow>
            </div>

            <!-- Delete Form -->
            <div id="delete-room-form">
                <div class="p-4 rounded border border-red-700 border-dashed">
                    <div id="expand-delete" class="flex text-red-700 hover:text-red-900 cursor-pointer" @click="delete_expanded = !delete_expanded">
                        <div class="mr-4">
                            <v-icon class="fill-current" :name="delete_expanded ? 'chevron-up' : 'chevron-down'"></v-icon>
                        </div>
                        <div>
                            Delete
                        </div>
                    </div>
                    
                    <div class="mt-8" v-show="delete_expanded">
                        <div class="mb-6 text-red-900">
                            Do you wish do delete your room? All associations will be reassigned to the property.
                        </div>

                        <FormRow>
                            <square-button id="delete-room-button" context="gray" class="mr-2" @click="deleteRoom">Delete</square-button>
                        </FormRow>

                    </div>
                </div>
            </div>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@property/Forms/UpdateRoomForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'


export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton
    },
    data()
    {
        return {
            name: 'update-room-modal',
            form: new Form(formDefaults, formConfig),
            room_names: ROOM_NAMES,
            delete_expanded: false,
        }
    },
    computed: {
        ...mapGetters({
            room: 'rooms/getRoom',
            property: 'properties/getCurrentProperty',
            floor_types: 'properties/getFloorTypes'
        })
    },
    methods:
    {
        beforeOpen(event)
        {
            if(!this.room_names.includes(this.room.name)) {
                this.room_names.push(this.room.name)
                this.form.other_name = this.room.name
            }

            this.form.name = this.room.name
            this.form.nickname = this.room.nickname
            this.form.storey = this.room.storey
            this.form.floor_type = this.room.floor_type
        },
        deleteRoom()
        {
            let room_name = this.room.name;

            this.$store.dispatch('rooms/deleteRoom')
                .then(() => {

                    console.log(this.property);
                    this.$router.push({name: 'properties.view', params: {id: this.property.id}})

                    this.$notify({
                        title: 'Success',
                        text: `${room_name} has been deleted.`,
                        type: 'success'
                    });

                }).catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
            });      
        },
        submit()
        {
            this.$store.dispatch('rooms/updateRoom', this.form.data())
                .then(() => {
                    // close modal
                    this.$modal.hide(this.name)

                    // notify user
                    this.$notify({
                        title: 'Success',
                        text: `${this.form.nickname || this.form.other_name || this.form.name} has been updated.`,
                        type: 'success'
                    });
                }).catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
    async created() {
        await this.$store.dispatch('properties/getFloorTypes');
    }
}

const ROOM_NAMES = [
    'Foyer',
    'Kitchen',
    'Pantry',
    'Dining Room',
    'Living Room',
    'Parlor',
    'Family Room',
    'Sunroom',
    'Office',
    'Bathroom',
    'Powder Room',
    'Laundry Room',
    'Storage Room',
    'Bedroom',
    'Closet',
    'Theater',
    'Gym',
    'Garage',
    'Attic',
    'Loft'
]

</script>
