import RetrofitProgramTierConditionAPI from '@api/retrofit_program_tier_conditions';

const state = {
    retrofit_program_tier_conditions: [],
    retrofit_program_tier_condition: {
       children: []
    }
};
 
const getters = {
    getRetrofitProgramTierCondition(state)
    {
        return state.retrofit_program_tier_condition;
    },
    getRetrofitProgramTierConditions(state)
    {
        return state.retrofit_program_tier_conditions;
    },

};

const actions = { 

    /**
     * Get all condition.
     */
    // async getRetrofitProgramTierConditions({commit})
    // {
    //     let retrofit_program_tier_conditions = await RetrofitProgramTierConditionAPI.findAll();
    //     commit('setRetrofitProgramTierConditions', retrofit_program_tier_conditions);
    //     return;
    // },

    /**
    * Get a condition.
    */
    // async getRetrofitProgramTierCondition({commit}, id)
    // {
    //     let retrofit_program_tier_condition = await RetrofitProgramTierConditionAPI.find(id);
    //     commit('setRetrofitProgramTierCondition', retrofit_program_tier_condition);
    //     return;
    // },

    /**
     * Create a new retrofit program.
     */
    // async createRetrofitProgramTierCondition({commit}, fields)
    // {
    //     let retrofit_program_tier_condition = await RetrofitProgramTierConditionAPI.create(fields);
    //     commit('setRetrofitProgramTierCondition', retrofit_program_tier_condition);
    //     return;
    // },

    /**
    * Update a retrofit program.
    */
    // async updateRetrofitProgramTierCondition({commit}, {id, fields}) {
    //     let retrofit_program_tier_condition = await RetrofitProgramTierConditionAPI.update(id, fields);
    //     commit('setRetrofitProgramTierCondition', retrofit_program_tier_condition);
    //     return;
    // },

    /**
     * Delete a retrofit program.
     */
    async deleteCondition({commit}, id)
    {
        let successful = await RetrofitProgramTierConditionAPI.delete(id);

        if(successful) 
        {
            commit('setRetrofitProgramTierCondition', {});    
        }

        return;
    },

    /**
    * Create a Grant for a Retrofit Program tier.
    */
    async createGrant({commit}, {id, fields})
    {
        let grant = await RetrofitProgramTierConditionAPI.createGrant(id, fields);

        if(grant.id) {
            commit('addGrant', grant);
        }

        return;
    },

    /**
    * Create a condition for a Retrofit Program tier.
    */
    async createConditionForTier({commit}, {id, fields})
    {
        let condition = await RetrofitProgramTierConditionAPI.createCondition(id, fields);
        return;
        // return condition;
    }
};

const mutations = {
    setRetrofitProgramTierConditions(state, retrofit_program_tier_conditions = [])
    {
        state.retrofit_program_tier_conditions = retrofit_program_tier_conditions;
    },
    setRetrofitProgramTierCondition(state, retrofit_program_tier_condition = {})
    {
        state.retrofit_program_tier_condition = retrofit_program_tier_condition;
    },
    addGrant(state, grant)
    {
        state.retrofit_program_tier_condition.grants.push(grant);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
