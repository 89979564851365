<template>
  <div>
    <!-- Header -->
    <header class="container pl-2 px-4 md:mx-auto py-6">
      <nav class="flex justify-between items-center">

        <!-- Left -->
        <div>
          <router-link to="/" class="flex items-end text-xl text-gray-700 hover:text-orange-700" :class="[homeLinkVisible ? '' : 'md:hidden']">
            <img :src="image('brand/logo-transparent-256.png')" alt="Paddio logo" class="mr-1 h-8">
            <span>Paddio</span>
          </router-link>
        </div>

        <!-- Right -->
        <div>
          <!-- Mobile -->
          <!-- <div class="md:hidden">
            <a v-if="authenticated" href="#" id="navigation-menu-toggle" @click.prevent="toggleNavigation" class="md:hidden">
              <v-icon :name="navigation_visible ? 'times' : 'bars'" class="ml-2" scale="1.5"></v-icon>
            </a>
            <a v-if="!authenticated" id="header-login-link" href="#" :class="styles.text" @click="$modal.show('login-modal')">Login</a>
          </div> -->

          <!-- Desktop -->
          <div>
            <a v-if="!authenticated" id="header-login-link" href="#" :class="styles.text" @click="$router.push({name:'signin'})/*$modal.show('login-modal')*/">Login</a>
            <!-- <router-link v-if="!authenticated" id="header-login-link" :class="styles.text" :to="{name:'signin'}">Login</router-link> -->

            <div v-else class="flex items-center gap-x-4">
              <ShareCard v-if="$route.matched.some(({ name }) => name === 'properties.view')" :propertyId="property.id" class="mr-4"></ShareCard>
              <HeaderNavigationMenu/>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <!-- Login Modal-->
    <LoginModal></LoginModal>

  </div>

</template>

<script>
import LoginModal from '../Auth/Components/LoginModal.vue';
import MobileNavigation from '../views/MobileNavigation.vue';
import { mapGetters } from 'vuex';
import HeaderNavigationMenu from './HeaderNavigationMenu.vue';
import ShareCard from '../Retrofit/Components/ShareCard.vue';

const styles = {
  primary: {
    text: ['text-orange-500 hover:text-orange-700'],
    button: ['bg-orange-500 text-white hover:bg-orange-700 hover:text-orange-500'],
  },
  primary: {
    text: ['text-purple-500 hover:text-purple-700'],
    button: ['bg-purple-500 text-white hover:bg-purple-700 hover:text-purple-500'],
  },
  green:{
    text: ['text-green-900 hover:text-green-600'],
    button: ['bg-green-500 text-white hover:bg-green-600'],
  },
  yellow: {
    text: ['text-yellow-900 hover:text-yellow-600'],
    button: ['bg-yellow-500 text-white hover:bg-yellow-600'],
  },
  orange: {
    text: ['text-gray-900 hover:text-orange-700'],
    button: ['bg-orange-500 text-white hover:bg-orange-600'],
  },
}

export default {
  components: {
    LoginModal,
    MobileNavigation,
    HeaderNavigationMenu,
    ShareCard
  },
  props: {
    homeLinkVisible: {
      default: true
    },
    context: {
      type: String,
      default: 'orange'
    },
  },
  data() {
    return {
      navigation_visible: false,
      styles: []
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/isAuthenticated',
      admin: 'auth/isAdmin',
      currentUser: 'auth/getUser',
      property: 'properties/getProperty',
    })
  },
  methods: {
    toggleNavigation(event)
    {
      this.navigation_visible = !this.navigation_visible
    },
    getStyles() {
      return styles[this.context]
    }
  },
  mounted()
  {
    this.styles = this.getStyles();
  }
}
</script>
