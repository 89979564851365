const state = {
    alert: {}
};
 
const getters = {
    getAlert(state) {
        return state.alert;
    },
};

const actions = { 
    async setAlert({commit}, alert = {}) {
        commit('setAlert', alert)
    },
    async deleteAlert({commit, dispatch, state})
    {
        let id = state.alert.id;
        await axios.delete(`/api/v1/alerts/${id}`)
            .then( response => {
                if(response.data.deleted)
                {
                    commit('deleteAlert');
                    // Remove from appliance alerts
                    dispatch('appliances/removeAlert', id, {root: true});
                }
            })
    }
};

const mutations = {
    setAlert(state, alert)
    {
        state.alert = alert;
    },
    deleteAlert(state)
    {
        state.alert = {};
        // console.log(state.alert);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
