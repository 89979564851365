<template>
    <RecordList>
        <!-- Title -->
        <RecordListItem title class="flex items-center">

            <div class="flex">
                <h2 class="text-lg mb-0 flex-1">Records</h2>
            </div>

            <!-- Controls -->
            <template v-slot:controls>
                <TextInput placeholder="Search by name, colour" class="w-64" v-model="filterText">
                    <template v-slot:icon><v-icon name="search" class="fill-current"></v-icon></template>
                </TextInput>
            </template>

            <!-- Important Actions -->
            <template v-slot:important-actions>
                <RecordListActionButton text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></RecordListActionButton>
            </template>
        </RecordListItem>

        <!-- Headers -->
        <RecordListItem header>
            <div class="flex font-bold">
                <div class="w-1/3">ID</div>
                <div class="w-1/3">Model</div>
                <div class="w-1/3">Colour</div>
                <!-- Additional Column Names -->
            </div>
        </RecordListItem>

        <!-- Items -->
        <RecordListItem v-for="(record, index) in filteredRecords" :key="index">
            <!-- Details  -->
            <div class="flex">
                <div class="w-1/3">{{ record.id }}</div>
                <div class="w-1/3">{{ record.name }}</div>
                <div class="w-1/3">{{ record.colour }}</div>
                <!-- Additonal Column Values -->
            </div>

            <!-- Important Actions -->
            <template v-slot:important-actions>
                <RecordListActionButton important text="Edit" @click="editRecord(record)"><v-icon name="pen"
                        class="fill-current"></v-icon></RecordListActionButton>
                <RecordListActionButton important text="Delete" @click="deleteRecord(record)"><v-icon name="trash"
                     class="fill-current"></v-icon></RecordListActionButton>
            </template>

            <!-- Other Actions -->
            <template v-slot:actions>
                <RecordListActionButton text="Duplicate" @click="duplicateRecord(record)"><v-icon name="copy"
                        class="fill-current"></v-icon></RecordListActionButton>
            </template>
        </RecordListItem>
    </RecordList>
</template>
    
<script>
import RecordList from '../../Components/RecordList/RecordList.vue';
import RecordListItem from '../../Components/RecordList/RecordListItem.vue';
import RecordListActionButton from '../../Components/RecordList/RecordListActionButton.vue';

export default {
    components: {
        RecordList,
        RecordListItem,
        RecordListActionButton
    },
    props: {
        filterBy: {
            type: Array,
            default: () => ['name', 'colour']
        },
        records: {
            type: Array,
            // required: true,
            default: () => [
                {
                    id: 1,
                    name: 'BMW Z4',
                    colour: 'Midnight Blue'
                },
                {
                    id: 2,
                    name: 'Porsche 911',
                    colour: 'Blood Red'
                },
                {
                    id: 3,
                    name: 'Audi R8 Spyder',
                    colour: 'Jet Black (Matte)'
                },

            ]
        }
    },
    data() {
        return {
            filterText: ''
        }
    },
    computed: {
        filteredRecords() {

            if (this.filterText.length > 0) {
                console.log(this.filterText);
                return this.records.filter(record => {
                    return this.filterBy.some(filter => {
                        return record[filter].toLowerCase().includes(this.filterText.toLowerCase());
                    })
                })
            }

            return this.records;
        }
    },
    methods: {
        editRecord(record) {
            console.log('Edit', record);
        },
        addRecord() {
            console.log('Add');
        },
        deleteRecord(record) {
            console.log('Delete', record);
        },
        duplicateRecord(record) {
            console.log('Duplicate', record);
        },
    }

}
</script>
    
<style></style>