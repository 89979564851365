let formDefaults =  {
    recordable_name: {
        value: null,
        rules: 'required|string'
    },
    title: {
        value: '',
        rules: 'required|string'
    },
    cost: {
        value: null,
        rules: 'number'
    },
    description: {
        value: '',
        rules: 'string'
    },
    service_date: {
        value: null,
        rules: 'required|date'
    },
    warranty_expires_date: {
        value: null,
        rules: 'date'
    }
}

let formConfig = {}

export { formDefaults, formConfig }