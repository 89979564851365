<template>
    <!-- Wrapper -->
    <div class="bg-white border border-gray-300 rounded">
        <textarea :name="name" :id="id || name" :value="value" @input="handleInput" :placeholder="placeholder" :autocomplete="autocomplete" class="bg-transparent w-full px-4 py-2 min-h-48"></textarea>
    </div>
</template>

<script>
export default {
  props: {
    autocomplete: {},
    placeholder: {},
    value: {},
    name: {},
    id: {},
  },
  methods: {
    handleInput (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
