import axios from 'axios'

/**
 * The base model class.
 */
class Model
{
    /**
     * 
     * @param {object} properties 
     */
    constructor(properties) {
        for(let prop in properties) {
            // Assign properties locally
            this[prop] = properties[prop];
        };

        // Assign default properties
        this.basePath = '/'
    }

    /**
     * Get path for model.
     */
    path()
    {
        return this.basePath;
    }

    /**
     * Refresh a model from the server.
     */
    refresh()
    {
        //
    }

    /**
     * 
     * @param {int} id 
     */
    static get(id)
    {
        
    }

    /**
     * Update a model on the server.
     * @param {object} fields 
     */
    update(fields)
    {
        //
    }

    /**
     * Delete a model on the server.
     */
    delete()
    {
        //
    }
}

export default Model;
