<template>
    <div>
      <canvas ref="canvas" @mousedown="startDrawing" @mousemove="drawLine" @mouseup="stopDrawing"></canvas>
      <button @click="resetCanvas">Reset Canvas</button>
      <br>
      {{ lines }}
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        drawing: false,
        lines: [],
        currentLine: null,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        canvasWidth: 500,
        canvasHeight: 500,
        lineWidth: 3,
        lineColor: 'black',
      }
    },
    mounted() {
      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext('2d');
      this.canvas.width = this.canvasWidth;
      this.canvas.height = this.canvasHeight;
    },
    methods: {
      startDrawing(event) {
        const rect = this.canvas.getBoundingClientRect();
        this.startX = event.clientX - rect.left;
        this.startY = event.clientY - rect.top;
        this.currentLine = {
          startX: this.startX,
          startY: this.startY,
          endX: this.startX,
          endY: this.startY,
        };
        this.drawing = true;
      },
      drawLine(event) {
        if (!this.drawing) return;
        const rect = this.canvas.getBoundingClientRect();
        this.endX = event.clientX - rect.left;
        this.endY = event.clientY - rect.top;
        this.currentLine.endX = this.endX;
        this.currentLine.endY = this.endY;
        this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.lines.forEach(line => this.draw(line));
        this.draw(this.currentLine);
      },
      stopDrawing(event) {
        this.drawing = false;
        const rect = this.canvas.getBoundingClientRect();
        this.endX = event.clientX - rect.left;
        this.endY = event.clientY - rect.top;
        this.currentLine.endX = this.endX;
        this.currentLine.endY = this.endY;
        this.lines.push(this.currentLine);
        this.currentLine = null;
        this.updateLineLength();
      },
      draw(line) {
        this.context.beginPath();
        this.context.moveTo(line.startX, line.startY);
        this.context.lineTo(line.endX, line.endY);
        this.context.lineWidth = this.lineWidth;
        this.context.strokeStyle = this.lineColor;
        this.context.stroke();
        this.updateLineLength(line);
      },
      updateLineLength(line) {
        if (!line) {
          this.lines.forEach(line => this.updateLineLength(line));
          return;
        }
        const length = Math.sqrt(Math.pow(line.endX - line.startX, 2) + Math.pow(line.endY - line.startY, 2));
        const centerX = (line.startX + line.endX) / 2;
        const centerY = (line.startY + line.endY) / 2;
        this.context.font = '12px sans-serif';
        this.context.fillStyle = 'black';
        this.context.fillText(length.toFixed(2), centerX, centerY);
      },
      resetCanvas() {
        this.lines = [];
        this.currentLine = null;
        this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      },
    },
  };
  </script>
  
  <style>
  canvas {
    border: 1px solid black;
  }
  </style>
  