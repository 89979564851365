export default class Loan {

    /**
     * Find a loan by it's ID.
     * @param {int} id 
     */
    static async find(id) {
        let request = await axios.get(`/api/v1/loans/${id}`);
        let loan = request.data.loan || {};

        return loan;
    }

    /**
    * Create a loan.
    * @param {object} fields
    */
    static async create(fields) {
        let request = await axios.post(`/api/v1/loans`, fields);
        let loan = request.data.loan || {};

        return loan;
    }

    /**
    * Update a loan.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/loans/${id}`, fields);
        let loan = request.data.loan || {};

        return loan;
    }

    /**
    * Delete a loan.
    * @param {int} id The ID of the loan to be deleted.
    */
    static async delete(id) {
        let request = await axios.delete(`/api/v1/loans/${id}`);
        let successful = request.data.success || false;

        return successful;
    }
}