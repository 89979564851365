<template>
    <div :href="path" @click.prevent="$emit('click')" class="" :class="[level ? 'pl-8 bg-gray-50 -mx-4' : '']">

        <!-- Router Link -->
        <router-link v-if="path != ''" :to="path" class="block py-4 hover:underline">
            <v-icon :name="iconName" class="fill-current mr-2"></v-icon>
            <slot></slot>
        </router-link>

        <!-- Standard Anchor -->
        <a v-else href="#" class="block py-4 hover:underline">
            <v-icon :name="iconName" class="fill-current mr-2"></v-icon>
            <slot></slot>
        </a>
    </div>

</template>

<script>
export default {
    props: {
        path: {
            type: String,
            default: '',
        },
        iconName: {
            type: String,
            required: true,
        },
        level: {
            type: Number,
            default: 0,
        }
    }
}
</script>

<style>

</style>