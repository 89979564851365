const state = {
    countries: [],
    states: [],
    cities: []
};
 
const getters = {
    getCountries(state) {
        return state.countries;
    },
    getStates(state) {
        return state.states;
    },
    getCities(state) {
        return state.cities;
    }
};

const actions = { 
    /**
     * Get Countries.
     *
     */
    async setCountries({commit})
    {
        await axios.get(`/api/v1/countries`)
            .then( response => {
                commit('setCountries',response.data.countries);
            })
    },
    /**
     * Get States.
     */
    async getStates({commit})
    {
        await axios.get(`/api/v1/states`)
            .then( response => {
                commit('setStates',response.data.states);
            })
    },
    /**
     * Get Cities.
     */
    async getCities({commit})
    {
        await axios.get(`/api/v1/cities`)
            .then( response => {
                commit('setCities',response.data.cities);
            })
    },




    async setStatesForCountry({commit}, country_id)
    {
        await axios.get(`/api/v1/countries/${country_id}/states`)
            .then( response => {
                commit('setStates',response.data.states);
            })
    },
    async setCitiesForState({commit}, state_id)
    {
        await axios.get(`/api/v1/states/${state_id}/cities`)
            .then( response => {
                commit('setCities',response.data.cities);
            })
    }
};

const mutations = {
    setCountries(state, countries)
    {
        state.countries = countries;
    },
    setStates(state, states)
    {
        state.states = states;
    },
    setCities(state, cities)
    {
        state.cities = cities;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
