export default class Property {

    /**
    * Get all properties.
     */
    static async all() {
        let request = await axios.get(`/api/v1/properties`);
        let properties = request.data.properties || [];

        return properties;
    }

    /**
     * Find a property by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/properties/${id}`);
        let property = request.data.property || {};

        return property;
    }

    /**
    * Create a property.
    * @param {object} fields
    */
    static async create(fields) {
        let request = await axios.post(`/api/v1/properties`, fields);
        let property = request.data.property || {};

        return property;
    }

    /**
    * Update a property.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.put(`/api/v1/properties/${id}`, fields);
        let property = request.data.property || {};

        return property;
    }

    /**
    * Delete a property.
    * @param {int} id 
    */
    static async delete(id) {
        let request = await axios.delete(`/api/v1/properties/${id}`);
        return request.status === 200;
    }

    /**
    * Add a note to a service company.
    */
    static async addNote(property_id, fields = {}) {
        let request = await axios.post(`/api/v1/properties/${property_id}/notes`, fields);
        let note = request.data.note || {};

        return note;
    }
}