import GrantAPI from '@api/grant';
import RebateAPI from '@api/rebate';
import LoanAPI from '@api/loan';

const state = {
    incentives: [],
    incentive: {
        retrofit_program: {},
        service_type: {}
    },

    // grants
    grant: {
        retrofit_program: {},
        service_type: {}
    },
    grants: [],

    // rebates
    rebate: {
        retrofit_program: {},
        service_type: {}
    },
    rebates: [],

    // loans
    loan: {
        retrofit_program: {},
        service_type: {}
    },
    loans: [],
};
 
const getters = {
    getIncentives(state)
    {
        return state.incentives;
    },

    getGrant(state) {
        return state.grant;
    },
    getGrants(state) {
        return state.grants;
    },

    getRebate(state) {
        return state.rebate;
    },
    getRebates(state) {
        return state.rebates;
    },

    getLoan(state) {
        return state.loan;
    },
    getLoans(state) {
        return state.loans;
    },
};

const actions = { 
    /**
     * Get all Incentives.
     * Inc. Grants, Rebates, Loans
     *
     * @param {int} id The ID of the Grant to be retrieved.
     */
    getIncentives({commit})
    {
        axios.get('/api/v1/incentives')
            .then(response => {
                commit('setIncentives', response.data.incentives)
            })
    },


    /**
    * Get a Grant.
    *
    * @param {int} id The ID of the Grant to be retrieved.
    */
    async getGrant({commit}, id)
    {
        await axios.get(`/api/v1/grants/${id}`)
            .then(response => {
                commit('setGrant', response.data.grant)
            })
    },
    /**
     * Create a Grant.
     *
     * @param {object} fields The fields to be used to create the Grant.
     */
    async createGrant({commit, state}, fields)
    {
        let grant = await GrantAPI.create(fields);

        if(grant.id) {
            commit('setGrant', grant);
            state.grants.push(grant);
        }
    },
    /**
    * Update a Grant.
    *
    * @param {object} fields The fields to be used to update the Grant.
    */
    async updateGrant({commit}, fields)
    {
        let grant = await GrantAPI.update(fields.id, fields);

        if(grant.id) {
            const grants = state.grants.filter(grant => grant.id !== grant.id)
            grants.push(grant)

            commit('setGrant', grant);
            commit('setGrants', grants)
        }       
    },
    /**
     * Delete a Grant.
     *
     * @param {int} id The ID of the Grant to be deleted.
     */
    async deleteGrant({commit}, id)
    {
        let successful = GrantAPI.delete(id);

        if(successful) {
            commit('setGrant', {});
        }
    },


    /**
    * Get a Rebate.
    *
    * @param {int} id The ID of the Rebate to be retrieved.
    */
    async getRebate({commit}, id)
    {
        let rebate = await RebateAPI.find(id);

        if(rebate.id) {
            commit('setRebate', rebate)
        }
    },
    /**
     * Create a Rebate.
     *
     * @param {object} fields The fields to be used to create the Rebate.
     */
    async createRebate({commit}, fields)
    {
        let rebate = await RebateAPI.create(fields);

        if(rebate.id) {
            commit('setRebate', rebate);
            state.rebates.push(rebate);
        }
    },
    /**
    * Update a Rebate.
    *
    * @param {object} fields The fields to be used to update the Rebate.
    */
    async updateRebate({commit}, fields)
    {
        let rebate = await RebateAPI.update(fields.id, fields);

        if(rebate.id) {
            const rebates = state.rebates.filter(rebate => rebate.id !== rebate.id)
            rebates.push(rebate)

            commit('setRebate', rebate);
            commit('setRebates', rebates)
        }       
    },
    /**
     * Delete a Rebate.
     *
     * @param {int} id The ID of the Rebate to be deleted.
     */
    async deleteRebate({commit}, id)
    {
        let successful = RebateAPI.delete(id);

        if(successful) {
            commit('setRebate', {});
        }
    },

    /**
    * Get a Loan.
    *
    * @param {int} id The ID of the Loan to be retrieved.
    */
    async getLoan({commit}, id)
    {
        let rebate = await LoanAPI.find(id);

        if(rebate.id) {
            commit('setLoan', rebate)
        }
    },
    /**
     * Create a Loan.
     *
     * @param {object} fields The fields to be used to create the Loan.
     */
    async createLoan({commit}, fields)
    {
        let rebate = await LoanAPI.create(fields);

        if(rebate.id) {
            commit('setLoan', rebate);
            state.rebates.push(rebate);
        }
    },
    /**
    * Update a Loan.
    *
    * @param {object} fields The fields to be used to update the Loan.
    */
    async updateLoan({commit}, fields)
    {
        let rebate = await LoanAPI.update(fields.id, fields);

        if(rebate.id) {
            const rebates = state.rebates.filter(rebate => rebate.id !== rebate.id)
            rebates.push(rebate)

            commit('setLoan', rebate);
            commit('setLoans', rebates)
        }       
    },
    /**
     * Delete a Loan.
     *
     * @param {int} id The ID of the Loan to be deleted.
     */
    async deleteLoan({commit}, id)
    {
        let successful = LoanAPI.delete(id);

        if(successful) {
            commit('setLoan', {});
        }
    },
    
    
};

const mutations = {
    // setProject(state, project)
    // {
    //     state.project = project;
    // },
    setIncentives(state, incentives)
    {
        state.incentives = incentives;
    },

    setGrant(state, grant)
    {
        state.grant = grant;
    },
    setGrants(state, grants)
    {
        state.grants = grants;
    },

    setRebate(state, rebate)
    {
        state.rebate = rebate;
    },
    setRebates(state, rebates)
    {
        state.rebates = rebates;
    },

    setLoan(state, loan)
    {
        state.loan = loan;
    },
    setLoans(state, loans)
    {
        state.loans = loans;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
