<template>
    <div class="relative flex gap-x-4 z-50" v-click-outside="() => { menuVisible = false }">

        <!-- User name -->
        <div class="group hidden md:flex items-center gap-x-4 hover:text-orange-700 cursor-pointer"
            @click.prevent="menuVisible = !menuVisible">
            <a href="#" class="flex items-center gap-2">
                <div>{{ user.first_name }}</div>
                <div v-if="isSuperadmin" class="px-1 text-xs border border-gray-700 group-hover:border-orange-700 rounded">Superadmin</div>
                <div v-else-if="isAdmin" class="px-1 text-xs border border-gray-700 group-hover:border-orange-700 rounded">Admin</div>
            </a>
            <v-icon name="caret-down" scale="1.5" class="fill-current pb-1"></v-icon>
        </div>

        <!-- Hamburger -->
        <div class="md:hidden" @click.prevent="menuVisible = !menuVisible">
            <v-icon :name="menuVisible ? 'times' : 'bars'" class="ml-2" scale="1.5"></v-icon>
        </div>

        <!-- Menu -->
        <div v-show="menuVisible"
            class="navigation-menu absolute flex flex-col top-10 right-0 bg-white rounded px-4 -mr-4 md:mr-0 md:mt-0 w-screen md:w-auto shadow-lg text-sm border border-gray-300"
        >

            <!-- Admin Toggle -->
            <!-- <div>
                <div class="flex justify-center items-center" @click="isAdminMode = !isAdminMode">
                    <div class="w-full h-10 bg-gray-300 rounded-full p-1 duration-300 ease-in-out relative overflow-hidden">
                        <div class="bg-white w-full h-8 rounded-full shadow-md transform duration-300 ease-in-out" :class="{ 'translate-x-full': isAdminMode }"></div>
                        <div class="flex">
                            <div class="flex-1 flex items-center justify-center">
                            <span class="text-gray-700 text-sm absolute inset-0 flex items-center justify-center" :class="{ 'opacity-25': isAdminMode }">User</span>
                            </div>
                            <div class="flex-1 flex items-center justify-center">
                            <span class="text-white text-sm absolute inset-0 flex items-center justify-center" :class="{ 'opacity-25': !isAdminMode }">Admin</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- USER -->
            <!-- <div class="md:hidden bg-gray-100 -m-4 p-4 mb-0">{{ user.full_name }}</div> -->

            <!-- ADMIN -->
            <MenuItem v-if="isAdmin" path="/admin" icon-name="user-plus" @click="hide">Admin</MenuItem>
            <MenuItem v-if="isAdmin" path="/admin/properties" icon-name="home" :level="1" @click="hide">Properties</MenuItem>
            <MenuItem v-if="isAdmin" path="/admin/programs" icon-name="money-bill-wave" :level="1" @click="hide">Retrofit Programs</MenuItem>
            <MenuItem v-if="isAdmin" path="/admin/projects" icon-name="hammer" :level="1" @click="hide">Projects</MenuItem>
            <MenuItem v-if="isAdmin" path="/admin/service_companies" icon-name="wrench" :level="1" @click="hide">Service Companies</MenuItem>
            <MenuItem v-if="isAdmin" path="/admin/users" icon-name="users" :level="1" @click="hide">Users</MenuItem>
            <MenuItem v-if="isAdmin" path="/admin/countries" icon-name="globe" :level="1" @click="hide">Countries</MenuItem>

            <!-- SUPERADMIN -->
            <!-- <SidebarLink href="/admin/project_plans" icon="clipboard">Project Plans</SidebarLink> -->
            <MenuItem v-if="isSuperadmin" path="/admin/project_plans" icon-name="clipboard" :level="1" @click="hide">Project Plans</MenuItem>


            <!-- AUTH -->
            <MenuItem v-if="!isAdmin && isAuthenticated" path="/properties" icon-name="home" @click="hide">Properties</MenuItem>
            <MenuItem v-if="isAuthenticated" :path="`/users/${user.id}/settings`" icon-name="cog" @click="hide">Settings</MenuItem>
            <div class="divider"></div>
            <MenuItem v-if="isAuthenticated" icon-name="comment" @click="hide();$modal.show('create-feedback-modal');">Submit Feedback</MenuItem>
            <div class="divider"></div>
            <MenuItem v-if="isAuthenticated" icon-name="sign-out-alt" @click="logout()">Logout</MenuItem>
        </div>


    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import MenuItem from './HeaderNavigationMenuItem.vue';

export default {
    components: {
        MenuItem
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            isAdmin: 'auth/isAdmin',
            isSuperadmin: 'auth/isSuperadmin',
            user: 'auth/getUser'
        })
    },
    data() {
        return {
            menuVisible: false,
            isAdminMode: false
        }
    },
    directives: {
        ClickOutside
    },
    methods: {
        hide()
        {
            this.menuVisible = false;
        },
        logout()
        {   
            this.hide();
            this.$store.dispatch('auth/logout');
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    @apply hover:text-orange-700
}

.navigation-menu {
    min-width: 240px;
}

.divider {
    @apply border-b border-gray-200 -mx-4 -my-1
}
</style>