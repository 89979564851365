<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen" :scrollable="true">
        <div class="p-6 pt-16 md:p-16">
            <!-- Update Form -->
            <div>
                <!-- Title -->
                <SectionTitle>Update Appliance</SectionTitle>

                <!-- Form -->
                <div id="update-appliance-form" class="mb-8">

                    <!-- Appliance Type -->
                    <FormRow>
                        <Label for="appliance_type_id">Appliance Type</Label>
                        <SelectInput name="appliance_type_id" v-model="form.appliance_type_id" class="2/3">
                            <option v-for="typee in appliance_types" :key="typee.id" :value="typee.id">{{typee.name}}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('appliance_type_id')">{{form.errors.first('appliance_type_id')}}</Errors>
                    </FormRow>

                    <!-- Manufacturer -->
                    <FormRow>
                        <Label for="manufacturer_id">Manufacturer</Label>
                        <SelectInput name="manufacturer_id" v-model="form.manufacturer_id" class="2/3">
                            <option v-for="man in manufacturers" :key="man.id" :value="man.id">{{man.name}}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('manufacturer_id')">{{form.errors.first('manufacturer_id')}}</Errors>
                    </FormRow>
                    
                    <!-- Model Number -->
                    <FormRow>
                        <Label for="model_number">Model Number</Label>
                        <TextInput name="model_number" v-model="form.model_number" class="2/3"/>
                        <Errors v-if="form.errors.has('model_number')">{{form.errors.first('model_number')}}</Errors>
                    </FormRow>

                    <!-- Serial Number (Optional) -->
                    <FormRow>
                        <Label for="serial_number">Serial Number (Optional)</Label>
                        <TextInput name="serial_number" v-model="form.serial_number" class="2/3"/>
                        <Errors v-if="form.errors.has('serial_number')">{{form.errors.first('serial_number')}}</Errors>
                    </FormRow>

                    <!-- Warranty Expires (Optional) -->
                    <FormRow>
                        <Label for="warranty_expires_at">Warranty Expires Date</Label>
                        <TextInput type="date" name="warranty_expires_at" v-model="form.warranty_expires_at" class="2/3"/>
                        <Errors v-if="form.errors.has('warranty_expires_at')">{{form.errors.first('warranty_expires_at')}}</Errors>
                    </FormRow>

                    <!-- Model Year (Optional) -->
                    <FormRow>
                        <Label for="year">Model Year (Optional)</Label>
                        <TextInput name="year" v-model="form.year" class="2/3"/>
                        <Errors v-if="form.errors.has('year')">{{form.errors.first('year')}}</Errors>
                    </FormRow>

                    <!-- Room -->
                    <FormRow v-if="(rooms && rooms.length && !room)">
                        <Label for="room_id">Room (optional)</Label>
                        <SelectInput name="room_id" v-model="form.room_id" class="2/3">
                            <option v-for="room in rooms" :key="room.id" :value="room.id">{{room.nickname ? `${room.nickname} (${room.name})` : room.name}}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('room_id')">{{form.errors.first('room_id')}}</Errors>
                    </FormRow>

                    <!-- Rented -->
                    <FormRow>
                        <Label for="rented">Rented?</Label>
                        <input type="checkbox" id="rented" name="rented" v-model="form.rented" class="2/3"/>
                        <Errors v-if="form.errors.has('rented')">{{form.errors.first('rented')}}</Errors>
                    </FormRow>

                    <!-- Service Provider -->
                    <FormRow v-if="form.rented">
                        <Label for="service_provider">Service Provider</Label>
                        <TextInput name="service_provider" v-model="form.service_provider" class="2/3"/>
                        <Errors v-if="form.errors.has('service_provider')">{{form.errors.first('service_provider')}}</Errors>
                    </FormRow>

                     <!-- Utility -->
                     <FormRow v-if="(property.utilities.length)">
                        <Label for="utility_id">Utility (optional)</Label>
                        <SelectInput name="utility_id" v-model="form.utility_id" class="2/3">
                            <option v-for="utility in property.utilities" :key="utility.id" :value="utility.id">({{utility.type.name}}) {{ utility.name }}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('utility_id')">{{form.errors.first('utility_id')}}</Errors>
                    </FormRow>

                    
                </div>

                <!-- Actions -->
                <FormRow class="mb-12">
                    <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                    <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
                </FormRow>
            </div>

            <!-- Delete Form -->
            <div id="delete-appliance-form">
                <div class="p-4 rounded border border-red-700 border-dashed">
                    <div id="expand-delete" class="flex text-red-700 hover:text-red-900 cursor-pointer" @click="delete_expanded = !delete_expanded">
                        <div class="mr-4">
                            <v-icon class="fill-current" :name="delete_expanded ? 'chevron-up' : 'chevron-down'"></v-icon>
                        </div>
                        <div>Delete</div>
                    </div>
                    
                    <!-- Lower -->
                    <div class="mt-8" v-show="delete_expanded">
                        <div class="mb-6 text-red-900">
                            Do you wish do delete your appliance? This cannot be undone without contacting Paddio directly.
                        </div>

                        <!-- Actions -->
                        <FormRow>
                            <square-button id="delete-appliance-button" context="gray" class="mr-2" @click="deleteAppliance">Delete</square-button>
                        </FormRow>

                    </div>
                </div>
            </div>
        </div>
    </Modal>
    
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@property/Forms/UpdateApplianceForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton,
    },
    computed: {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            appliance: 'appliances/getAppliance',
            manufacturers: 'manufacturers/getManufacturers',
            appliance_types: 'properties/getApplianceTypes',
        })
    },
    data()
    {
        return {
            name: 'update-appliance-modal',
            form: new Form(formDefaults),
            rooms: [],
            room: null,
            delete_expanded: false
        }
    },
    methods:
    {
        beforeOpen(event)
        {
            if(this.appliance) {
                Object.keys(formDefaults).forEach(key => {
                    this.form[key] = this.appliance[key]
                });

                // Update Room in form
                this.form.room_id = this.appliance.room_id

                // Update utility in form
                if(this.appliance.utility){
                    this.form.utility_id = this.appliance.utility.id
                }

                this.form.appliance_type_id = this.appliance.type.id
            }

            this.rooms = this.$store.getters['properties/getCurrentProperty'].rooms;
        },
        async deleteAppliance()
        {
            await this.$store.dispatch('appliances/deleteAppliance', this.appliance)
                .then(() => {

                    // TODO - update state.property.appliances
                    // TODO - update state.room.appliances
                    this.$modal.hide(this.name)
                    // this.$modal.show('appliance-modal', this.$store.getters['appliances/getAppliance'])

                    this.$notify({
                        title: 'Success',
                        text: `The appliances has been deleted.`,
                        type: 'success'
                    });
                    }).catch(error => {
                    console.error(error);
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });      
        },
        async submit()
        {

            await this.$store.dispatch('appliances/updateAppliance', {id: this.appliance.id, fields: this.form.data()})
                .then(() => {

                    // TODO - update state.property.appliances
                    // TODO - update state.room.appliances
                    this.$modal.hide(this.name)
                    this.$modal.show('appliance-modal', this.$store.getters['appliances/getAppliance'])

                    this.$notify({
                        title: 'Success',
                        text: `The ${this.appliance.type.name} has been updated.`,
                        type: 'success'
                    });
                }).catch(error => {
                    console.error(error);

                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
    async created() {
        await this.$store.dispatch('properties/getApplianceTypes');
        await this.$store.dispatch('manufacturers/getManufacturers');
    },
    
}
</script>
