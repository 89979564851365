export default class HouseholdDetails {

    /**
     * Find a household_details by it's ID.
     * @param {int} id 
     */
    // static async find(id) {
    //     let request = await axios.get(`/api/v1/household_details/${id}`);
    //     let household_details = request.data.household_details || {};

    //     return household_details;
    // }

    /**
    * Create a household_details.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/household_details`, fields);
    //     let household_details = request.data.household_details || {};

    //     return household_details;
    // }

    /**
    * Update a household_details.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/household_details/${id}`, fields);
        let household_details = request.data.household_details || {};

        return household_details;
    }

    /**
    * Delete a household_details.
    * @param {int} id The ID of the household_details to be deleted.
    */
    // static async delete(id) {
    //     let request = await axios.delete(`/api/v1/household_details/${id}`);
    //     let successful = request.data.success || false;

    //     return successful;
    // }
}