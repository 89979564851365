export default class Country {

    /**
     * Find a countries by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/countries/${id}`);
        let countries = request.data.countries || {};

        return countries;
    }

    /**
    * Get all countries.
     */
    static async all() {
        let request = await axios.get(`/api/v1/countries`);
        let countries = request.data.countries || [];

        return countries;
    }

    /**
    * Create a countries.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/countries`, fields);
    //     let countries = request.data.countries || {};

    //     return countries;
    // }

    /**
    * Update a countries.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/countries/${id}`, fields);
        let countries = request.data.countries || {};

        return countries;
    }
}