export default class ProjectJob {

    /**
     * Find a job by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/project-jobs/${id}`);
        let job = request.data.job || {};

        return job;
    }

    /**
    * Create a job.
    * @param {object} fields
    */
    static async create(fields) {
        
        if(!fields.project_id) throw new Error('Project ID is required to create a job.');

        let request = await axios.post(`/api/v1/projects/${fields.project_id}/project-jobs`, fields);
        let job = request.data.project_job || {};

        return job;
    }

    /**
    * Update a job.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.put(`/api/v1/project-jobs/${id}`, fields);
        let job = request.data.job || {};

        return job;
    }
    /**
    * Delete a job.
    */
    static async delete(id) {
        let request = await axios.delete(`/api/v1/project-jobs/${id}`);
        return request.status === 200;
    }
    /**
    * Attach a grant to a job.
    */
    static async attachGrant(job_id, fields) {
        let request = await axios.post(`/api/v1/project-jobs/${job_id}/grants`, fields);
        return request.status === 200;
    }

    /**
    * detach a grant to a job.
    */
    static async detachGrant(job_id, grant_id) {
        let request = await axios.delete(`/api/v1/project-jobs/${job_id}/grants/${grant_id}`);
        return request.status === 200;
    }

    /**
    * Attach a rebate to a job.
    */
    static async attachRebate(job_id, fields) {
        let request = await axios.post(`/api/v1/project-jobs/${job_id}/rebates`, fields);
        return request.status === 200;
    }

    /**
    * detach a rebate to a job.
    */
    static async detachRebate(job_id, rebate_id) {
        let request = await axios.delete(`/api/v1/project-jobs/${job_id}/rebates/${rebate_id}`);
        return request.status === 200;
    }

    /**
    * Attach a loan to a job.
    */
    static async attachLoan(job_id, fields) {
        let request = await axios.post(`/api/v1/project-jobs/${job_id}/loans`, fields);
        return request.status === 200;
    }

    /**
    * detach a loan to a job.
    */
    static async detachLoan(job_id, loan_id) {
        let request = await axios.delete(`/api/v1/project-jobs/${job_id}/loans/${loan_id}`);
        return request.status === 200;
    }

    /**
    * Create a component for a job.
    * @param {object} fields
    */
    static async createComponent(job_id, fields) {
        let request = await axios.post(`/api/v1/project-jobs/${job_id}/components`, fields);
        let component = request.data.component || {};

        return component;
    }
}