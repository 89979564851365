import StateAPI from '@api/states';

const state = {
    state: {},
    states: [],
};
 
const getters = {
    getState(state)
    {
        return state.state;
    },
    getStates(state)
    {
        return state.states;
    }
};

const actions = { 
    async getState({commit}, id)
    {
        let state = await StateAPI.find(id);
        commit('setState', state);
    },

    /**
    * Create a state.
    * @param {object} data The form fields
    */
    async updateState({commit}, data) {
        let successful = await StateAPI.update(data.id, data.fields);
        return successful;
    },

    // delete
    async deleteState({commit}, id)
    {
        // await axios.delete(`/api/v1/states/${id}`)
            // .then(response => commit('setState', response.data.state))
    },

    async getStates({commit})
    {
        let states = await StateAPI.all();
        commit('setStates', states);
    }
};

const mutations = {
    setState(state, geographicState)
    {
        state.state = geographicState;
    },
    setStates(state, states)
    {
        state.states = states;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
