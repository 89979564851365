<template>
    <div class="block md:hidden absolute px-4 py-8 w-full z-50 bg-gray-50 border border-gray-100 shadow-xl">

        <!-- Title -->
        <h2 class="mb-4">{{user.full_name}}</h2>

        <!-- Upper Links -->
        <div class="mb-8">
            <MobileNavigationLink href="/properties" icon="home" class="navigation-link mb-4" @click="$emit('click')">Properties</MobileNavigationLink>
            <div v-if="isAdmin" class="mt-8">
                    <MobileNavigationLink  @click="$router.push('/admin/retrofit_programs')" icon="list" class="navigation-link mb-4">Retrofit Programs</MobileNavigationLink>
                    <MobileNavigationLink  @click="$router.push('/admin/incentives')" icon="money-check-alt" class="navigation-link mb-4">Incentives</MobileNavigationLink>
                    <!-- <SidebarLink  @click="$router.push('/admin/projects')" icon="wrench" class="mb-4">Projects</SidebarLink> -->
                </div>
        </div> 

        <!-- Lower Links -->
        <div>
            <MobileNavigationLink href="#" icon="cog" class="navigation-link mb-4" @click="$router.push(`/users/${user.id}/settings`); $emit('click')">Settings</MobileNavigationLink>
            <MobileNavigationLink href="#" icon="sign-out-alt" class="navigation-link mb-4" @click="$store.dispatch('users/logoutUser', {}); $emit('click')">Logout</MobileNavigationLink>
            <div class="text-gray-500">Early Access</div>
        </div>
    </div>
</template>

<script>
import SidebarLink from "@components/Sidebar/SidebarLink";
import MobileNavigationLink from "../Components/MobileNavigationLink.vue";
import { mapGetters } from 'vuex';

export default {
    name: "MobileNavigation",
    components: {
        SidebarLink,
        MobileNavigationLink
    },
    computed: {
        ...mapGetters({
            // property: 'properties/getCurrentProperty',
            user: 'auth/getUser',
            isAdmin: 'auth/isAdmin',
        })
    }
};
</script>

<style lang="scss" scoped>
</style>
