export default class Appliance {

    /**
     * Find a appliances by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/appliances/${id}`);
        let appliance = request.data.appliance || {};

        return appliance;
    }

    /**
    * Get all appliances.
     */
    static async all() {
        let request = await axios.get(`/api/v1/appliances`);
        let appliances = request.data.appliances || [];

        return appliances;
    }

    /**
    * Create a appliances.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/appliances`, fields);
    //     let appliances = request.data.appliances || {};

    //     return appliances;
    // }

    /**
    * Update a appliances.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/appliances/${id}`, fields);
        let appliances = request.data.appliances || {};

        return appliances;
    }
}