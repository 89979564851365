export default class State {

    /**
     * Find a states by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/states/${id}`);
        let state = request.data.state || {};

        return state;
    }

    /**
    * Get all states.
     */
    static async all() {
        let request = await axios.get(`/api/v1/states`);
        let states = request.data.states || [];

        return states;
    }

    /**
    * Create a states.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/states`, fields);
    //     let states = request.data.states || {};

    //     return states;
    // }

    /**
    * Update a states.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/states/${id}`, fields);
        let states = request.data.states || {};

        return states;
    }
}