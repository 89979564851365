<template>
    <a 
        @click.prevent="$emit('click')"
        href="#"
        class="flex items-center justify-center rounded-full text-gray-900 bg-inherit hover:bg-gray-500 hover:text-white w-7 h-7"
    >
        <v-icon :name="name" class="fill-current"></v-icon>
    </a>
</template>

<script>
export default {
    props: {
        name: {
            default: 'ellipsis-h'
        }
    }
}
</script>
