<template>
  <div>
      <!-- Title -->
      <PageTitle>Properties</PageTitle>

      <div v-if="!properties.length" class="mb-4">
        <RoundButton icon-left="plus" @click="$router.push('/retrofit/onboarding')">Add a Property</RoundButton>
      </div>

      <!-- <Card>
        <PropertyList v-if="properties.length" :properties="properties"></PropertyList>

        <div v-else class="p-4 border border-orange-500 rounded">
            <h2 class="mb-4 text-lg text-orange-500">You don't have any properties yet!</h2>
            <p class="mb-8">Add your property and start taking ownership of its data.  </p>
            <square-button id="add-property-button" context="orange" @click="$router.push('/properties/create')">Add Property</square-button>
        </div>
      </Card> -->

      <!-- Properties -->
      <div v-if="properties.length">
          <Property class="property-card" v-for="property in this.properties" :key="property.id" :property="property"></Property>
      </div>

      <!-- No Properties -->
      <div v-else class="p-4 border border-orange-500 rounded">
        <h2 class="mb-4 text-lg text-orange-500">You don't have any properties yet!</h2>
        <p class="mb-8">Add your property and start taking ownership of its data.  </p>
        <RoundButton id="add-property-button" context="orange" icon-left="plus" @click="$router.push('/retrofit/onboarding')">Add a Property</RoundButton>
      </div>

  </div>
</template>

<script>
import Property from '@property/Components/PropertyCard';
import { mapGetters } from 'vuex';
import PropertyList from '../../Property/Components/PropertyList.vue';
import RecordListExample from '../../Components/RecordList/RecordListExample.vue';

export default {
    title: 'Properties',
    components: {
        Property,
        PropertyList,
        RecordListExample,
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            properties: 'users/getProperties'
        }),
        liveProperties() {
          //
        }
    },
    async created()
    {
        await this.$store.dispatch('users/getProperties', this.user.id);
    }
}
</script>
