<template>
  <div class="text-lg mb-2">
      <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>
