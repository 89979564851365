export default class EnergyReport {

    /**
     * Find a energy Report by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/energy_reports/${id}`);
        let energyReport = request.data.energyReport || {};

        return energyReport;
    }

    /**
    * Create a energy Report.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/energy_reports`, fields);
    //     let energyReport = request.data.energyReport || {};

    //     return energyReport;
    // }

    /**
    * Update a energyReport.
    * @param {int} id 
    * @param {object} fields 
    */
    // static async update(id, fields = {}) {
    //     let request = await axios.put(`/api/v1/energy_reports/${id}`, fields);
    //     let energyReport = request.data.energyReport || {};

    //     return energyReport;
    // }


}