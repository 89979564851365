import ProjectPlanAPI from '@api/project_plans';

const state = {
    plans: [],
    plan: {}
};
 
const getters = {
    getProjectPlans(state)
    {
        return state.plans;
    },
    getProjectPlan(state)
    {
        return state.plan;
    }
};

const actions = { 
    async getProjectPlans({commit})
    {
        let plans = await ProjectPlanAPI.findAll();
        commit('setProjectPlans', plans);
        return;
    },

     async getProjectPlan({commit}, id)
    {
        let plan = await ProjectPlanAPI.find(id);
        commit('setProjectPlan', plan);
        return;
    },
    /**
     * Update project plan.
     */
    async updateProjectPlan({commit}, fields)
    {
        let successful = await ProjectPlanAPI.update(fields.id, fields);
        return successful;
    }
};

const mutations = {
    setProjectPlans(state, plans = [])
    {
        state.plans = plans;
    },
    setProjectPlan(state, plan = {})
    {
        state.plan = plan;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
