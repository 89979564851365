var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inline-block rounded border border-gray-200 px-2 fill-current",class:{
        'border-gray-200 text-gray-900': _vm.state == 'standard',
        // 'hover:bg-gray-100': state == 'standard' && clickable,
        'border-orange-700 text-orange-700': _vm.state == 'orange' && !_vm.fill,
        // 'border-orange-600 hover:bg-orange-100': state == 'orange' && !fill && clickable,
        'border-orange-700 bg-orange-700 text-white': _vm.state == 'orange' && _vm.fill,
        // 'hover:bg-orange-100 hover:text-orange-900': state == 'orange' && fill && clickable,
    },on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"flex items-center"},[(_vm.icon)?_c('v-icon',{staticClass:"mr-2 inline-block",attrs:{"name":_vm.icon}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }