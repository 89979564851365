import CountryAPI from '@api/countries';

const state = {
    country: {},
    countries: [],
};
 
const getters = {
    getCountry(state)
    {
        return state.country;
    },
    getCountries(state)
    {
        return state.countries;
    }
};

const actions = { 
    async getCountry({commit}, id)
    {
        await axios.get(`/api/v1/countries/${id}`)
            .then(response => commit('setCountry', response.data.country))
    },

    /**
    * Create a country.
    * @param {object} data The form fields
    */
    async updateCountry({commit}, data) {
        let successful = await CountryAPI.update(data.id, data.fields);
        return successful;
    },

    // delete
    async deleteCountry({commit}, id)
    {
        // await axios.delete(`/api/v1/countries/${id}`)
            // .then(response => commit('setCountry', response.data.country))
    },

    async getCountries({commit})
    {
        let countries = await CountryAPI.all();
        commit('setCountries', countries);
    }
};

const mutations = {
    setCountry(state, country)
    {
        state.country = country;
    },
    setCountries(state, countries)
    {
        state.countries = countries;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
