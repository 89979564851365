<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
           <!-- Title -->
           <SectionTitle>Add Alert</SectionTitle>

            <!-- Form -->
            <div class="mb-8">
                <!-- Title -->
                <FormRow>
                    <Label for="title">Title</Label>
                    <TextInput name="title" v-model="form.title" class="2/3" placeholder="ie. Replace Filter"/>
                    <Errors v-if="form.errors.has('title')">{{form.errors.first('title')}}</Errors>
                </FormRow>

                <!-- Comments -->
                <FormRow>
                    <Label for="comments">Comments</Label>
                    <TextareaInput type="date" name="comments" v-model="form.comments" class="2/3"/>
                    <Errors v-if="form.errors.has('comments')">{{form.errors.first('comments')}}</Errors>
                </FormRow>

                <!-- Alert Date -->
                <FormRow>
                    <Label for="alert_date">Date</Label>
                    <p class="text-sm">When should this alert trigger?</p>
                    <TextInput type="date" name="alert_date" v-model="form.alert_date" class="2/3"/>
                    <Errors v-if="form.errors.has('alert_date')">{{form.errors.first('alert_date')}}</Errors>
                </FormRow>
            </div>

            <!-- Actions -->
            <FormRow>
                <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@property/Forms/CreateDeviceAlertForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'


export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton
    },
    data()
    {
        return {
            name: 'create-device-alert-modal',
            form: new Form(formDefaults, formConfig),
            alertable_type: '',
            create_action: ''
        }
    },
    computed:
    {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            appliance: 'appliances/getAppliance'
        })
    },
    methods:
    {
        beforeOpen(event)
        {
            if(event.params) {
                this.alertable_type = event.params.alertable
                this.setDeviceAlertAction();
            };
        },
        setDeviceAlertAction()
        {
            if(this.alertable_type == 'appliance') {
                this.create_action = 'appliances/addAlert'
                this.alertable = this.appliance
                // this.create_action = `/api/v1/appliances/${this.appliance.id}/alerts`

            } else {
                this.create_action =  ''
            };
        },
        async submit()
        {
            await this.$store.dispatch(this.create_action, this.form.data())
                .then(() => {
                    // close modal
                    this.$modal.hide(this.name)

                    // notify user
                    this.$notify({
                        title: 'Success',
                        text: `Your alert has been created.`,
                        type: 'success'
                    });
                }).catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
    async created() {
        //
    }
}

</script>
