import EnergyProfile from '../../api/energy_profiles';

const state = {
    energyProfile: {}
};
 
const getters = {
    getEnergyProfile(state) {
        return state.energyProfile;
    },
};

const actions = { 
    /**
     * Get a energy profile by ID.

     * @returns null
     */
    async getEnergyProfile({commit}, id)
    {
        let energyProfile = await EnergyProfile.find(id);
        commit('setEnergyProfile', energyProfile);
        return;
    },
};

const mutations = {
    setEnergyProfile(state, energyProfile)
    {
        state.energyProfile = energyProfile;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
