<template>
    <div class="service-record-card mb-4 p-4 rounded border border-1 bg-gray-50 cursor-pointer hover:bg-gray-100">
        <!-- Wrapper -->
        <div class="flex">
            <!-- Chevron -->
            <div class="mr-2 md:mr-4" @click="$emit('click')">
                <v-icon :name="expanded ? 'chevron-up' : 'chevron-down'"></v-icon>
            </div>

            <!-- Content -->
            <div class="w-full">
                <!-- Upper -->
                <div class="flex justify-between" @click="$emit('click')">
                    <!-- Left -->
                    <div class="w-full">
                        <div class="uppercase">{{serviceRecord.title}}</div>
                        <div class="flex md:block justify-between flex-row-reverse">
                            <div class="text-red-800 md:hidden">{{serviceRecord.cost_in_local_currency}}</div>
                            <div class="text-gray-500">{{serviceRecord.service_date_short}}</div>
                        </div>
                    </div>
                    <!-- Right -->
                    <div class="hidden md:block text-red-800">{{serviceRecord.cost_in_local_currency}}</div>
                </div>

                <!-- Lower -->
                <div v-show="expanded">
                    <div class="text-gray-700 mt-4">{{serviceRecord.description}}</div>

                    <div id="service-record-documents" class="flex flex-col-reverse md:flex-row md:items-center mt-8">
                        <div id="add-document-button" class="flex items-center mt-4 md:mt-0 mr-8" @click="$modal.show('add-document-modal', {documentable: 'service_record'})">
                            <AddButton></AddButton>
                            <span v-if="!serviceRecord.documents || !serviceRecord.documents.length" class="hidden md:block ml-2 hover:text-orange-500">Add Document</span>
                            <span class="block md:hidden ml-2 hover:text-orange-500 text-sm">Add Document</span>
                        </div>
                        <DocumentButton v-for="document in serviceRecord.documents" :key="document.id" :document="document" class="mb-2 md:mb-0"></DocumentButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddButton from '../../Components/Buttons/AddButton.vue';
import DocumentButton from '../../Document/DocumentButton.vue';

export default {
    props: {
        serviceRecord: {
            required: true
        },
        expanded: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        click() {
            //
        }
    },  
    components: {
        AddButton,
        DocumentButton
    }
}
</script>

<style>

</style>