<template>
    <Modal :name="name" width="800" height="auto" @before-open="beforeOpen" :scrollable="true">
        <div class="p-6 pt-16 md:p-16">
            <div>
                <!-- Upper -->
                <div v-if="appliance.type" class="flex mb-4">
                    <div class="h-12 w-12 md:h-16 md:w-16 mr-6">
                        <img :src="image(appliance.type.image_url)" :alt="appliance.type.name">
                    </div>
                    <div>
                        <h3 class="mb-1 text-lg">{{appliance.type.name}}</h3>
                        <div v-if="appliance.manufacturer">{{appliance.manufacturer.name}}</div>
                    </div>
                </div>

                <!-- Lower -->
                <div class="mb-8">

                    <!-- Details -->
                    <table class="mb-4">
                        <tr>
                            <td class="pr-4">Model Number: </td>
                            <td>
                                <span v-if="appliance.model_number" class="text-gray-600 whitespace-nowrap">{{ appliance.model_number }}</span>
                                <span v-else class="text-gray-500">Unknown</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="pr-4">Serial Number: </td>
                            <td>
                                <span v-if="appliance.serial_number" class="text-gray-600 whitespace-nowrap">{{ appliance.serial_number }}</span>
                                <span v-else class="text-gray-500">Unknown</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="pr-4">Last Serviced: </td>
                            <td>
                                <span v-if="appliance.last_serviced_at_short" class="text-gray-600 whitespace-nowrap">{{ appliance.last_serviced_at_short }}</span>
                                <span v-else class="text-gray-500">Never</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="pr-4">Warranty Expires: </td>
                            <td>
                                <div v-if="appliance.warranty_expires_at">
                                    <span class="text-gray-600 whitespace-nowrap">{{ appliance.warranty_expires_at_short }}</span>
                                    <span v-if="!appliance.in_warranty" class="text-red-700 ml-2">Expired</span>
                                </div>
                                <div v-else>Unknown</div>
                            </td>
                        </tr>
                        <tr v-if="appliance.rented">
                            <td class="pr-4">Rented from: </td>
                            <td class="text-gray-700">{{ appliance.service_provider }}</td>
                        </tr>
                    </table>

                    <!-- Lifespan -->
                    <div v-if="appliance.lifespan">
                        <div class="flex justify-between">
                            <span>Lifespan - {{appliance.lifespan.age}}/{{appliance.type.lifespan}} years</span>
                            <span class="text-gray-600">Estimated</span>
                        </div>
                        <!-- Wrapper -->
                        <div class="w-full border border-gray-300">
                            <!-- Progress -->
                            <div class="h-4" 
                                :class="{
                                    'bg-green-500': appliance.lifespan.percent <= 0.7,
                                    'bg-yellow-500': appliance.lifespan.percent <= 0.9,
                                    'bg-red-500': appliance.lifespan.percent > 0.9
                                }" 
                                :style="{width: (appliance.lifespan.percent < 1 ? appliance.lifespan.percent*100 : 100) + '%'}"></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Associations -->
            <div class="flex flex-wrap gap-y-2 mb-12">
                <!-- Edit -->
                <Pill id="update-appliance-button" state="purple" :fill="true" class="mr-2 cursor-pointer hover:bg-gray-50" icon="cog" @click="showUpdateModal">Edit Info</Pill>

                <!-- Room -->
                <div v-if="appliance.room">
                    <Pill icon="map-marker-alt" class="mr-2 border-none">{{appliance.room?.name}}</Pill>
                </div>

                <!-- Utility -->
                <Pill v-if="appliance.utility" state="green" class="mr-2 hover:bg-gray-50 cursor-pointer" @click="showUtilityModal(appliance.utility)">
                    <img :src="image(appliance.utility.image_url)" alt="" class="h-4 mr-2"/>
                    <span>{{appliance.utility.type.name}} ({{appliance.utility.name}})</span>
                </Pill>
            </div>

             <!-- Notifcation -->
             <div v-if="!appliance.is_complete" class="p-3 mb-8 text-sm rounded border bg-yellow-50 border-yellow-100 text-yellow-900">
                <p class="mb-2">You appliance information is incomplete.  Please update the following information:</p>
                <ul class="list-disc list-inside">
                    <li v-for="(item, index) in appliance.incomplete_fields" :key="index">{{item}}</li>
                </ul>
            </div>

            <!-- Documents -->
            <div id="appliance-documents" class="mb-12">
                <div class="flex items-center mb-4">
                    <h2 class="text-lg mr-4">Documents</h2>
                    <AddButton id="add-document-button" @click="$modal.show('add-document-modal', {documentable: 'appliance'})"></AddButton>
                </div>
                <div class="flex" v-if="(appliance.documents && appliance.documents.length)">
                    <DocumentButton v-for="document in appliance.documents" :key="document.id" :document="document"></DocumentButton>
                </div>

                <div v-else>
                    <p>You do not have any documents yet.</p>
                </div>
            </div>

            <!-- Service Record -->
            <div  id="appliance-service-records" class="mt-10 mb-8">
                <div class="flex items-center mb-8">
                    <h2 class="text-lg mr-4">Service Records</h2>
                    <AddButton id="add-service-record-button" @click="$modal.show('create-service-record-modal', {recordable: 'appliance'})"></AddButton>
                </div>

                <div v-if="(appliance.service_records && appliance.service_records.length)">
                    <ServiceRecordCard v-for="record in appliance.service_records" :key="record.id" :service-record="record" :expanded="visible_record == record.id" @click="setServiceRecord(record.id)"></ServiceRecordCard>
                </div>

                <div v-else>
                    <p>You do not have any service records yet.</p>
                </div>
            </div>

            <!-- Alerts -->
            <div class="mt-10 mb-8">
                <div class="flex items-center mb-8">
                    <h2 class="text-lg mr-4">Alerts</h2>
                    <AddButton @click="$modal.show('create-device-alert-modal', {alertable: 'appliance'})"></AddButton>
                </div>

                <div v-if="(appliance.alerts && appliance.alerts.length)">
                    <AlertCard v-for="(alert, index) in appliance.alerts" :key="index" :alert="alert"></AlertCard>
                    <!-- <ServiceRecordCard v-for="record in appliance.alerts" :key="record.id" :service-record="record" :expanded="visible_record == record.id" @click="setServiceRecord(record.id)"></ServiceRecordCard> -->
                </div>

                <div v-else>
                    <p>You do not have any alerts yet.</p>
                </div>
            </div>

            <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            
        </div>

        <CreateDeviceAlertModal></CreateDeviceAlertModal>
        <DeleteDeviceAlertModal></DeleteDeviceAlertModal>
        <AddDocumentModal></AddDocumentModal>
        <CreateServiceRecordModal></CreateServiceRecordModal>

    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import DocumentButton from '../../../Document/DocumentButton.vue'
import { mapGetters } from 'vuex'
// import AddDocumentModal from './AddDocumentModal.vue'
import ServiceRecordCard from '../ServiceRecordCard.vue'
import AlertCard from '../AlertCard.vue'
import CreateDeviceAlertModal from '../CreateDeviceAlertModal.vue'
import DeleteDeviceAlertModal from '../DeleteDeviceAlertModal.vue'
import AddDocumentModal from '../AddDocumentModal.vue'
import CreateServiceRecordModal from '../CreateServiceRecordModal.vue'

export default {
    components: {
        Modal,
        DocumentButton,
        // AddDocumentModal,
        ServiceRecordCard,
        AlertCard,
        CreateDeviceAlertModal,
        DeleteDeviceAlertModal,
        AddDocumentModal,
        CreateServiceRecordModal
    },
    data()
    {
        return {
            name: 'appliance-modal',
            visible_record: null
        }
    },
    computed: {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            appliance: 'appliances/getAppliance',
            utility: 'utilities/getUtility'
        }),
    },
    methods:
    {
        beforeOpen(event) {},
        
        setServiceRecord(id)
        {
            let service_record = this.appliance.service_records.filter(record => record.id == id)[0]

            this.$store.dispatch('service_records/setServiceRecord', service_record);

            // Set accordian visibility
            this.visible_record = this.visible_record == id 
                ? null 
                : service_record.id;
        },
        showUpdateModal()
        {
            this.$modal.hide(this.name);
            this.$modal.show('update-appliance-modal', {appliance: this.appliance});
        },
        showUtilityModal(utility)
        {
            this.$store.commit('utilities/setUtility', this.property.utilities.filter(util => util.id == utility.id)[0]);
            this.$modal.hide(this.name);
            this.$modal.show('view-utility-modal')
        },
    },
    created()
    {
        // this.$modal.show('add-document-modal');
    }
}
</script>
