<template>
    <Modal :id="name" :name="name" width="500" height="auto">

        <div class="p-6 pt-16 md:p-16">

            <div id="forgot-password-form">
                <!-- Title -->
                <SectionTitle>Forgot Password</SectionTitle>

                <p class="mb-8">We will send you an email to reset your password.</p>

                <!-- Form -->
                <div id="forgot-password-form" class="mb-8">

                    <!-- Email -->
                    <FormRow class="">
                        <Label for="email">Email Address</Label>
                        <TextInput name="email" v-model="form.email" class="w-full" placeholder="user@example.com"/>
                        <Errors v-if="form.errors.has('email')">{{form.errors.first('email')}}</Errors>
                    </FormRow>

                    <!-- Actions -->
                    <FormRow class="mb-12">
                        <square-button id="submit-button" context="primary" class="mr-2" @click="submit">Send Email</square-button>
                        <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
                    </FormRow>
                </div>
            </div>
            
        </div>

        
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import Form from '@lukejm/form-js'
import {formDefaults} from './ForgotPasswordForm'

export default {
    components: {
        Modal,
    },
    data()
    {
        return {
            name: 'forgot-password-modal',
            form: new Form(formDefaults)
        }
    },
    methods:
    {
        submit()
        {
            this.form.validate();

            if(this.form.isValid) {
                axios.post('/api/v1/forgot-password', this.form.data())
                    .then(response => {
                        this.$notify({
                            title: 'Success',
                            text: response.data.message,
                            type: 'success'
                        }) 
                    }).catch(error => {
                        console.log(error);
                        this.$notify({
                            title: 'Error',
                            text: error.message,
                            type: 'error'
                        }) 
                    });
            }

            
        }
    }
}
</script>
