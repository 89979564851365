<template>
  <div>
    <div class="">
        <RoundButton context="primary-outline" class="w-full md:w-auto flex justify-center md:justify-start items-center gap-x-2" @click="getSharableLink()">
            <v-icon name="share"></v-icon>
            <span>Share Report</span>
        </RoundButton>
    </div>
  </div>
</template>

<script>

export default {
    props: {
        propertyId: {
            type: Number,
            // required: true
        }
    },
    methods: {
        getSharableLink() {
            axios.post(`/api/v1/retrofit/report/${this.propertyId}/share`)
                .then(response => {
                    let link = response.data.link;
                    navigator.clipboard.writeText(link);

                    this.$notify({
                        title: 'Success',
                        text: `Link copied to clipboard.`,
                        type: 'success'
                    });
                })
        }
    }
}
</script>

<style>

</style>