<template>
  <div class="card relative">
    <div v-if="admin" class="absolute top-2 right-2 px-1 rounded bg-gray-50 border border-gray-300 text-gray-500 text-sm uppercase">Admin</div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        admin: {
          type: Boolean,
          default: false,
        },
        // classes: {}
    },
    data() {
        return {
          ...mapGetters({
            // userIsAdmin: 'auth/isAdmin',
          })
        }
    },
}
</script>

<style scoped lang="scss">
  .card-shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
  }
  .card {
    @apply bg-white rounded p-4 md:p-6;
    @apply card-shadow;
  }
</style>