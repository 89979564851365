const state = {
    service_types: [],
};
 
const getters = {
    getServiceTypes(state) {
        return state.service_types;
    },
};

const actions = { 
    async getServiceTypes({commit})
    {
        await axios.get('/api/v1/service_types')
            .then(response => {
                // console.log(response.data)
                commit('setServiceTypes', response.data.service_types)
            })
    }
};

const mutations = {
    setServiceTypes(state, service_types)
    {
        state.service_types = service_types;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
