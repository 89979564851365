import Stats from '../../api/stats';

const state = {
    weeklyStats: {},
    monthlyStats: {},
};
 
const getters = {
    getWeeklyStats(state) {
        return state.weeklyStats;
    },
    getMonthlyStats(state) {
        return state.monthlyStats;
    },

};

const actions = { 
    /**
     * Get weekly stats.

     * @returns null
     */
    async getWeeklyStats({commit})
    {
        let stats = await Stats.findWeekly();
        commit('setWeeklyStats', stats);
        return;
    },
    /**
     * Get monthly stats.

     * @returns null
     */
    async getMonthlyStats({commit})
    {
        let stats = await Stats.findMonthly();
        commit('setMonthlyStats', stats);
        return;
    }
};

const mutations = {
    setWeeklyStats(state, stats)
    {
        state.weeklyStats = stats;
    },
    setMonthlyStats(state, stats)
    {
        state.monthlyStats = stats;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
