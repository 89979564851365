export default class Project {

    /**
    * Update a project.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.put(`/api/v1/projects/${id}`, fields);
        let project = request.data.project || {};

        return project;
    }

    /**
     * Find a project by it's ID.
     * @param {int} id 
     */
    // static async find(id) {

    //     let request = await axios.get(`/api/v1/projects/${id}`);
    //     let project = request.data.project || {};

    //     return project;
    // }

    /**
    * Create a project.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/projects`, fields);
    //     let project = request.data.project || {};

    //     return project;
    // } 
}