<template>
    <div 
        class="flex round items-center bg-white mx-2 md:mx-0 p-2 md:px-4"  
        :class="[
            header ? ' bg-gray-100 h-10' : '',
            isRecord ? 'hover:bg-gray-50 h-12' : ''
        ]"
    >

    <!-- Checkbox -->
    <!-- <div v-if="!title" class="w-12">
        <input type="checkbox" class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
    </div> -->

    <!-- Title -->
    <div class="flex-1">
        <slot></slot>
    </div>

    <div v-if="$slots.controls" class="md:hidden gap-x-2 mr-2">
        <slot name="controls"></slot>
    </div>

    <!-- Actions -->
    <div class="flex items-center justify-end" :class="[$slots.controls ? 'w-auto' : 'w-1/6 lg:w-1/8']">

        <div v-if="$slots.controls" class="hidden md:flex gap-x-2 mr-2">
            <slot name="controls"></slot>
        </div>

        <!-- Important Actions -->
        <div class="flex gap-x-1">
            <slot name="important-actions"></slot>
        </div>

        <!-- Other Actions -->
        <div class="relative">
            <RecordListActionButton v-if="!header && $slots['actions']" important text="More" @click="moreActionsVisible = !moreActionsVisible"><v-icon name="ellipsis-v" class="fill-current"></v-icon></RecordListActionButton>

            <div v-show="moreActionsVisible" v-click-outside="() => {moreActionsVisible = false}" class="w-auto z-10 absolute bg-white border border-gray-100 shadow-md right-0 rounded" style="width:250px">
                <div class="flex flex-col gap-y-2" @click="moreActionsVisible = false">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import RecordListActionButton from '../../Components/RecordList/RecordListActionButton.vue';
import ClickOutside from 'vue-click-outside';

export default {
    components: {
        RecordListActionButton
    },
    props: {
        header: {
            default: false,
            type: Boolean
        },
        title: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            moreActionsVisible: false
        }
    },
    computed: {
        isRecord() {
            return !this.header && !this.title;
        }
    },
    methods: {
        showMoreActions() {
            console.log('show more actions');
        }
    },
    mounted () {
        // prevent click outside event with popupItem.
        this.popupItem = this.$el
    },
    directives: {
        ClickOutside
    },
}
</script>

<style>

</style>