import User from '@models/User';
import axios from 'axios';
import UserAPI from '@api/users';
import UserActivityAPI from '@api/user_activity';
import AuthAPI from '@api/auth';
import periods_of_availability from './user_periods_of_availability';


const state = {
    authenticated_user: {},
    user: {
        owned_properties: [],
        periods_of_availability: [],
    },
    user_activity: [],
    users: [],
    properties: [],
    isAuthenticated: false,
    isAdmin: false
};

const modules = {
    periods_of_availability
};
 
const getters = {
    getUser(state)
    {
        return state.user;
    },
    getUsers(state)
    {
        return state.users;
    },
    getUserActivity(state)
    {
        return state.user_activity;
    },
    getProperties(state)
    {
        return state.properties;
    },
};

const actions = { 
    /**
     * Complete user registration.
     */
    async completeRegistration({commit}, fields) {
        await axios.post('/api/v1/complete-registration', fields)
            .then(response => {
                if(response.data.uuid) {
                    commit('setAuthenticatedUser', new User(response.data.user));
                };       
            })
    },
    /**
     * get a user by ID.
     * @param {int} id 
     */
    async getUser({commit}, id) {
        let user = await UserAPI.find(id);
        commit('setUser', new User(user));
    },

    /**
     * get a user's activity by ID.
     * @param {int} id 
     */
    async getUserActivity({commit}, id) {
        let activity = await UserActivityAPI.find(id);
        commit('setUserActivity', activity);
    },

    /**
     * get all users.
     */
    async getUsers({commit})
    {
        let users = await UserAPI.findAll();
        commit('setUsers', users);
    },
   
    async updateUser({commit, state}, fields) {

        return axios.patch(`/api/v1/users/${state.authenticated_user.id}`, fields)
            .then(response => {
                commit('updateUser', response.data.user)
                return response
            })
    },
    async getProperties({commit, state}, user_id)
    {
        if(user_id) {
            let properties = await UserAPI.getProperties(user_id);
            commit('setProperties', properties);
        }
    },

    // Add a note to the property
    async addNote({commit}, fields)
    {
        let successful = await UserAPI.addNote(fields.notable_id, fields);
    },
};

const mutations = {
    setAuthenticatedUser(state, user = null)
    {
        // console.log('setAuthenticatedUser', user);
        if(user == null) {
            state.isAuthenticated = false;
            state.isAdmin = false;
        } else {
            state.authenticated_user = new User(user);
            state.isAuthenticated = true;
            state.isAdmin = state.authenticated_user.is_admin;
        };
    },
    setUser(state, user = {})
    {
        state.user = user;
    },
    setUserActivity(state, activity)
    {
        state.user_activity = activity;
    },
    setUsers(state, users)
    {
        state.users = users;
    },
    setProperties(state, properties)
    {
        state.properties = properties
    },
    updateUser(state, user)
    {
        state.user = user;
    },
    deleteUser(state, user)
    {
        // TODO - delete a user with an HTTP request
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
}
