<template>
    <div 
        class="inline-block rounded border border-gray-200 px-2 fill-current"
        :class="{
            'border-gray-200 text-gray-900': state == 'standard',
            // 'hover:bg-gray-100': state == 'standard' && clickable,
            'border-orange-700 text-orange-700': state == 'orange' && !fill,
            // 'border-orange-600 hover:bg-orange-100': state == 'orange' && !fill && clickable,
            'border-orange-700 bg-orange-700 text-white': state == 'orange' && fill,
            // 'hover:bg-orange-100 hover:text-orange-900': state == 'orange' && fill && clickable,
        }"
        @click="$emit('click')"
    >
        <div class="flex items-center">
            <v-icon v-if="icon" class="mr-2 inline-block" :name="icon"></v-icon>
            <slot></slot>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        state: {
            type: String,
            default: 'standard'            
        },
        icon: {
            type: String,
            default: ''
        },
        fill: {
            type: Boolean,
            default: false,
        },
        // clickable: {
        //     default: false
        // }
    }
}
</script>