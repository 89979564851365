export default class RetrofitProgramTier {

     /**
     * Find a retrofit program tier by it's ID.
     * @param {int} id 
     */
    static async find(id) {
        let request = await axios.get(`/api/v1/retrofit_program_tiers/${id}`);
        let retrofit_program_tier = request.data.incentive_program_tier || {};

        return retrofit_program_tier;
    }

    /**
     * Get all retrofit program tiers.
     */
    static async findAll() {
        let request = await axios.get(`/api/v1/retrofit_program_tiers`);
        let retrofit_program_tiers = request.data.incentive_program_tiers || [];

        return retrofit_program_tiers;
    }

    /**
    * Create a retrofit_program_tier.
    * @param {object} fields
    */
    static async create(fields)
    {
        let request = await axios.post(`/api/v1/retrofit_program_tiers`, fields);
        let retrofit_program_tier = request.data.incentive_program_tier || {};

        return retrofit_program_tier;
    }

     /**
    * Update a retrofit_program_tier.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {})
    {
        let request = await axios.patch(`/api/v1/retrofit_program_tiers/${id}`, fields);
        let retrofit_program_tier = request.data.incentive_program_tier  || {};

        return retrofit_program_tier;
    }

    /**
    * Delete a retrofit program tier.
    */
    static async delete(id)
    {
        let request = await axios.delete(`/api/v1/retrofit_program_tiers/${id}`);
        let successful = request.data.success || false;
    
        return successful;
    }

    /********
    * GRANTS
    *********/
    static async createGrant(id, fields)
    {
        let request = await axios.post(`/api/v1/grants`, fields);
        let grant = request.data.grant || {};
        
        return grant;
    }

    /*********
    * CONDITIONS
    */
    static async createCondition(id, fields)
    {
        let request = await axios.post(`/api/v1/retrofit_program_tiers/${id}/conditions`, fields);
        let condition = request.data.condition || {};
        
        return condition;
    }


}