import axios from 'axios';
import User from '@models/User';
import AuthAPI from '@api/auth';

const state = {
    user: {},
    isAuthenticated: false,
    isAdmin: false,
    isSuperadmin: false
};
 
const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated
    },
    isAdmin(state) {
        return state.isAdmin
    },
    isSuperadmin(state) {
        return state.isSuperadmin
    },
    getUser(state) {
        return state.user;
    },
};

const actions = { 
    /**
     * Register a new user.
     */
    async register({commit}, fields) {
        let user = await AuthAPI.register(fields);
        commit('setUser', new User(user));
    },

    /**
     * Login a user.
     * @param {object} fields 
     */
    async login({ commit }, fields) {

        let user = await AuthAPI.login(fields);

        if(user.id) {
            commit('setUser', new User(user));
        }
    },

    /**
     * Logout the currently authenticated user.
     */
    async logout({ commit }) {
        let request = await axios.post('/api/v1/logout');

        if(request.status == 200) {
            commit('setUser');
            window.location.href = "/";
        } else {
            throw new Error(request.error?.message);
        };
    },

    /**
    * Get authenticated user.
    */
    async getUser({commit}) {
        let user = await AuthAPI.getUser();
        if(user.uuid) {
            commit('setUser', new User(user));

            // keep user store updated during transition
            commit('users/setAuthenticatedUser', new User(user), { root: true });
        }
    }
};

const mutations = {
    setUser(state, user = {})
    {

        if(!user.id) {
            state.user = null;
            state.isAuthenticated = false;
            state.isAdmin = false;
            state.isSuperadmin = false;
        } else {
            state.user = user;
            state.isAuthenticated = true;
            state.isAdmin = state.user.is_admin;
            state.isSuperadmin = state.user.is_superadmin;
        };

        localStorage.setItem('isAuthenticated', state.isAuthenticated);
        localStorage.setItem('isAdmin', state.isAdmin);
        localStorage.setItem('isSuperadmin', state.isSuperadmin);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
