<template>
  <div class="bg-neutral-700">
    <footer class="container mx-auto px-4 pt-12 pb-8">

        <!-- Upper -->
        <div class="flex flex-col md:flex-row gap-y-8 md:gap-x-16 lg:gap-x-20 w-full">

            <!-- Logo -->
            <a href="https://paddio.ca" class="flex justify-center -mt-4" target="_blank">
                <img src="/images/brand/paddio/full-tight-white.svg" alt="Paddio" class="h-16 md:h-28">
            </a>

            <!-- Links -->
            <div class="flex flex-col md:flex-row gap-y-8  md:gap-x-16 lg:gap-x-20 w-full">

              <!-- Company -->
              <div class="flex flex-col">
                <h3 class="footer-title">Company</h3>
                
                <!-- <a href="https://www.paddio.ca/about-us" class="footer-link" target="_blank">About Us</a> -->
                <!-- <a href="https://www.paddio.ca/investors" class="footer-link" target="_blank">Investors</a> -->
                <a href="https://www.paddio.ca/privacy-terms" class="footer-link" target="_blank">Terms of Service</a>
                <a href="https://www.paddio.ca/privacy-terms" class="footer-link" target="_blank">Privacy Policy</a>

              </div>

              <!-- Pricing -->
               <div class="flex flex-col">
                  <h3 class="footer-title">Pricing</h3>
                  <a href="https://www.paddio.ca/pricing" class="footer-link" target="_blank">Plans</a>
               </div>

               <!-- Socials -->
                <div class="flex flex-col">
                  <h3 class="footer-title">Get in touch</h3>

                  <!-- Social Media -->
                  <div class="flex gap-x-3">
                    <!-- LinkedIn -->
                    <a href="https://www.linkedin.com/company/91182515" class="footer-link h-6" target="_blank">
                      <img src="/images/brand/linkedin/linkedin-icon.png" class="h-6">
                    </a>

                    <!-- Instagram -->
                    <a href="https://www.instagram.com/paddioapp/" class="footer-link" target="_blank">
                      <img src="/images/brand/instagram/instagram-icon.png" class="h-6">
                    </a>

                    <!-- Facebook -->
                    <a href="https://www.facebook.com/profile.php?id=100094751655043" class="footer-link" target="_blank">
                      <img src="/images/brand/facebook/facebook-icon.png" class="h-6">
                    </a>
                  </div>

                  <!-- Feedback -->
                  <a href="#" @click.prevent="$modal.show('create-feedback-modal')" class="footer-link submit-feedback-link">Submit Feedback</a>
                
                </div>
            </div>
        </div>

        <!-- Divider -->
        <div class="w-full border-t border-white my-8"></div>

        <!-- Lower -->
        <div class="flex w-full items-center justify-center">
            <p class="text-white">© {{ year }} Paddio. All rights reserved.</p>
        </div>
    </footer>
    <CreateFeedbackModal></CreateFeedbackModal>
  </div>

</template>
  
<script>
import SidebarLink from "@components/Sidebar/SidebarLink";
import CreateFeedbackModal from '../Property/Components/CreateFeedbackModal.vue'


export default {
  components: {
      SidebarLink,
      CreateFeedbackModal
  },
  computed: {
    year () {
      return (new Date()).getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-title {
    @apply text-white font-bold mb-6;

  }
  .footer-link {
    @apply text-white hover:underline mb-4;

  }
</style>
  