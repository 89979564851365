<template>
  <div class="flex">
    <!-- Important Actions -->
    <div class="flex gap-x-1">
        <slot name="important-actions"></slot>
    </div>

    <!-- Other Actions -->
    <div class="relative">
        <ActionButton v-if="$slots['actions']" important text="More" @click="moreActionsVisible = !moreActionsVisible"><v-icon name="ellipsis-v" class="fill-current"></v-icon></ActionButton>

        <div v-show="moreActionsVisible" class="w-auto z-20 absolute bg-white border border-gray-100 shadow-md right-0 rounded" style="width:250px">
            <div class="flex flex-col gap-y-2" @click="moreActionsVisible = false">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>


  </div>
</template>

<script>
import ActionButton from './ActionButton.vue';
import ClickOutside from 'vue-click-outside';
// 
export default {
    components: {
        ActionButton
    },
    data() {
        return {
            moreActionsVisible: false
        }
    },
    directives: {
        ClickOutside
    },
    methods: {
        // showMore() {
        //     console.log('show more');
        //     this.moreActionsVisible = true
        // },
        // hideMore()
        // {
        //     console.log('hide more');
        //     this.moreActionsVisible = false
        // }
    }
}
</script>

<style>

</style>