<template>
  <router-link :to="{name: 'properties.view', params: {id: property.id}}">
<div class="md:flex mb-2 p-4 -mx-4 rounded bg-white hover:bg-gray-50">
      <!-- Left -->
      <div class="mb-4 md:mb-0">
          <!-- Image -->
          <div class="rounded flex overflow-hidden z-0 w-full md:w-52 max-h-36 md:max-w-none">
              <img :src="`/${property.image}`" class="object-cover" :alt="`${property.street}`">
          </div>
      </div>

      <!-- Right -->
      <div class="md:ml-6">
           <!-- Street -->
            <div class="text-orange-600 mb-2">
                {{ property.unit_number ? `${property.unit_number} -` : '' }}
                {{property.street}}
            </div>
            <!-- City, State, Country -->
            <div class="text-gray-500 mb-2 md:mb-6">
                {{property.city.name}}, {{property.state.name}}, {{property.country.name}}
            </div>

            <!-- Tenants -->
            <div v-if="property.tenants.length" class="flex items-center">
                <span class="mr-4">{{ 'Tenant' | pluralize(property.tenants.length, true)}}</span>
                <!-- Avatars -->
                <div class="flex">
                    <img v-for="tenant in property.tenants" :key="tenant.id" :src="tenant.avatar" class="bg-gray-300 rounded-full w-10 h-10 -ml-2 first-of-type:ml-2 border border-orange-600"/>
                </div>
            </div>

            <!-- Stats -->
            <div class="flex items-center text-gray-500 fill-current">
                <!-- Bathrooms -->
                <div class="items-center mr-4">
                    <v-icon class="mr-1 inline-block" name="bed"></v-icon>
                    {{ property.bedrooms_count}}
                </div>

                <!-- Bedrooms -->
                <div class="items-center">
                    <v-icon class="mr-1 inline-block" name="toilet"></v-icon>
                    {{ property.bathrooms_count}}

                </div>
            </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
    props: {
        property: {
            required: true,
            type: Object
        }
    }
}
</script>
