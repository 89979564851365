export default class EnergyProfile {

    /**
     * Find a energy Profile by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/properties/${id}/energy_profile`);
        let energyProfile = request.data.energyProfile || {};

        return energyProfile;
    }

    /**
    * Create a energy Profile.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/energy_profiles`, fields);
    //     let energyProfile = request.data.energyProfile || {};

    //     return energyProfile;
    // }

    /**
    * Update a energyProfile.
    * @param {int} id 
    * @param {object} fields 
    */
    // static async update(id, fields = {}) {
    //     let request = await axios.put(`/api/v1/energy_profiles/${id}`, fields);
    //     let energyProfile = request.data.energyProfile || {};

    //     return energyProfile;
    // }


}