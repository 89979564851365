export default class Stats {

    /**
     * Get weekly stats
     */
    static async findWeekly() {
        let request = await axios.get(`/api/v1/admin/weekly-stats`);
        let stats = request.data.stats || {};
        // console.log(request);

        return stats;
    }

    /**
     * Get monthly stats
     */
    static async findMonthly() {
        let request = await axios.get(`/api/v1/admin/monthly-stats`);
        let stats = request.data.stats || {};
        // console.log(request);

        return stats;
    }
}