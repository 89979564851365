import ServiceCompanyAPI from '@api/service_companies';

const state = {
    service_company: {
        service_types: [],
        cities: [],
        incentive_programs: [],
        documents: [],
        notes: [],
    },
    service_companies: [],
};
 
const getters = {
    getServiceCompany(state)
    {
        return state.service_company;
    },
    getServiceCompanies(state)
    {
        return state.service_companies;
    }
};

const actions = { 
    async getServiceCompany({commit}, id)
    {
        let service_company = await ServiceCompanyAPI.find(id);
        commit('setServiceCompany', service_company);
    },
    async addDocument({commit}, document)
    {   
        commit('addDocument', document);
    },
    async addCities({commit}, fields)
    {
        let successful = await ServiceCompanyAPI.addCities(fields.service_company_id, fields);
    },
    async removeCity({commit}, fields)
    {
        let successful = await ServiceCompanyAPI.removeCity(fields.service_company_id, fields);
    },
    async addIncentivePrograms({commit}, fields)
    {
        let successful = await ServiceCompanyAPI.addIncentivePrograms(fields.service_company_id, fields);
    },
    async removeIncentiveProgram({commit}, fields)
    {
        let successful = await ServiceCompanyAPI.removeIncentiveProgram(fields.service_company_id, fields);
    },
    // Add a note to the service company
    async addNote({commit}, fields)
    {
        let successful = await ServiceCompanyAPI.addNote(fields.notable_id, fields);
    },



    /**
    * Create a new service company.
    */
    async createServiceCompany({commit}, data) {
        let service_company = await ServiceCompanyAPI.create(data);
        commit('setServiceCompany', service_company);

    },

    /**
    * Update a service_company.
    * @param {object} data The form fields
    */
    async updateServiceCompany({commit}, data) {
        let successful = await ServiceCompanyAPI.update(data.id, data.fields);
        return successful;
    },

    // delete
    async deleteServiceCompany({commit}, id)
    {
        // await axios.delete(`/api/v1/service_companies/${id}`)
            // .then(response => commit('setServiceCompany', response.data.service_company))
    },

    async getServiceCompanies({commit})
    {
        let service_companies = await ServiceCompanyAPI.all();
        commit('setServiceCompanies', service_companies);
    }
};

const mutations = {
    setServiceCompany(state, service_company)
    {
        state.service_company = service_company;
    },
    setServiceCompanies(state, service_companies)
    {
        state.service_companies = service_companies;
    },
    addDocument(state, document)
    {   
        if(state.service_service_company.documents)
        {
            state.service_service_company.documents.push(document);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
