export default class RetrofitProgramTierCondition {

     /**
     * Find a retrofit program tier condition by it's ID.
     * @param {int} id 
     */
    // static async find(id) {
    //     let request = await axios.get(`/api/v1/retrofit_program_tier_conditions/${id}`);
    //     let retrofit_program_tier_condition = request.data.incentive_program_tier_condition || {};

    //     return retrofit_program_tier_condition;
    // }

    /**
     * Get all retrofit program tier conditions.
     */
    // static async findAll() {
    //     let request = await axios.get(`/api/v1/retrofit_program_tier_conditions`);
    //     let retrofit_program_tier_conditions = request.data.incentive_program_tier_conditions || [];

    //     return retrofit_program_tier_conditions;
    // }

    /**
    * Create a retrofit_program_tier_condition.
    * @param {object} fields
    */
    // static async create(fields)
    // {
    //     let request = await axios.post(`/api/v1/retrofit_program_tier_conditions`, fields);
    //     let retrofit_program_tier_condition = request.data.incentive_program_tier_condition || {};

    //     return retrofit_program_tier_condition;
    // }

     /**
    * Update a retrofit_program_tier_condition.
    * @param {int} id 
    * @param {object} fields 
    */
    // static async update(id, fields = {})
    // {
    //     let request = await axios.patch(`/api/v1/retrofit_program_tier_conditions/${id}`, fields);
    //     let retrofit_program_tier_condition = request.data.incentive_program_tier_condition  || {};

    //     return retrofit_program_tier_condition;
    // }

    /**
    * Delete a retrofit program tier condition.
    */
    static async delete(id)
    {
        let request = await axios.delete(`/api/v1/retrofit_program_tier_conditions/${id}`);
        let successful = request.data.success || false;
    
        return successful;
    }
}