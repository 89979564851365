<template>
    <!-- Wrapper -->
    <div class="bg-white border border-gray-300 rounded flex relative items-center">
        <div v-if="$slots.icon" class="px-2 text-gray-500">
          <slot name="icon" class="pl-4"></slot>
        </div>
        <!-- {{$attrs}} -->
        <select :name="`${name}[]`" :id="id || name" :value="value" @input="handleInput" :disabled="disabled" :multiple="multiple" class="bg-transparent w-full h-full px-4 py-2">
            <option v-if="!multiple" value="">Make a selection...</option>
            <slot></slot>
        </select>
        <v-icon name="chevron-down" class="absolute right-4 pointer-events-none"></v-icon>
    </div>
</template>

<script>
export default {
  props: {
    value: {},
    name: {},
    id: {},
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: this.multiple ? [] : null
    }
  },
  methods: {
    handleInput (event) {

      // let value = this.determineValue(event.target.value)

      this.$emit('input', event.target.value)
      this.$emit('change', event.target.value)
    },
    determineValue(value) {
      if (this.multiple) {

        console.log(this.selected);
        this.selected = this.selected.includes(value) 
          ? this.selected.filter(item => item !== value) 
          : this.selected.push(value)

      } else {
        this.selected = value
      }
      
      return value
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../../../svg/DownArrow.svg") no-repeat;
    background-position: calc(100% - 1rem);
  }
</style>
