<template>
    <a 
        @click.prevent="$emit('click')"
        href="#"
        class="flex items-center justify-center rounded-full text-white bg-orange-700 hover:bg-orange-600 w-7 h-7"
    >
        <v-icon name="plus" class="fill-current"></v-icon>
    </a>
</template>

<script>
export default {
    //
}
</script>
