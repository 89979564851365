<template>
  <div class="" style="font-size: 0.875rem">
    <!-- Title -->
    <div class="md:flex items-center mb-8 md:mb-0 mx-2 md:mx-0 p-2 md:px-4 h-14">
      <!-- Title -->
      <h2 class="text-lg mb-1 flex-1">{{ title }}</h2>

      <!-- Controls -->
      <div class="flex">
        <TextInput :id="`${title.toLowerCase()}-search`" type="search" placeholder="Search" class="flex-1 md:w-64 mr-2" v-model="list.filterText" @input="list.filterRecords()">
            <template v-slot:icon><v-icon name="search" class="fill-current"></v-icon></template>
        </TextInput>
        <!-- Actions -->
        <slot name="title-actions" :list="list"></slot>
      </div>

    </div>

    <!-- List Header -->
    <slot name="header" :list="list"></slot>

    <!-- Loading Bar -->
    <div v-if="list.loading" class="loading-bar"></div>

    <!-- Record List-->
    <div>
        <!-- Records if Exist -->
        <div v-for="(record, index) in list.recordsForPage" :key="index">
        <slot name="record" v-bind:record="record"></slot>
      </div>
    </div>
  
    <!-- Footer -->
    <RecordListFooter :list="list"></RecordListFooter>
  </div>
</template>

<script>
import RecordList from './RecordList.js';
import RecordListFooter from './RecordListFooter.vue';


export default {
  components: {
    RecordListFooter,
  },
  props: {
    title: {
      type: String,
      default: 'List Title'
    },
    records: {
      type: Array,
      default: () => [],
      // required: true,
    },
    sortBy: {
      type: Array,
      default: () => [],
    },
    filterBy: {
      type: Array,
      default: () => [],
    },
    recordsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      list: new RecordList([], {
        filterBy: this.filterBy,
        sortBy: this.sortBy,
        recordsPerPage: this.recordsPerPage,
      }),
    }
  },
  watch: {
      records: {
        handler: function (val, oldVal) {
            this.list.setRecords(val)
        },
        deep: true
      }
  },
}
</script>

<style lang="scss" scoped>

</style>