const state = {
    manufacturer: {},
    manufacturers: [],
}

const getters = {
    getManufacturer: state => state.manufacturer,
    getManufacturers: state => state.manufacturers,
}

const actions = {
    async getManufacturers({commit})
    {
        await axios.get(`/api/v1/manufacturers`)
            .then(response => {
                commit('setManufacturers', response.data.manufacturers)
            });
    },
}

const mutations = {
    setManufacturer(state, manufacturer = {})
    {
        state.manufacturer = manufacturer;
    },
    setManufacturers(state, manufacturers)
    {
        state.manufacturers = manufacturers;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
