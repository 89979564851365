export default class User {


    /**
     * Find a user by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/users/${id}`);
        return request.data?.user || {};
    }



    /**
    * Find all properties.
    *
    * @return {Array} users
    */
    static async findAll() {
        let request = await axios.get(`/api/v1/users`);
        return request.data?.users || {};
    }

    /**
     * Get properties for a user by ID.
     * @param {int} id 
     * @returns {Array}
     */
    static async getProperties(id)
    {
        if(id) {
            let response = await axios.get(`/api/v1/users/${id}/properties`);
            return response.data?.properties || {};
        }
    }

     /**
    * Add a note to a user.
    */
     static async addNote(user_id, fields = {}) {
        let request = await axios.post(`/api/v1/users/${user_id}/notes`, fields);
        let note = request.data.note || {};

        return note;
    }
}