export default class ProjectPlan {

    /**
     * Get all project plans.
     */
    static async findAll() {

        let request = await axios.get(`/api/v1/project_plans`);
        return request.data.project_plans || [];
    }

    /**
     * Find a project_plan by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/project_plans/${id}`);
        return request.data.project_plan || {};
    }

    /**
    * Create a project_plan.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/project_plans`, fields);
    //     let project_plan = request.data.project_plan || {};

    //     return project_plan;
    // }

    /**
    * Update a project_plan.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/project_plans/${id}`, fields);
        let project_plan = request.data.project_plan || {};

        return project_plan;
    }
}