export default class RetrofitProgram {

     /**
     * Find a retrofit program by it's ID.
     * @param {int} id 
     */
    static async find(id) {
        let request = await axios.get(`/api/v1/retrofit_programs/${id}`);
        let retrofit_program = request.data.retrofit_program || {};

        return retrofit_program;
    }

    /**
     * Get all retrofit programs.
     */
    static async findAll() {
        let request = await axios.get(`/api/v1/admin/retrofit_programs`);
        let retrofit_programs = request.data.retrofit_programs || [];

        return retrofit_programs;
    }

    /**
    * Create a retrofit_program.
    * @param {object} fields
    */
    static async create(fields)
    {
        let request = await axios.post(`/api/v1/retrofit_programs`, fields);
        let retrofit_program = request.data.retrofit_program || {};

        return retrofit_program;
    }

     /**
    * Update a retrofit_program.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {})
    {
        let request = await axios.patch(`/api/v1/retrofit_programs/${id}`, fields);
        let retrofit_program = request.data.retrofit_program || {};

        return retrofit_program;
    }

    /**
    * Delete a retrofit program.
    */
    static async delete(id)
    {
        let request = await axios.delete(`/api/v1/retrofit_programs/${id}`);
        let successful = request.data.success || false;
    
        return successful;
    }

    /**
    * Activate a retrofit program.
    */
    static async activate(id)
    {
        let request = await axios.patch(`/api/v1/retrofit_programs/${id}/activate`);
        let successful = request.data.success || false;

        return successful;
    }

    /**
    * Deactivate a retrofit program.
    */
    static async deactivate(id)
    {
        let request = await axios.patch(`/api/v1/retrofit_programs/${id}/deactivate`);
        let successful = request.data.success || false;

        return successful;
    }

    /********
    * GRANTS
    *********/
    static async createGrant(id, fields)
    {
        let request = await axios.post(`/api/v1/grants`, fields);
        let grant = request.data.grant || {};
        
        return grant;
    }


}