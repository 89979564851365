<template>
    <div class="mb-4 md:mb-6 w-full">
        <slot></slot>
    </div>
</template>

<script>
export default {

}
</script>
