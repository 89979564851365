import RetrofitReportAPI from "@api/retrofit_report";

const state = {
    property: {
        address: {
            formatted: '',
            city: {},
            state: {},
            country: {},
            street: null
        },
        utilities: [],
        rooms: [],
        property_type: {}
    },
    retrofit_programs: [],
    // program: {
    //     loans: [],
    //     grants: [],
    //     rebates: [],
    //     property: {}
    // },
    energy_profile: {
        energy_reports: []
    }
};
 
const getters = {
    getProperty(state)
    {
        return state.property;
    },
    getRetrofitPrograms(state) {
        return state.retrofit_programs;
    },
    // getProgram(state) {
    //     return state.program;
    // },
};

const actions = { 
    async getPropertyByID({commit}, id)
    {
        await axios.get(`/api/v1/retrofit/report/${id}`)
            .then(response => {
                commit('setCurrentProperty', response.data.property)
            })
    },
    async getPropertyByToken({commit}, token)
    {
        await axios.get(`/api/v1/retrofit/report/view/${token}`)
            .then(response => {
                commit('setCurrentProperty', response.data.property)
            })
    },
    async getRetrofitPrograms({commit}, id)
    {
        await axios.get(`/api/v1/admin/retrofit_programs`)
            .then(response => {
                commit('setRetrofitPrograms', response.data.retrofit_programs)
                // console.log(response.data.retrofit_programs)
            })
    },
    // async getProgram({commit}, id)
    // {
    //     await axios.get(`/api/v1/admin/retrofit_programs/${id}`)
    //         .then(response => {
    //             commit('setProgram', response.data.program)
    //         })
    // }
};

const mutations = {
    setCurrentProperty(state, property)
    {
        state.property = property;
    },
    setRetrofitPrograms(state, retrofit_programs)
    {
        state.retrofit_programs = retrofit_programs;
    },
    // setProgram(state, program)
    // {
    //     state.program = program;
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
