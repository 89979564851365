import axios from 'axios';
import Property from '@api/properties';

const state = {
    property: {
        address: {
            city: {},
            state: {},
            country: {},
            street: null
        },
        notes: [],
        projects: [],
        utilities: [],
        rooms: [],
        property_type: {},
        documents: [],
        energy_profile: {
            energy_reports: []
        },
        householdDetails: {}
    },
    properties: [],
    property_types: [],
    appliance_types: [],
    floor_types: [],
    roof_types: [],
    projects: [],
    incentives: {
        grants: [],
        loans: [],
        rebates: []
    }
};
 
const getters = {
    getProperty(state)
    {
        return state.property;
    },
    getCurrentProperty(state)
    {
        return state.property;
    },
    getProperties(state)
    {
        return state.properties;
    },
    getPropertyTypes(state)
    {
        return state.property_types
    },
    getUtilities(state)
    {
        return state.property.utilities
    },
    getApplianceTypes(state)
    {
        return state.appliance_types
    },
    // getManufacturers(state)
    // {
    //     return state.manufacturers
    // },
    getFloorTypes(state)
    {
        return state.floor_types
    },
    getRoofTypes(state)
    {
        return state.roof_types
    },
    getProjects(state)
    {
        return state.projects
    },
    getIncentives(state)
    {
        return state.incentives
    },

    // getGasUtilities(state) {
    //     return state.property.utilities.filter(utility => utility.type.name == 'Gas');
    // }
};

const mutations = {
    setProperties(state, properties)
    {
        state.properties = properties;
    },
    setCurrentProperty(state, property)
    {
        state.property = property;
    },
    setProjects(state, projects)
    {
        state.projects = projects;
    },
    setPropertyTypes(state, property_types)
    {
        state.property_types = property_types
    },
    setUtilities(state, utilities)
    {
        state.utilities = utilities
    },
    setApplianceTypes(state, appliance_types)
    {
        state.appliance_types = appliance_types
    },
    setFloorTypes(state, floor_types)
    {
        state.floor_types = floor_types
    },
    setRoofTypes(state, roof_types)
    {
        state.roof_types = roof_types
    },
    setProjects(state, projects)
    {
        state.projects = projects
    },
    setIncentives(state, incentives)
    {
        state.incentives = incentives
    },
    addAppliance(state, appliance)
    {
        state.property ? state.property.appliances.push(appliance) : null
    },
    updateAppliance(state, appliance)
    {    
        if(state.property && state.property.appliances) {
            state.property.appliances.forEach((item, index) => {
                if(item.id == appliance.id) {
                    state.property.appliances[index] = appliance;
                }
            });
        };
    },
    removeAppliance(state, id)
    {
            if(state.property.appliances) {
                state.property.appliances = state.property.appliances.filter(item => item.id != id);
            }
    },
    addRoom(state, room)
    {
        if(!state.property.rooms) {
            state.property.rooms = []
        };

        state.property.rooms.push(room)
    },
    addServiceRecord(state, serviceRecord)
    {
        if(!state.property.service_records) {
            state.property.service_records = []
        };

        state.property.service_records.unshift(serviceRecord)
    },
    addDocument(state, document)
    {   
        state.property.documents.push(document);
    },
    addUtility(state, utility)
    {   
        state.property.utilities.push(utility); 
    },
    updateUtility(state, utility)
    {    
        let type_name = utility.type.name.toLowerCase();

        if(state.property && state.property.utilities) {
            state.property.utilities.forEach((item, index) => {
                if(item.id == utility.id) {
                    state.property.utilities[index] = utility;
                }
            });
        };
    },
    deleteUtility(state, utility)
    {    
        let type_name = utility.type.name.toLowerCase();

        if(state.property && state.property.utilities && state.property.utilities[type_name]) {
            state.property.utilities[type_name] = state.property.utilities[type_name].filter(item => {
                return item.id != utility.id
            })
        };
    },
    updateBannerImage(state, path)
    {
        state.property.image = path;
    }
};

const actions = {

    /**
    * Get all properties.
    */
    async getProperties({commit}) {
        let properties = await Property.all();

        if(properties.length) {
            commit('setProperties', properties);
        }
    },

    /**
     * Get a property by ID.

     * @returns null
     */
    async getProperty({commit}, id)
    {
        let property = await Property.find(id);
        commit('setCurrentProperty', property);
        return;
    },
    async getListedProperty({commit}, id)
    {
        await axios.get(`/api/v1/listings/${id}`)
            .then(response => commit('setListedProperty', response.data.property))
    },
    
    async generateEnergyReport({commit, dispatch}, id) {
        return axios.post(`/api/v1/properties/${id}/create_energy_report`);   
    },

    async createProperty({commit}, fields)
    {
        // Add user ID to form fields
        fields.user_id = this.getters['auth/getUser'].id;

        await axios.post('/api/v1/properties', fields)
            .then(response => {
                commit('setCurrentProperty', response.data)
            }).catch(error => console.log(error))
    },
    /**
     * Update a property.
     *
     */
    async updateProperty({commit}, fields) {
        // console.log(fields);
        await axios.patch(`/api/v1/properties/${fields.id}`, fields)
            .then(response => {
                // commit('setCurrentProperty', response.data.property)
            })
    },

    // delete property
    async deleteProperty({commit}, id) {
        let successful = await Property.delete(id);

        if(successful) {
            commit('setProperties', {});
        }       
    },
    async getPropertyTypes({commit})
    {
        await axios.get('/api/v1/property_types', {
            user_id: this.getters['auth/getUser'].id
        })
            .then(response => {
                commit('setPropertyTypes', response.data.property_types)
            })
    },
    // Add a note to the service company
    async addNote({commit}, fields)
    {
        let successful = await Property.addNote(fields.notable_id, fields);
    },
    async getApplianceTypes({commit})
    {
        await axios.get('/api/v1/appliance_types')
            .then(response => {
                commit('setApplianceTypes', response.data.appliance_types)
            })
    },
    async getComponents({commit, state}, property_id)
    {
        await axios.get(`/api/v1/properties/${property_id}/components`)
            .then(response => {
                commit('setComponents', response.data.components)
            });
    },
    async getFloorTypes({commit, state})
    {
        if(!state.floor_types.length) {
            await axios.get(`/api/v1/properties/getFloorTypes`)
                .then(response => {
                    commit('setFloorTypes', response.data.floor_types)
                });
        }
    },
    async getProjects({commit, state}, property_id)
    {
        await axios.get(`/api/v1/properties/${property_id}/projects`)
            .then(response => {
                // console.log(response);
                commit('setProjects', response.data.projects)
            });
    },
    async getIncentives({commit, state}, property_id)
    {
        await axios.get(`/api/v1/properties/${property_id}/incentives`)
            .then(response => {
                // console.log(response);
                commit('setIncentives', response.data.incentives)
            });
    },
    async getRoofTypes({commit, state})
    {
        if(!state.roof_types.length) {
            await axios.get(`/api/v1/properties/getRoofTypes`)
                .then(response => {
                    commit('setRoofTypes', response.data.roof_types)
                });
        }
    },
    async addAppliance({commit}, fields)
    {
        let property_id = fields.property_id;

        await axios.post(`/api/v1/properties/${property_id}/appliances`, fields)
            .then(response => {
                commit('addAppliance', response.data.appliance);
                
                // TODO - decouple from property and utilize "room" endpoint
                commit('rooms/addAppliance', response.data.appliance, {root: true});
            });
    },
    async updateAppliance({commit}, appliance)
    {
        commit('updateAppliance', appliance);
    },
    async removeAppliance({commit}, id)
    {  
        commit('removeAppliance', id);
    },
    async addRoom({commit, state}, fields)
    {
        await axios.post(`/api/v1/properties/${state.property.id}/rooms`, fields)
            .then(response => {
                commit('addRoom', response.data.room)
            });
    },
    async addServiceRecord({commit}, fields)
    {
        await axios.post(`/api/v1/properties/${state.property.id}/service_records`, fields)
            .then(response => {
                commit('addServiceRecord', response.data.service_record)
            });
    },
    async addDocument({commit}, document)
    {   
        commit('addDocument', document);
    },
    async addUtility({commit, state}, fields)
    {
        await axios.post(`/api/v1/properties/${state.property.id}/utilities`, fields)
            .then(response => {
                commit('addUtility', response.data.utility)
            });
    },
    async updateUtility({commit}, utility)
    {
        commit('updateUtility', utility);
    },
    async deleteUtility({commit}, utility)
    {
        commit('deleteUtility', utility);
    },
    async updateBannerImage({commit}, path)
    {
        commit('updateBannerImage', path)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}