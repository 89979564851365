<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
           <!-- Title -->
           <SectionTitle>Delete Alert</SectionTitle>

            <!-- Form -->
            <div class="mb-8">
                <p class="mb-8">Are you sure you want to delete this alert?</p>
                <!-- Details -->
                <div class="flex mb-1">
                    <p class="w-1/6 md:w-1/5 mr-3">Title</p>
                    <p class="text-gray-700">{{ alert.title }}</p>
                </div>
                <div class="flex mb-1">
                    <p class="w-1/6 md:w-1/5 mr-3">Date</p>
                    <p class="text-gray-700">{{ alert.alert_date_short }}</p>
                </div>
                <div class="flex mb-1">
                    <p class="w-1/6 md:w-1/5 mr-3">Device</p>
                    <p v-if="(alert.device && alert.device.type)" class="text-gray-700">
                        {{ alert.device.type.name }} 
                        {{ alert.device.model_number ? `(${alert.device.model_number})` : ''}}</p>
                </div>
            </div>

            <!-- Actions -->
            <FormRow>
                <square-button context="danger" class="mr-2" @click="submit">Delete</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
// import {formDefaults, formConfig} from '@property/Forms/UpdateRoomForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'


export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton
    },
    data()
    {
        return {
            name: 'delete-alert-modal',
            form: new Form(),
        }
    },
    computed:
    {
        ...mapGetters({
            property: 'properties/getCurrentProperty',
            alert: 'alerts/getAlert'
        })
    },
    methods:
    {
        beforeOpen(event)
        {
        //     // if(event.params) {
        //         // this.property = event.params.property;
        //         this.form.property_id = this.property.id
        //     // }
        },
        submit()
        {
            
            this.$store.dispatch('alerts/deleteAlert')
                .then(() => {

                    // close modal
                    this.$modal.hide(this.name)

                    // notify user
                    this.$notify({
                        title: 'Success',
                        text: `Your alert has been deleted.`,
                        type: 'success'
                    });
                }).catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
    async created() {
        //
    }
}

</script>
