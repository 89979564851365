<template>
    <div class="relative block w-full md:inline-block md:mr-2 mb-2 p-4 md:rounded border">
        <div class="flex justify-between items-center">
            <div v-if="alert" class="flex items-center">
                <v-icon class="fill-current mr-4" :class="[alert.in_next_month ? 'text-yellow-500' : 'text-green-600']" name="circle"></v-icon>
                <div class="uppercase mr-4 flex-1 md:w-auto">
                    <div>{{alert.title}}</div>
                    <div class="text-gray-700 md:hidden">{{alert.alert_date_short}}</div>
                </div>
                <div class="hidden md:block text-gray-700">{{alert.alert_date_short}}</div>
            </div>
            <MoreButton name="trash" @click="deleteAlert"></MoreButton>

        </div>
    </div>
</template>

<script>
import MoreButton from '../../Components/Buttons/MoreButton.vue';

export default {
    components: {
        MoreButton
    },
    props: {
        alert: {}
    },
    methods: {
        async deleteAlert()
        {
            await this.$store.dispatch('alerts/setAlert', this.alert);
            this.$modal.show('delete-alert-modal');
        }
    }
}
</script>

<style lang="scss" scoped>
    @keyframes blinker {
        0% { opacity: 0.7; }
        50% { opacity: 1; }
        100% { opacity: 0.7; }
    }

    .blinking {
        animation-name: blinker;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(.5, 0, 1, 1);
        animation-duration: 2s;
    }
</style>