<template>
  <div >

    <!-- Important -->
    <button 
      class="flex hover:bg-gray-100 items-center text-gray-700" 
      :class="[important ? 'rounded-full w-10 h-10 justify-center' : 'w-full py-2 px-4']"
      @click="$emit('click', $event)" 
      :title="text"
    >
        <slot></slot>
        <div v-if="!important" class="ml-4 text-gray-900">
          {{ text }}
        </div>
    </button>



  </div>
  
</template>

<script>
export default {
    props: {
        text: {
            type: String
        },
        important: {
            default: false,
            type: Boolean
        }
    }
}
</script>

<style>

</style>