/**
 * Multiple helper functions to help with front end design.
 * 
 */

export default {
    methods: {
        /**
         * Get resolved path to an image.
         */
        image (path) {
            return `/images/${path}`;
        },

        /**
         * Get resolved api path.
         */
        api(path, version = 1) {
            return `/api/v${version}/${path}`;
        },
    }
}
