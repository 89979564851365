<template>
    <div class="sidebar-link flex items-center" :class="`hover:text-gray-700`" @click="$emit('click')">
        <div class="flex justify-center w-8">
            <!-- Icon -->
            <v-icon :name="icon" class="block mr-4 fill-current" scale="1.2"></v-icon>
        </div>
     
        <!-- Link -->
        <router-link :to="href">
            <slot></slot>
        </router-link>
        <!-- Counter -->
        <div></div>
    </div>
</template>

<script>

export default {
    components: {
        //
    },
    props: {
        href: {
            default: '#',
        },
        icon: {
            default: 'home',
            type: String
        },
        counter: {
            default: 0,
            type: Number
        }
    },
}
</script>
