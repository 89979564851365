let formDefaults =  {
    email: {
        value: '',
        rules: 'required|string|email'
    },
}

let formConfig = {}

let formStubs = {
    email: 'johnsmith@email.com'
}

export { formDefaults, formConfig, formStubs }
