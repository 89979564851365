import NoteAPI from '@api/notes';

const state = {
    note: {
        user: {},
    },
    notes: [],
};
 
const getters = {
    getNote(state)
    {
        return state.note;
    },
    getNotes(state)
    {
        return state.notes;
    }
};

const actions = { 
    // async getNote({commit}, id)
    // {
    //     let note = await NoteAPI.find(id);
    //     commit('setNote', note);
    // },
    // async getNotes({commit})
    // {
    //     let notes = await NoteAPI.all();
    //     commit('setNotes', notes);
    // },
    // async addDocument({commit}, document)
    // {   
    //     commit('addDocument', document);
    // },

    /**
     * Delete a note.
     */
    async deleteNote({commit}, id)
    {
        let successful = await NoteAPI.delete(id);
        
        if(successful) {
            commit('setNote');
        }
    }
   
    /**
    * Update a note.
    * @param {object} data The form fields
    */
    // async updateNote({commit}, data) {
    //     let successful = await NoteAPI.update(data.id, data.fields);
    //     return successful;
    // },

    // delete
    // async deleteNote({commit}, id)
    // {
    //     // await axios.delete(`/api/v1/notes/${id}`)
    //         // .then(response => commit('setNote', response.data.note))
    // },

    
};

const mutations = {
    setNote(state, note)
    {
        state.note = note;
    },
    setNotes(state, notes)
    {
        state.notes = notes;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
