<template>
    <div>
        <Modal :id="name" :name="name" width="500" height="auto">
            <div class="p-6 pt-16 md:p-16">
                <!-- Title -->
                <SectionTitle>Login</SectionTitle>

                <!-- Form -->
                <div class="mb-8">
                    <!-- Email -->
                    <FormRow class="">
                        <Label for="email">Email Address</Label>
                        <TextInput name="email" v-model="form.email" class="w-full" placeholder="user@example.com"/>
                        <Errors v-if="form.errors.has('email')">{{form.errors.first('email')}}</Errors>
                    </FormRow>

                    <!-- Password -->
                    <FormRow>
                        <Label for="password">Password</Label>
                        <TextInput name="password" type="password" v-model="form.password" class="w-full" placeholder="********"/>
                        <Errors v-if="form.errors.has('password')">{{form.errors.first('password')}}</Errors>
                    </FormRow>

                    <!-- Remember & Forgot Password -->
                    <FormRow class="mb-12">
                        <div class="flex items-center justify-between mb-4 md:mb-0">
                            <!-- Remember -->
                            <div>
                                <label for="remember" class="hidden">Remember Me?</label>
                                <div>
                                    <input type="checkbox" name="remember" id="remember" v-model="form.remember" class="mr-2"/>
                                    <span>Remember me</span>
                                </div>
                            </div>

                            <!-- Forgot Password -->
                            <a href="#" id="forgot-password-link" @click.prevent="$modal.hide(name); $modal.show('forgot-password-modal')" class="text-orange-700 hover:underline">Forgot Password?</a>
                        </div>
                    </FormRow>

                    <!-- Actions -->
                    <FormRow class="mb-12">
                        <square-button context="orange" class="mr-2" @click="getAuthentificationToken()">Submit</square-button>
                        <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
                    </FormRow>
                </div>

                <!-- Sign Up -->
                    <!-- <div>
                        Don't have an account with us? Sign up <router-link to="/signup" class="text-orange-500 hover:text-orange-600">here</router-link>.
                    </div> -->
            </div>
        </Modal>

        <!-- Modals -->
        <ForgotPasswordModal></ForgotPasswordModal>
    </div>
  
</template>

<script>
import Modal from '@components/Modal'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@forms/LoginForm'
import ForgotPasswordModal from '../Components/ForgotPasswordModal.vue'
import { mapGetters } from 'vuex'
import AuthAPI from '@api/auth'

export default {
    components: {
        Modal,
        ForgotPasswordModal
    },
    data()
    {
        return {
            name: 'login-modal',
            form: new Form(formDefaults, {
                axios: {
                    withCredentials: true
                }
            })
        }
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            isAdmin: 'auth/isAdmin',
        })
    },
    methods:
    {
        /**
         * Get authentication token.
         */ 
        async getAuthentificationToken()
        {
            await AuthAPI.getAuthentificationToken();
            this.login();
        },
        /**
         * Login user.
         */
        async login()
        {
            this.form.validate();

            if(this.form.isValid) {
                try {
                    await this.$store.dispatch('auth/login', this.form.data())     
                    
                    if(this.$store.getters['auth/isAdmin']) {
                        this.$router.push('/admin')
                    } else if(this.$store.getters['auth/isAuthenticated']) {
                        this.$router.push('/properties')
                    } else {
                        console.log('Not Authenticated')
                    }
                } catch(error) {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    }) 
                };
            }
        }
    }
}
</script>
