<template>
  <a :href="href" @click="$emit('click')" class="text-orange-700 hover:underline" target="_blank">
    <slot></slot>
  </a>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
            default: '#',
        }
    }
}
</script>

<style>

</style>