export default class UserPeriodOfAvailability {


    /**
    * Create a period_of_availability.
    */
    static async create(user_id, fields) {
        let request = await axios.post(`/api/v1/users/${user_id}/periods_of_availability`, fields);
        let period_of_availability = request.data.period_of_availability || {};

        return period_of_availability;
    }

    /**
    * Update a period_of_availability.
    * @param {int} id 
    * @param {object} fields 
    */
    // static async update(id, fields = {}) {
    //     let request = await axios.patch(`/api/v1/periods_of_availability/${id}`, fields);
    //     // console.log('update', request.data);
    //     let period_of_availability = request.data.period_of_availability || {};

    //     return period_of_availability;
    // }

    /**
     * Find a period_of_availability by it's ID.
     * @param {int} id 
     */
    // static async find(id) {

    //     let request = await axios.get(`/api/v1/periods_of_availability/${id}`);
    //     let period_of_availability = request.data.period_of_availability || {};

    //     return period_of_availability;
    // }

    /**
    * Get all a user's periods_of_availability.
    */
    static async findAll(user_id) {
        let response = await axios.get(`/api/v1/users/${user_id}/periods_of_availability`);
        let periods_of_availability = response.data.periods_of_availability || [];

        return periods_of_availability;
    }

    /**
    * Delete a period_of_availability.
    */
    static async delete(id) {
        let request = await axios.delete(`/api/v1/periods_of_availability/${id}`);
        let successful = request.data.success || false;

        return successful;
    }
}