import HouseholdDetailsAPI from '@api/household_details';

const state = {
    household_details: {},
};
 
const getters = {
    getHouseholdDetails(state)
    {
        return state.household_detail;
    },
};

const actions = { 

    /**
     * Get all condition.
     */
    // async getHouseholdDetailss({commit})
    // {
    //     let household_details = await HouseholdDetailsAPI.findAll();
    //     commit('setHouseholdDetailss', household_details);
    //     return;
    // },

    /**
    * Get a condition.
    */
    // async getHouseholdDetails({commit}, id)
    // {
    //     let household_detail = await HouseholdDetailsAPI.find(id);
    //     commit('setHouseholdDetails', household_detail);
    //     return;
    // },

    /**
     * Create a new retrofit program.
     */
    // async createHouseholdDetails({commit}, fields)
    // {
    //     let household_detail = await HouseholdDetailsAPI.create(fields);
    //     commit('setHouseholdDetails', household_detail);
    //     return;
    // },

    /**
    * Update a retrofit program.
    */
    async updateHouseholdDetails({commit}, {id, fields}) {
        let household_details = await HouseholdDetailsAPI.update(id, fields);
        commit('setHouseholdDetails', household_details);
        return;
    },

   
};

const mutations = {
    setHouseholdDetails(state, household_details = {})
    {
        state.household_details = household_details;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
