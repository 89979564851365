const state = {
    air_conditioning_types: [],
};
 
const getters = {
    getAirConditioningTypes(state) {
        return state.air_conditioning_types;
    },
};

const actions = { 
    async getAirConditioningTypes({commit}) {
        await axios.get(`/api/v1/air_conditioning_types`,{
            user_id: this.getters['auth/getUser'].id
        })
            .then(response => commit('setAirConditioningTypes', response.data.air_conditioning_types))
    }
    
};

const mutations = {
    setAirConditioningTypes(state, air_conditioning_types)
    {
        state.air_conditioning_types = air_conditioning_types;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
