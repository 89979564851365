require('./bootstrap');

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VModal from 'vue-js-modal'
import VueScreen from 'vue-screen';
import titleMixin from './mixins/titleMixin'
import helpers from './mixins/helperFunctions'
import pluralize from 'pluralize';
import Notifications from 'vue-notification'
import 'vue-awesome/icons' // https://github.com/Justineo/vue-awesome
import Icon from 'vue-awesome/components/Icon'
import mixpanel from 'mixpanel-browser';
import ClickOutside from 'vue-click-outside';

Vue.component('v-icon', Icon)

Vue.use(VueScreen);
Vue.use(VModal)
Vue.use(Notifications)
Vue.use(VueScreen);

Vue.mixin(titleMixin)
Vue.mixin(helpers)

Vue.directive('click-outside', ClickOutside);

Vue.config.productionTip = false

require('./Components/globalComponents');


// Pluralize filter
Vue.filter('pluralize', function (string, count, withCount = false) {
  return pluralize(string, count, withCount)
});

// Initialize Mixpanel
mixpanel.init(process.env.MIX_MIXPANEL_PROJECT_TOKEN, { 
  debug: true, 
  track_pageview: true, 
  persistence: 'localStorage' 
});


// Vue instance helpers
Vue.prototype.$mixpanel = mixpanel;

/**
 * Scroll to an element by id
 * @param {*} id HTML element ID
 */
Vue.prototype.scrollToId = (id) => window.document.querySelector(id).scrollIntoView({behavior: 'smooth'});


/**
 * Create Vue instance.
*/
new Vue({
  router,
  store,
  beforeCreate() {},
  render: h => h(App)
}).$mount('#app')
