<template>
    <button @click="click" class="flex gap-x-2 font-bold items-center" :class="[getJustification()]">
        <!-- Text -->
        <div class="hover:underline">
            <slot></slot>
        </div>
        <!-- Sort Icon -->
        <div>
            <v-icon :name="iconNames[sortByDirection]"></v-icon>
        </div>
    </button>
</template>

<script>


export default {
    props: {
        field: {
            type: String,
            // required: true
        },
        justify: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            sortByDirection: 'none',
            sortingDirections: {
                none: 'none',
                asc: 'asc',
                desc: 'desc'
            },
            iconNames: {
                none: 'sort',
                asc: 'sort-up',
                desc: 'sort-down'
            }
        }
    },
    methods: {
        click(event) {
            this.setSortDirection();
            this.$emit('click', {field: this.field, direction: this.sortByDirection});
        },
        getJustification()
        {
            if(this.justify == 'left') {
               return 'justify-start' 
            };
            if(this.justify == 'center') {
               return 'justify-center' 
            };
            if(this.justify == 'right') {
               return 'justify-end' 
            };
        },
        setSortDirection()
        {
            // if (this.sortByDirection == this.sortingDirections.none) {
            //     this.sortByDirection = this.sortingDirections.asc;
            // } else if (this.sortByDirection == this.sortingDirections.asc) {
            //     this.sortByDirection = this.sortingDirections.desc;
            // } else if (this.sortByDirection == this.sortingDirections.desc) {
            //     this.sortByDirection = this.sortingDirections.none;
            // }
            
            // switch implemenation of the above
            switch (this.sortByDirection) {
                case this.sortingDirections.none:
                    this.sortByDirection = this.sortingDirections.asc;
                    break;
                case this.sortingDirections.asc:
                    this.sortByDirection = this.sortingDirections.desc;
                    break;
                case this.sortingDirections.desc:
                    this.sortByDirection = this.sortingDirections.asc;
                    break;
            }
        }
    }
}
</script>

<style>

</style>