let formRules =  {
    first_name: {
        value: '',
        rules: 'required|string'
    },
    last_name: {
        value: '',
        rules: 'required|string'
    },
    email: {
        value: '',
        rules: 'required|string|email'
    },
    phone: {
        value: '',
        rules: 'required|string'
    },
    password: {
        value: '',
        rules: 'string|confirmed'
    },
    password_confirmation: {
        value: '',
        rules: 'string',
        messages: {
            equal: "The password fields must match."
        }
    }
}

let formStubs = {
    first_name: 'John',
    last_name: 'Smith',
    email: 'john@email.com',
    password: 'password',
    password_confirmation: 'password',
}

let formConfig = {}

export { formRules, formConfig, formStubs}
