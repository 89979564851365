<template>
  <fieldset class="mt-4">
    <legend class="">{{ label }}</legend>
    <slot></slot>
  </fieldset>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
    },
}
</script>

<style>

</style>