<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
           <!-- Title -->
           <SectionTitle>Add Document</SectionTitle>

            <!-- Form -->
            <div id="add-document-form" class="mb-8">

                <!--  Name -->
                <FormRow>
                    <Label for="name">Document Type</Label>
                    <SelectInput name="name" v-model="form.name" class="2/3">
                        <option v-for="(type, index) in document_types" :key="index" :value="type.name">{{type.name}}</option>
                        <option value="Other">Other</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('name')">{{form.errors.first('name')}}</Errors>
                </FormRow>

                <!-- Other Name -->
                <FormRow v-show="form.name == 'Other'">
                    <Label for="other_name">Custom Name</Label>
                    <TextInput name="other_name" v-model="form.other_name" class="2/3" placeholder="ie. Certification of Achievement"/>
                    <Errors v-if="form.errors.has('other_name')">{{form.errors.first('other_name')}}</Errors>
                </FormRow>

                <!-- File -->
                <FormRow>
                    <Label for="file">File</Label>
                    <FileInput name="file" class="2/3" @change="form.addFile($event)"/>
                    <Errors v-if="form.errors.has('file')">{{form.errors.first('file')}}</Errors>
                </FormRow>
                <div class="mb-4 md:mb-0">
                    <span class="text-sm">1 MB file size limit</span>
                </div>
                
            </div>

            <!-- Actions -->
            <FormRow class="">
                <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import { formDefaults, formConfig } from '@property/Forms/CreateDocumentForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton,
    },
    data()
    {
        return {
            name: 'add-document-modal',
            form: new Form(formDefaults),
        }
    },
    computed: {
        ...mapGetters({
            appliance: 'appliances/getAppliance',
            room: 'rooms/getRoom',
            property: 'properties/getCurrentProperty',
            service_record: 'service_records/getServiceRecord',
            job: 'project_jobs/getJob',
            user: 'auth/getUser',
            document_types: 'documents/getDocumentTypes',
            service_company: 'service_companies/getServiceCompany',
        }),
    },
    methods:
    {
        /**
         * Get route depending on the documentable name.
         */
        getRoute()
        {
            // Appliance
            if(this.documentable_name == 'appliance') {
                return `/api/v1/appliances/${this.appliance.id}/documents`
            }
            // Service Company
            else if(this.documentable_name == 'service_company') {
                return `/api/v1/service_companies/${this.service_company.id}/documents`
            }
            // Service Record
            else if(this.documentable_name == 'service_record') {
                return `/api/v1/service_records/${this.service_record.id}/documents`
            }
            // Room
            else if(this.documentable_name == 'room') {
                return `/api/v1/rooms/${this.room.id}/documents`
            }
            // Property
            else if(this.documentable_name == 'property') {
                return `/api/v1/properties/${this.property.id}/documents`
            }
            // Job
            else if(this.documentable_name == 'job') {
                return `/api/v1/project-jobs/${this.job.id}/documents`
            }

        },
        getActionForDocumentable()
        {
            // Appliance
            if(this.documentable_name == 'appliance') {
                return 'appliances/addDocument'
            }
            if(this.documentable_name == 'service_company') {
                return 'service_companies/addDocument'
            }
            // Service Record
            if(this.documentable_name == 'service_record') {
                return 'service_records/addDocument'
            }
            // Room
            if(this.documentable_name == 'room') {
                return 'rooms/addDocument'
            }
            // Property
            if(this.documentable_name == 'property') {
                return 'properties/addDocument'
            }
            // Job
            if(this.documentable_name == 'job') {
                return 'project_jobs/addDocument'
            }
        },
        submit()
        {
            let route = this.getRoute();
            let action = this.getActionForDocumentable()

            this.form.post(route)
                .then(response => {
                    this.$emit('created');
                    this.$modal.hide(this.name);
                    this.$notify({
                        title: 'Success',
                        text: 'The document has been added.',
                        type: 'success'
                    });
                })
                .catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        },
        beforeOpen(event) {
            if(event.params) {
                this.documentable_name = event.params.documentable;
            }
        }

    },
    async created() {
        this.form.appliance_id = this.appliance.id;
        this.form.user_id = this.user.id;

        await this.$store.dispatch('documents/getDocumentTypes');
    }
}
</script>
