export default class City {

    /**
     * Find a cities by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/cities/${id}`);
        let city = request.data.city || {};

        return city;
    }

    /**
    * Get all cities.
     */
    static async all() {
        let request = await axios.get(`/api/v1/cities`);
        let cities = request.data.cities || [];

        return cities;
    }

    /**
    * Create a cities.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/cities`, fields);
    //     let cities = request.data.cities || {};

    //     return cities;
    // }

    /**
    * Update a cities.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/cities/${id}`, fields);
        let cities = request.data.cities || {};

        return cities;
    }
}