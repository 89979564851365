let formDefaults =  {
    name: {
        value: '',
        rules: 'required|string'
    },
    other_name: {
        value: '',
        rules: 'string'
    }
}

let formConfig = {}

export { formDefaults, formConfig }