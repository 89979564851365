<template>
    <div @mouseenter="showTooltip" @mouseleave="hideTooltip" @focus="showTooltip" @blur="hideTooltip">
        <div class="group" ref="button">
            <v-icon name="info-circle" class="fill-current text-neutral-500 group-hover:text-neutral-400"></v-icon>
        </div>
        <div ref="tooltip" class="tooltip bg-neutral-700 text-white px-3 py-2" role="tooltip">
            {{ text }}
            <div class="arrow" ref="arrow"></div>
        </div>
    </div>
</template>

<script>
/*
 * @see https://floating-ui.com/docs/tutorial
 */
import { computePosition, autoUpdate, arrow, shift, flip, offset } from '@floating-ui/dom';

export default {
    props: {
        text: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            button: null,
            tooltip: null
        };
    },  
    methods: {
        update() {
            computePosition(this.button, this.tooltip, {
                // placement: 'bottom',
                middleware: [
                    shift({padding: 5}),
                    flip(),
                    arrow({element: this.arrow}),
                    offset(6)
                ],
            }).then(({x, y, placement, middlewareData}) => {
                Object.assign(this.tooltip.style, {
                    left: `${x}px`,
                    top: `${y}px`,
                });

                // Accessing the data
                const {x: arrowX, y: arrowY} = middlewareData.arrow;

                const staticSide = {
                    top: 'bottom',
                    right: 'left',
                    bottom: 'top',
                    left: 'right',
                }[placement.split('-')[0]];

                Object.assign(this.arrow.style, {
                    left: arrowX != null ? `${arrowX}px` : '',
                    top: arrowY != null ? `${arrowY}px` : '',
                    right: '',
                    bottom: '',
                    [staticSide]: '-4px',
                });
            });
        },
        showTooltip() {
            this.tooltip.style.display = 'block';
            this.update();
        },
        hideTooltip() {
            this.tooltip.style.display = '';
        }
    },
    mounted() {

        // this.button = document.getElementById('button');
        // this.tooltip = document.getElementById('tooltip');
        // this.arrow = document.getElementById('arrow');

        this.button = this.$refs.button;
        this.tooltip = this.$refs.tooltip;
        this.arrow = this.$refs.arrow;

        console.log(this.button, this.tooltip, this.arrow);


        // this.showTooltip();
    }
}
</script>

<style lang="scss" scoped>
.tooltip {

    /* Float on top of the UI */
    position: absolute;
    
    /* Avoid layout interference */
    width: max-content;
    top: 0;
    left: 0;

    /* Hide by default */
    display: none;

    font-weight: bold;
    border-radius: 4px;
    font-size: 90%;
}

#floating {
  position: absolute;
  width: max-content;
  top: 0;
  left: 0;
}

.arrow {
@apply bg-neutral-700;
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}
</style>