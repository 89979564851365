import RetrofitProgramTierAPI from '@api/retrofit_program_tiers';

const state = {
    retrofit_program_tiers: [],
    retrofit_program_tier: {
        loans: [],
        grants: [],
        rebates: [],
        property: {}
    }
};
 
const getters = {
    getRetrofitProgramTier(state)
    {
        return state.retrofit_program_tier;
    },
    getRetrofitProgramTiers(state)
    {
        return state.retrofit_program_tiers;
    },

};

const actions = { 

    /**
     * Get all retrofit programs.
     */
    async getRetrofitProgramTiers({commit})
    {
        let retrofit_program_tiers = await RetrofitProgramTierAPI.findAll();
        commit('setRetrofitProgramTiers', retrofit_program_tiers);
        return;
    },

    /**
    * Get a retrofit program.
    */
    async getRetrofitProgramTier({commit}, id)
    {
        let retrofit_program_tier = await RetrofitProgramTierAPI.find(id);
        commit('setRetrofitProgramTier', retrofit_program_tier);
        return;
    },

    /**
     * Create a new retrofit program.
     */
    async createRetrofitProgramTier({commit}, fields)
    {
        let retrofit_program_tier = await RetrofitProgramTierAPI.create(fields);
        commit('setRetrofitProgramTier', retrofit_program_tier);
        return;
    },

    /**
    * Update a retrofit program.
    */
    async updateRetrofitProgramTier({commit}, {id, fields}) {
        let retrofit_program_tier = await RetrofitProgramTierAPI.update(id, fields);
        commit('setRetrofitProgramTier', retrofit_program_tier);
        return;
    },

    /**
     * Delete a retrofit program.
     */
    async deleteRetrofitProgramTier({commit}, id)
    {
        let successful = await RetrofitProgramTierAPI.delete(id);

        if(successful) 
        {
            commit('setRetrofitProgramTier', {});    
        }

        return;
    },

    /**
    * Create a Grant for a Retrofit Program tier.
    */
    async createGrant({commit}, {id, fields})
    {
        let grant = await RetrofitProgramTierAPI.createGrant(id, fields);

        if(grant.id) {
            commit('addGrant', grant);
        }

        return;
    },

    /**
    * Create a condition for a Retrofit Program tier.
    */
    async createConditionForTier({commit}, {id, fields})
    {
        let condition = await RetrofitProgramTierAPI.createCondition(id, fields);
        return;
        // return condition;
    }
};

const mutations = {
    setRetrofitProgramTiers(state, retrofit_program_tiers = [])
    {
        state.retrofit_program_tiers = retrofit_program_tiers;
    },
    setRetrofitProgramTier(state, retrofit_program_tier = {})
    {
        state.retrofit_program_tier = retrofit_program_tier;
    },
    addGrant(state, grant)
    {
        state.retrofit_program_tier.grants.push(grant);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
