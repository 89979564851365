const state = {
    heating_types: [],
};
 
const getters = {
    getHeatingTypes(state) {
        return state.heating_types;
    },
};

const actions = { 
    async getHeatingTypes({commit}) {
        await axios.get(`/api/v1/heating_types`,{
            user_id: this.getters['auth/getUser'].id
        })
            .then(response => commit('setHeatingTypes', response.data.heating_types))
    }
    
};

const mutations = {
    setHeatingTypes(state, heating_types)
    {
        state.heating_types = heating_types;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
