const state = {
    fuel_types: [],
};
 
const getters = {
    getFuelTypes(state) {
        return state.fuel_types;
    },
};

const actions = { 
    async getFuelTypes({commit}) {
        await axios.get(`/api/v1/fuel_types`,{
            user_id: this.getters['auth/getUser'].id
        })
            .then(response => commit('setFuelTypes', response.data.fuel_types))
    }
    
};

const mutations = {
    setFuelTypes(state, fuel_types)
    {
        state.fuel_types = fuel_types;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
