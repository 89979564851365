/**
 * Record List.
 *
 * Module for handing a data list.  It can be used for 
 * pagination, sorting and text filtering.
 */
export default class RecordList {

    /**
     * Generate new Record List.
     *
     * @param {array} records 
     * @param {object} options
     */
    constructor(records = [], options = {}) {
        // Records
        this.records = records;
        this.visibleRecords = [];
        this.filteredRecords = this.records;

        // Sorting & Filtering
        this.filterText = '';
        this.sortByField = '';
        this.sortDirections = {};

        // options
        this.options = options;
        this.sortBy = options.sortBy || [];
        this.filterBy = options.filterBy || [];
        this.withDeleted = options.withDeleted = false;
        this.recordsPerPage = options.recordsPerPage || 10;

        // state
        this.loading = true;

        this._setVisibleRecords();
        this._setPages();
    }

    /**
     * Set records after instantiation.
     *
     * @param {Array} records 
     * @return void
     */
    setRecords(records) {
        this.records = this.filteredRecords = records;
        this.loading = false;
        this._setVisibleRecords();
        this._setPages();

    }

    /**
     * Set page data.
     *
     * @return void
     */
    _setPages() {
        this.totalPages = Math.ceil(this.visibleRecords.length / this.recordsPerPage);
        this.currentPage = 1;
        this.startRecordIndex = 0;
        this.endRecordIndex = 0;
        this.recordsForPage = [];
        this._setPageRecords();
    }

    /**
     * Set page number.
     *
     * @param {integer} page 
     * @return void
     */
    setPage(page) {
        this.currentPage = page;
        this._setPageRecords();
    }

    /**
     * Set records for current page.
     *
     * @return Array
     */
    _setPageRecords() {
        const start = (this.currentPage - 1) * this.recordsPerPage;
        this.startRecordIndex = start + 1;

        const end = this.endRecordIndex = start + this.recordsPerPage;
        this.endRecordIndex = end;

        return this.recordsForPage = this.visibleRecords.slice(start, end);
    }

    /**
     * Go to next page.
     *
     * @return void
     */
    nextPage() {
        if (this.records.length > this.endRecordIndex) {
            this.currentPage++;
            this._setPageRecords();
        }
    }

    /**
     * Go to previous page.
     *
     * @return void
     */
    backPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this._setPageRecords();
        }
    }

    /**
     * Is the field sortable?
     *
     * @param {string} field 
     * @returns {bool}
     */
    _isFieldSortable(field) {
        return this.sortBy.includes(field);
    }

    /**
    * Sort records by field.
    *
    * @param {string} field 
    * @return void
    */
    sortRecords(field) { 
        this.sortByField = field;

        if(this._isFieldSortable(field)) {

            if(!this.sortDirections[field]) {
                this.sortDirections[field] = 'asc';
            }

            this.visibleRecords = this.records.sort((a, b) => {
                const aValue = a[this.sortByField];
                const bValue = b[this.sortByField];
                let sortValue = 0;
    
                if (aValue < bValue) {
                    sortValue = this.sortDirections[field] === 'asc' ? -1 : 1;
                } else if (aValue > bValue) {
                    sortValue = this.sortDirections[field] === 'asc' ? 1 : -1;
                }
                return sortValue;
            });
    
            this._updateSortDirection(field);
            this._setVisibleRecords()
            this._setPageRecords();

        } else {
            throw new Error('The field "' + field + '" is not sortable.');
        }     
    }

    /**
     * Update the current sort direction for a field.
     *
     * @param {string} field 
     * @return void
     */
    _updateSortDirection(field)
    {
        if(this.sortDirections[field] == 'asc') {
            this.sortDirections[field] = 'desc';
        } else {
            this.sortDirections[field] = 'asc';
        }
    }

    /**
     * Filter records by text.
     *
     * @return void
     */
    filterRecords() {
        if (this.filterText.length > 0) {

            this.filteredRecords = this.records.filter(record => {

                // Single Layer
                // return this.filterBy.some(filter => {
                //     if (typeof record[filter] === 'string') {
                //         return record[filter].toLowerCase().includes(this.filterText.toLowerCase());
                //     }
                //     return String(record[filter]).toLowerCase().includes(this.filterText.toLowerCase());
                // });

                // Multi-layer [created_at, user.first_name]
                return this.filterBy.some(filter => {
                    const keys = filter.split('.');
                    const nestedValue = keys.reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined), record);

                    if (typeof nestedValue === 'string') {
                        return nestedValue.toLowerCase().includes(this.filterText.toLowerCase());
                    }

                    return String(nestedValue).toLowerCase().includes(this.filterText.toLowerCase());
                });
            })
        } else {
            this.filteredRecords = this.records;
        }

        this._setVisibleRecords();
        this._setPageRecords();
    }


    /**
     * Set whether deleted records are visible.
     *
     * @return void
     */
    _setVisibleRecords() {
        if (this.withDeleted) {
            this.visibleRecords = this.filteredRecords;
        }
        this.visibleRecords = this.filteredRecords.filter(record => record.deleted_at == null);
    }

    /**
     * Toggle deleted record visibility.
     *
     * @return void
     */
    toggleDeleted(visible = !this.withDeleted)
    {
        this.withDeleted = visible;
        this._setVisibleRecords();
    }

    /**
     * Enable deleted record visibility.
     *
     * @return void
     */
    showDeleted()
    {
        this.toggleDeleted(true)
    }

    /**
     * Disable deleted record visibility.
     *
     * @return void
     */
    hideDeleted()
    {
        this.toggleDeleted(false)
    }

    /**
     * Get the count of visible records.
     *
     * @return integer
     */
    visibleCount()
    {
        return this.visibleRecords.length;
    }
}