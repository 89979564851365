export default class Note {

    /**
     * Find a notes by it's ID.
     * @param {int} id 
     */
    // static async find(id) {

    //     let request = await axios.get(`/api/v1/notes/${id}`);
    //     let note = request.data.note || {};

    //     return note;
    // }

    /**
    * Get all notes.
     */
    // static async all() {
    //     let request = await axios.get(`/api/v1/notes`);
    //     let notes = request.data.notes || [];

    //     return notes;
    // }

    /**
    * Create a notes.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/notes`, fields);
    //     let notes = request.data.notes || {};

    //     return notes;
    // }

    /**
    * Update a notes.
    * @param {int} id 
    * @param {object} fields 
    */
    // static async update(id, fields = {}) {
    //     let request = await axios.patch(`/api/v1/notes/${id}`, fields);
    //     let notes = request.data.notes || {};

    //     return notes;
    // }

    /**
     * Delete a note.
     * @param {int} id 
     */
    static async delete(id) {
        let response = await axios.delete(`/api/v1/notes/${id}`);
        let successful = response.status == 200 || false;

        return successful;
    }
}