import Model from '@models/Model'

class User extends Model {

    constructor(properties)
    {
        super(properties)
    }

    async getProperties()
    {
        await axios.get(`/api/v1/users/${this.id}/properties`)
            .then(response => {
                this.properties = response.data.properties;
            })
    }
}

export default User;
