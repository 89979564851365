export default class UserType {

    /**
     * Find a user_types by it's ID.
     * @param {int} id 
     */
    // static async find(id) {

    //     let request = await axios.get(`/api/v1/user_types/${id}`);
    //     let user_types = request.data.user_types || {};

    //     return user_types;
    // }

    /**
    * Get all user_types.
     */
    static async all() {
        let request = await axios.get(`/api/v1/user-types`);
        return request.data.user_types || [];
    }

    /**
    * Create a user_types.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/user_types`, fields);
    //     let user_types = request.data.user_types || {};

    //     return user_types;
    // }

    /**
    * Update a user_types.
    * @param {int} id 
    * @param {object} fields 
    */
    // static async update(id, fields = {}) {
    //     let request = await axios.put(`/api/v1/user_types/${id}`, fields);
    //     let user_types = request.data.user_types || {};

    //     return user_types;
    // }
}