<template>
    <modal :name="name" :width="width" :pivotY="pivotY" :pivotX="pivotX" :adaptive="true" :height="height" :scrollable="scrollable" @before-open="beforeOpen" @before-close="beforeClose" class="pb-16">
      <a href="#" class="absolute top-1 right-4 text-2xl hover:text-gray-600" @click.prevent="$modal.hide(name)">&times;</a>
      <slot></slot>
    </modal>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    pivotY: {
      default: 0.2
    },
    pivotX: {},
    height: {},
    scrollable: {
      default: true
    },
    width: {}
  },
  methods: {
    beforeOpen(event)
    {
      this.$emit('before-open', event);
    },
    beforeClose(event)
    {
      this.$emit('before-close', event);
    }
  }
}
</script>
