<template>
    <div class="md:flex">
        <div v-for="option in options" 
            :key="option.value" 
            @click="updateSelected(option.value)" 
            class="flex p-4 md:p-6 mr-2 mb-2 md:mb-0 rounded border cursor-pointer items-center justify-center text-center"
            :class="[selected.includes(option.value) ? 'bg-orange-200' : 'bg-transparent']"
        >
            <!-- <img class="mr-2 inline-block" :src="image('Appliances/washing-machine.svg')"/> -->
            {{option.text}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },

    },
    data() {
        return {
            selected: [],
            styles: {
                default: [],
                selected: []
            }
        }
    },
    methods: {
        updateSelected(value = null) {
            if(value) {
                // let value = event.target.value;

                if(!this.selected.includes(value)) {
                    this.selected.push(value)
                } else {
                    this.selected = this.selected.filter((item) => item != value)
                }
            }

            this.$emit('input', this.selected)
            this.$emit('change', this.selected)
        }
    },
}
</script>

<style lang="scss" scoped>

</style>