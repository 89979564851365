<template>
    <RecordListCard title="Properties" :records="records" :sort-by="['street', 'created_at']" :filter-by="['street', 'property_type.name']">

        <!-- Title -->
        <template v-slot:title-actions="slotProps">
            <ActionButtonStack>
                <template v-slot:actions>
                    <!-- <ActionButton text="Add" @click="addRecord"><v-icon name="plus" class="fill-current"></v-icon></ActionButton> -->
                    <!-- <ActionButton :text="slotProps.list.withDeleted ? `Hide Deleted` : `Show Deleted`" @click="slotProps.list.toggleDeleted()"><v-icon name="eye-slash" class="fill-current"></v-icon></ActionButton> -->
                </template>
                <template v-slot:important-actions>
                    <!--  -->
                </template>
            </ActionButtonStack>
        </template>

        <!-- List Header -->
        <template v-slot:header="slotProps">
            <RecordListItem header>
                <div class="flex items-center font-bold">
                    <RecordListSortableHeaderItem class="flex-1" field="street" @click="slotProps.list.sortRecords('street')">Address</RecordListSortableHeaderItem>
                    <!-- <div class="hidden md:block w-1/5">Owner</div> -->
                    <div class="hidden md:block w-1/4">Project State</div>
                    <RecordListSortableHeaderItem class="w-1/5 text-center items-center" field="created_at" @click="slotProps.list.sortRecords('created_at')">Created</RecordListSortableHeaderItem>
                </div>
            </RecordListItem>
        </template>

        <!-- Records -->
        <template v-slot:record="slotProps">
            <RecordListItem :visible="slotProps.record.deleted_at == null">

                <!-- Details  -->
                <div class="flex items-center">
                    <div class="flex flex-1 items-center hover:underline">
                        <v-icon v-if="slotProps.record.deleted_at" name="ban" class="fill-current text-red-700 mr-1" :title="`Deleted: ${slotProps.record.deleted_at}`"></v-icon>
                        <router-link :to="`/admin/properties/${slotProps.record.id}`">{{ slotProps.record.street }}</router-link>
                    </div>

                    <div class="w-1/4">
                        <router-link v-if="slotProps.record.projects" :to="`/projects/${slotProps.record.projects[0]?.id}`" class="hover:underline">{{slotProps.record.projects[0]?.state.title}}</router-link>
                    </div>
                    <!-- <div class="hidden md:block w-1/4">{{slotProps.record.property_type.name}}</div> -->
                    <div class="w-1/5 text-center items-center">{{new Date(slotProps.record.created_at).toLocaleDateString() }}</div>
                </div>

                <!-- Important Actions -->
                <template v-slot:important-actions>
                    <!-- <RecordListActionButton important text="Edit" @click="editRecord(slotProps.record)"><v-icon name="pen" class="fill-current"></v-icon></RecordListActionButton> -->
                </template>

                <!-- Other Actions -->
                <template v-slot:actions>
                    <!-- <RecordListActionButton v-if="slotProps.record.projects_count" class="md:hidden" text="Retrofit Report" @click="viewRetrofitReport(slotProps.record)"><v-icon name="clipboard-list" class="fill-current"></v-icon></RecordListActionButton> -->
                    <RecordListActionButton v-if="slotProps.record.projects?.length" text="View Retrofit Report (Public)" class="hidden md:block" @click="viewRetrofitReport(slotProps.record)"><v-icon name="clipboard-list" class="fill-current"></v-icon></RecordListActionButton>
                    <RecordListActionButton text="View Owner" @click="$router.push(`/admin/users/${slotProps.record.owners[0].id}`)"><v-icon name="user" class="fill-current"></v-icon></RecordListActionButton>
                    <!-- <RecordListActionButton text="Delete" @click="deleteRecord(slotProps.record)"><v-icon name="trash" class="fill-current"></v-icon></RecordListActionButton> -->
                    <!-- <RecordListActionButton text="Duplicate" @click="duplicateRecord(record)"><v-icon name="copy" class="fill-current"></v-icon></RecordListActionButton> -->
                </template>

            </RecordListItem>
        </template>
    </RecordListCard>
</template>

<script>
// import RecordList from '../../Components/RecordList/RecordList.js';
import RecordListCard from '../../Components/RecordList/RecordList.vue';
import RecordListItem from '../../Components/RecordList/RecordListItem.vue';
import RecordListFooter from '../../Components/RecordList/RecordListFooter.vue';
import RecordListActionButton from '../../Components/RecordList/RecordListActionButton.vue';
import RecordListSortableHeaderItem from '../../Components/RecordList/RecordListSortableHeaderItem.vue';

export default {
    components: {
        // RecordList,
        RecordListCard,
        RecordListItem,
        RecordListFooter,
        RecordListActionButton,
        RecordListSortableHeaderItem,
    },
    props: {
        records: {
            type: Array,
            required: true,
            default: () => []
        },
    },
    methods: {
        viewRetrofitReport(record)
        {
            this.$router.push(`/retrofit_report/${record.id}`);
        },
        editRecord(record)
        {
            alert('Edit: ' + record.id);
            console.log('Edit', record);
        },
        addRecord()
        {
            alert('Add a Property');
            console.log('Add');
        },
        deleteRecord(record)
        {
            this.$store.dispatch('properties/deleteProperty', record.id);

            this.records = this.records.filter((item, index) => {
                return item.id != record.id
            });

            this.$notify({
                title: 'Success',
                text: 'Property deleted successfully.',
                type: 'success'
            });
        },
        duplicateRecord(record)
        {
            alert('Duplicate: ' + record.id);
            console.log('Duplicate', record);
        },
    }  
}
</script>