<template>
    <!-- Dropdown Wrapper -->
    <div class="relative" v-click-outside="closeDropdown">
        <!-- Dropdown Button -->
        <button type="button" :disabled="disabled" @click="toggleDropdown"
            class="border border-gray-300 px-4 py-2 rounded shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 flex w-full justify-between items-center text-left"
            :aria-expanded="isOpen" :aria-haspopup="true">

            <span>
                <!-- Display selected options or placeholder -->
                <template v-if="selectedValue && selectedValue?.length">
                    {{ multiple ? selectedOptions?.join(', ') : selectedOptions[0] }}
                </template>
                <template v-else>
                    Make a selection...
                </template>
            </span>
            <v-icon name="chevron-down" class="text-gray-500"></v-icon>
        </button>

        <!-- Dropdown Menu -->
        <div v-if="isOpen" class="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10"
            @click.stop>
            <ul class="max-h-60 overflow-auto">
                <li v-for="option in options" :key="option.value" 
                    class="border-l-4 border-transparent cursor-pointer hover:bg-gray-100 px-4 py-2"
                    :class="{ 'border-l-4 border-orange-500 bg-orange-100': isSelected(option.value) }" @click="selectOption(option.value)"
                    :aria-selected="isSelected(option.value)">
                    <span>{{ option.text }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        value: {
            type: [Array, String, Number],
            default: () => (this?.multiple ? [] : null)
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String
        },
        multiple: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedValue: this.value,
            isOpen: false
        };
    },
    computed: {
        selectedOptions() {

            if (this.multiple) {
                let options = this.options?.filter(option =>
                    this.selectedValue?.includes(option.value)
                )
                    .map(option => option.text);

                return options;

            } else {
                const selectedOption = this.options?.find(
                    option => option.value === this.selectedValue
                );
                return selectedOption ? [selectedOption.text] : [];
            }
        }
    },
    watch: {
        value(newValue) {
            this.selectedValue = newValue;
        }
    },
    methods: {
        toggleDropdown() {
            if (!this.disabled) {
                this.isOpen = !this.isOpen;
            }
        },
        closeDropdown() {
            this.isOpen = false;
        },
        selectOption(value) {
            if (this.multiple) {
                if (this.selectedValue.includes(value)) {
                    this.selectedValue = this.selectedValue?.filter(val => val !== value);
                } else {
                    this.selectedValue.push(value);
                }
            } else {
                this.selectedValue = value;
                this.closeDropdown();
            }
            this.$emit("input", this.selectedValue);
            this.$emit("change", this.selectedValue);
        },
        isSelected(value) {
            return this.multiple
                ? this.selectedValue.includes(value)
                : this.selectedValue === value;
        }
    },
    mounted() {
        if (this.multiple && !Array.isArray(this.selectedValue)) {
            this.selectedValue = [];
        }
        // document.addEventListener('click', this.closeDropdown);
        // console.log(this.options);
    },
    beforeDestroy() {
        // document.removeEventListener('click', this.closeDropdown);
    }
};
</script>



<style lang="scss" scoped>
.dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border: 1px solid #d1d5db;
    padding: 0.5rem 1rem;
    width: 100%;
    border-radius: 0.375rem;
    text-align: left;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        border-color: #a0aec0;
    }

    &[aria-expanded="true"] {
        @apply bg-orange-100;
    }
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    margin-top: 0.25rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 15rem;
    overflow-y: auto;
}

.dropdown-menu li {
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
        background-color: #f7fafc;
    }

    &[aria-selected="true"] {
        background-color: #ebf8ff;
    }
}

.dropdown-icon {
    pointer-events: none;
}
</style>
