<template>
    <div name="create-property-form">
        <!-- Head text -->
        <SectionTitle>Add a Property</SectionTitle>

        <!-- fields -->
        <div class="my-8">

            <!-- Search -->
            <div v-show="false && form.currentSection == 'search'">

                <p class="mt-4 mb-12">Let's start with confirming your property address.</p>

                <FormRow class="flex">
                    <div class="w-full md:w-2/3 mr-0 md:mr-4">
                        <Label for="address">Enter your Address</Label>
                        <TextInput id="address" name="address" v-model="form.address" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('address')">{{form.errors.first('address')}}</Errors>
                    </div>
                </FormRow>

                <!-- ACTIONS -->
                <FormRow class="mb-12">
                    <SquareButton :disabled="!form.address.length" :context="'primary'" class="mr-2" @click="form.nextSection(); scrollToTop()">Next</SquareButton>
                </FormRow>
            </div>
   
            <!-- Address Section -->
            <div v-show="form.currentSection == 'address'">

                <p class="mt-4 mb-12">Please confirm the following address. We will be using this to improve your home management experience.</p>

                <!-- Address -->
                <!-- Address + Unit # -->
                <FormRow class="md:flex">
                    <div class="w-full md:w-1/2 mr-4 mb-4 md:mb-0">
                        <Label for="street">Street</Label>
                        <TextInput name="street" v-model="form.street" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('street')">{{form.errors.first('street')}}</Errors>
                    </div>
                    <div class="w-full md:w-1/2">
                        <Label for="unit_number">Unit/Apt/Suite #</Label>
                        <TextInput name="unit_number" v-model="form.unit_number" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('unit_number')">{{form.errors.first('unit_number')}}</Errors>
                    </div>
                </FormRow>

                <!--Country + State -->

                <FormRow class="md:flex">
                    <!-- <div class="w-full md:w-1/2 mr-4 mb-4 md:mb-0">
                        <Label for="country">Country</Label>
                        <TextInput name="country" v-model="form.country" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('country')">{{form.errors.first('country')}}</Errors>
                    </div> -->

                    <!-- <div class="w-full md:w-1/2">
                        <Label for="state">Province</Label>
                        <TextInput name="state" v-model="form.state" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('state')">{{form.errors.first('state')}}</Errors>
                    </div> -->

                    <div class="w-full md:w-1/2 mr-4 mb-4 md:mb-0">
                        <Label for="country_id">Country</Label>
                        <SelectInput name="country_id" v-model="form.country_id" placeholder="">
                            <option v-for="(country, index) in countries" :value="country.id" :key="index">{{country.name}}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('country_id')">{{form.errors.first('country_id')}}</Errors>
                        
                    </div>

                    <div class="w-full md:w-1/2">
                        <Label for="state_id">Province</Label>
                        <SelectInput name="state_id" v-model="form.state_id" placeholder="">
                            <option v-for="(state, index) in states" :value="state.id" :key="index">{{state.name}}</option>
                        </SelectInput>
                        <Errors v-if="form.errors.has('state_id')">{{form.errors.first('state_id')}}</Errors>
                    </div>
                </FormRow>

                <!--City -->
                <FormRow class="md:flex">
                    <div class="w-full md:w-1/2 mr-4 mb-4 md:mb-0">
                        <Label for="city">City</Label>
                        <TextInput name="city" v-model="form.city" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('city')">{{form.errors.first('city')}}</Errors>
                    </div>
                    <div class="w-full md:w-1/2">
                        <Label for="postal_code">Postal Code</Label>
                        <TextInput name="postal_code" v-model="form.postal_code" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('postal_code')">{{form.errors.first('postal_code')}}</Errors>
                    </div>
                </FormRow>

                <!-- ACTIONS -->
                <FormRow class="mb-12">
                    <SquareButton context="primary" class="mr-2" @click="form.nextSection(); scrollToTop()">Next</SquareButton>
                    <!-- <SquareButton context="gray" @click="form.previousSection(); scrollToTop()">Back</SquareButton> -->
                </FormRow>

            </div>

            <div v-show="form.currentSection == 'basic_details'">

                <!-- Property Type -->
                <FormRow class="w-full mb-8 md:mb-0 md:w-2/3">
                    <Label for="property_type_id">Property Type</Label>
                    <!-- <SelectInput name="property_type_id" v-model="form.property_type_id" placeholder="">
                        <option v-for="typee in property_types" :value="typee.id" :key="typee.id">{{typee.name}}</option>
                    </SelectInput> -->
                    <div class="md:flex flex-wrap">
                        <div v-for="typee in property_types" 
                            :key="typee.id" 
                            class="appliance-card flex p-4 md:p-6 mr-2 mb-2 rounded border cursor-pointer items-center md:justify-center md:text-center"
                            :class="[form.property_type_id == typee.id ? 'bg-orange-200' : 'bg-transparent']"
                            @click="form.property_type_id = typee.id"
                        >
                            <img class="h-8 md:h-12 mr-2 inline-block" :src="image(typee.image_url)"/>
                            {{typee.name}}
                        </div>
                    </div>
                    <Errors v-if="form.errors.has('property_type_id')">{{form.errors.first('property_type_id')}}</Errors>
                </FormRow>

                <!--Bedrooms & Bathrooms -->
                <FormRow class="flex">
                    <div class="w-full md:w-2/3">
                        <Label for="bedrooms">Number of Bedrooms</Label>
                        <TextInput type="number" name="bedrooms" v-model="form.bedrooms" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('bedrooms')">{{form.errors.first('bedrooms')}}</Errors>
                    </div>
                </FormRow>

                <FormRow class="flex">
                    <div class="w-full md:w-2/3">
                        <Label for="bathrooms">Number of Bathrooms</Label>
                        <TextInput type="number" name="bathrooms" v-model="form.bathrooms" class="" placeholder=""/>
                        <Errors v-if="form.errors.has('bathrooms')">{{form.errors.first('bathrooms')}}</Errors>
                    </div>
                </FormRow>

                <!-- Nickname -->
                 <FormRow class="w-full md:w-2/3">
                    <Label for="nickname">Nickname (optional)</Label>
                    <TextInput name="nickname" v-model="form.nickname" placeholder="ie. Home"/>
                    <Errors v-if="form.errors.has('nickname')">{{form.errors.first('nickname')}}</Errors>
                </FormRow>

                <!-- ACTIONS -->
                <FormRow class="mb-12">
                    <SquareButton context="primary" class="mr-2" @click="form.nextSection(); scrollToTop()">Next</SquareButton>
                    <SquareButton context="gray" @click="form.previousSection(); scrollToTop()">Back</SquareButton>
                </FormRow>
            </div>

            <div v-show="form.currentSection == 'connection_details'">

                <p class="my-4">Let's go over how your property connects to the outside world.</p>
                <p class="mb-12 font-bold">Select all that apply:</p>

                <!-- Water -->
                <FormRow>
                    <Label for="water_types">Water</Label>
                    <!-- <p>Select all that apply:</p> -->
                    <SelectMultipleInline id="water_types" name="water_types" v-model="form.water_types" @change="updateUtilityTypes" :options="water_types"></SelectMultipleInline>
                    <Errors v-if="form.errors.has('water_types')">{{form.errors.first('water_types')}}</Errors>
                </FormRow>

                <!-- Sewage -->
                <FormRow>
                    <Label for="sewage_types">Sewage</Label>
                    <!-- <p>Select all that apply:</p> -->
                    <SelectMultipleInline id="sewage_types" name="sewage_types" v-model="form.sewage_types" @change="updateUtilityTypes" :options="sewage_types"></SelectMultipleInline>
                    <Errors v-if="form.errors.has('sewage_types')">{{form.errors.first('sewage_types')}}</Errors>
                </FormRow>

                <!-- Electrical -->
                <FormRow>
                    <Label for="electricity_types">Electricity</Label>
                    <!-- <p>Select all that apply:</p> -->
                    <SelectMultipleInline id="electricity_types" name="electricity_types" v-model="form.electricity_types" @change="updateUtilityTypes" :options="electricity_types"></SelectMultipleInline>
                    <Errors v-if="form.errors.has('electricity_types')">{{form.errors.first('electricity_types')}}</Errors>
                </FormRow>

                <!-- Gas -->
                <FormRow>
                    <Label for="gas_types">Gas</Label>
                    <!-- <p>Select all that apply:</p> -->
                    <SelectMultipleInline id="gas_types" name="gas_types" v-model="form.gas_types" @change="updateUtilityTypes" :options="gas_types"></SelectMultipleInline>
                    <Errors v-if="form.errors.has('gas_types')">{{form.errors.first('gas_types')}}</Errors>
                </FormRow>
                
                <!-- ACTIONS -->
                <FormRow class="mb-12">
                    <SquareButton context="primary" class="mr-2" @click="form.nextSection(); scrollToTop()">Next</SquareButton>
                    <SquareButton context="gray" @click="form.previousSection(); scrollToTop()">Back</SquareButton>
                </FormRow>
            </div>

            <!-- Appliance Types -->
            <div v-show="form.currentSection == 'appliances'">

                <p class="my-4">Finally, let's go over your appliances.</p>
                <p class="mb-12 font-bold">Select all that apply:</p>

                <FormRow>
                    <Label for="appliance_types" class="hidden md:block">Appliances</Label>
                    <!-- <SelectMultipleInline id="appliance_types" name="appliance_types" v-model="form.appliance_types" @change="updateUtilityTypes" :options="appliance_types"></SelectMultipleInline> -->

                    <div class="md:flex flex-wrap">
                        <div v-for="appliance_type in appliance_types" 
                            :key="appliance_type.id" 
                            class="appliance-card flex p-4 md:p-6 mr-2 mb-2 rounded border cursor-pointer items-center md:justify-center md:text-center"
                            :class="[form.appliance_types.includes(appliance_type.id) ? 'bg-orange-200' : 'bg-transparent']"
                            @click="updateAppliances(appliance_type.id)"
                        >
                            <img class="h-8 md:h-12 mr-2 inline-block" :src="image(appliance_type.image_url)"/>
                            {{appliance_type.name}}
                        </div>
                    </div>
                    <Errors v-if="form.errors.has('appliance_types')">{{form.errors.first('appliance_types')}}</Errors>
                </FormRow>

                <!-- ACTIONS -->
                <FormRow class="mb-12">
                    <SquareButton context="primary" class="mr-2" @click="submit()">Submit</SquareButton>
                    <SquareButton context="gray" @click="form.previousSection(); scrollToTop()">Back</SquareButton>
                </FormRow>
            </div>

            <!-- <FloorPlan></FloorPlan> -->

            <!-- TEXT -->
            <!-- <FormRow>
                <Label for="city">city</Label>
                <cityInput name="city" v-model="form.city" class="w-2/3" placeholder=""/>
                <Errors v-if="form.errors.has('city')">{{form.errors.first('city')}}</Errors>
            </FormRow> -->

            <!-- CHECKBOX -->
            <!-- <FormRow>
                <div class="flex items-center justify-between">
                    <div>
                        <label for="CHECK" class="hidden">CHECK HIDDEN TEXT?</label>
                        <div>
                            <input type="checkbox" name="CHECK" id="CHECK" v-model="form.CHECK" class="mr-2"/>
                            <span>CHECK TEXT</span>
                            <Errors v-if="form.errors.has('CHECK')">{{form.errors.first('CHECK')}}</Errors>
                        </div>
                    </div>
                </div>
            </FormRow> -->


        </div>
    </div>
</template>

<script>
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@forms/CreatePropertyForm'
import SquareButton from '@components/Buttons/SquareButton.vue'
import SelectMultipleInline from '@components/Inputs/SelectMultipleInline.vue'
import { mapGetters } from 'vuex'
import FloorPlan from '../Components/FloorPlan.vue'

export default {
    components: {
        SectionTitle,
        Form,
        SquareButton,
        SelectMultipleInline,
        FloorPlan
    },
    data() {
        return {
            form: new Form(formDefaults),
            places: [],
            map: null,
            disabled: true,
            electricity_types: [],
            heating_types: [],
            water_types: [],
            sewage_types: [],
            gas_types: [],
        }
    },
    computed: {
        ...mapGetters({
            appliance_types: 'properties/getApplianceTypes',
            property_types: 'properties/getPropertyTypes',
            utilities: 'utilities/getUtilities',
            countries: 'addresses/getCountries',
            states: 'addresses/getStates'
        }),
        isDisabled: () => !this.form.address.length ? true : false
    },
    methods: {
        /**
         * Update utility types on form object.
         *
         * @param {array} ids 
         */
        updateUtilityTypes()
        {
            this.form.utilities = this.form.water_types.concat(
                this.form.gas_types,
                this.form.sewage_types,
                this.form.electricity_types
            )
        },
        updateAppliances(id)
        {
            if(!this.form.appliance_types.includes(id)) {
                this.form.appliance_types.push(id)
            } else {
                this.form.appliance_types = this.form.appliance_types.filter(item => item != id)
            }
        },
        updatePropertyType(id)
        {
            if(!this.form.appliance_types.includes(id)) {
                this.form.appliance_types.push(id)
            } else {
                this.form.appliance_types = this.form.appliance_types.filter(item => item != id)
            }
        },
        scrollToTop()
        {
            window.scrollTo(0, 0);
        },
        async submit() {
            this.form.validate();

            if(this.form.isValid) {
                await this.$store.dispatch('properties/createProperty', this.form.data())

                let property = this.$store.getters['properties/getCurrentProperty']

                this.$notify({
                    title: 'Success',
                    text: "Property has been created.",
                    type: 'success'
                })

                if(property) {
                    this.$router.push({
                        name: 'properties.view',
                        params: {
                            id: property.id,
                            property: property
                        }
                    })
                }

            } else {

                let first_error = Object.values(this.form.errors.errors)
                    .filter(item => item.length)
                    [0][0]

                this.$notify({
                    title: 'Form Error',
                    text: first_error,
                    type: 'error',
                    duration: -1
                })
            }
        },
        // createAutoCompleteListener() {
        //     // Connect address input to Google Autocomplete
        //     const input = document.getElementById("address");
        //     const searchBox = new google.maps.places.SearchBox(input);

        //     searchBox.addListener("places_changed", () => {
        //         this.places = searchBox.getPlaces();

        //         let addressComponents = this.places[0].address_components

        //         this.form.address = this.places[0].formatted_address
        //         this.form.street = addressComponents[0].long_name + " " + addressComponents[1].long_name
        //         this.form.city = addressComponents[3].long_name
        //         this.form.state = addressComponents[5].long_name
        //         this.form.country = addressComponents[6].long_name
        //         this.form.postal_code = addressComponents[7].long_name
        //         this.form.location = {
        //             lat: this.places[0].geometry.location.lat(),
        //             long: this.places[0].geometry.location.lng()
        //         }

        //         if (this.places.length == 0) return
        //     })
        // },
        async getUtilties()
        { 
            await this.$store.dispatch('utilities/setUtilities');

            // GAS
            this.gas_types = this.utilities.filter(util => util.type.name == 'Gas')
            this.gas_types = this.gas_types.map(item => {
                return {
                    value: item.id,
                    text: item.name
                }
            })

            // Water
            this.water_types = this.utilities.filter(util => util.type.name == 'Water')
            this.water_types = this.water_types.map(item => {
                return {
                    value: item.id,
                    text: item.name
                }
            })
            
            // Electricity
            this.electricity_types = this.utilities.filter(util => util.type.name == 'Electricity')
            this.electricity_types = this.electricity_types.map(item => {
                return {
                    value: item.id,
                    text: item.name
                }
            })

            // Sewage
            this.sewage_types = this.utilities.filter(util => util.type.name == 'Sewage')
            this.sewage_types = this.sewage_types.map(item => {
                return {
                    value: item.id,
                    text: item.name
                }
            })
        }
    },
    async created() {
        this.getUtilties();
        await this.$store.dispatch('properties/getPropertyTypes');
        await this.$store.dispatch('properties/getApplianceTypes');

        // Get Countries
        await this.$store.dispatch('addresses/setCountries');

        // Get States for Country
        await this.$store.dispatch('addresses/setStatesForCountry', this.countries[0].id);

        // Get Cities for State

        this.form.currentSection = 'address'
        // this.form.currentSection = 'basic_details'
        // this.form.currentSection = 'connection_details'
        // this.form.currentSection = 'appliances'
    },
    mounted() {
        // this.createMap()
        // this.createAutoCompleteListener()
    },
}
</script>