export default class ProjectOpportunity {

    /**
     * Find a opportunity by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/project-opportunities/${id}`);

        // console.log('request', request);
        let opportunity = request.data.project_opportunity || {};

        return opportunity;
    }

    /**
    * Create a opportunity.
    * @param {object} fields
    */
    static async create(fields) {
        let request = await axios.post(`/api/v1/projects/${fields.project_id}/project-opportunities`, fields);
        let opportunity = request.data.project_opportunity || {};

        return opportunity;
    }

    /**
    * Update a opportunity.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.put(`/api/v1/project-opportunities/${id}`, fields);
        let opportunity = request.data.opportunity || {};

        return opportunity;
    }

    /**
    * Delete a projecy opportunity.
    */
    static async delete(id) {
        let request = await axios.delete(`/api/v1/project-opportunities/${id}`);
        return request.status === 200;
    }

    /**
    * Attach a grant to a opportunity.
    */
    static async attachGrant(opportunity_id, fields) {
        let request = await axios.post(`/api/v1/project-opportunities/${opportunity_id}/grants`, fields);
        return request.status === 200;
    }

    /**
    * detach a grant to a opportunity.
    */
    static async detachGrant(opportunity_id, grant_id) {
        let request = await axios.delete(`/api/v1/project-opportunities/${opportunity_id}/grants/${grant_id}`);
        return request.status === 200;
    }

    /**
    * Attach a rebate to a opportunity.
    */
    static async attachRebate(opportunity_id, fields) {
        let request = await axios.post(`/api/v1/project-opportunities/${opportunity_id}/rebates`, fields);
        return request.status === 200;
    }

    /**
    * detach a rebate to a opportunity.
    */
    static async detachRebate(opportunity_id, rebate_id) {
        let request = await axios.delete(`/api/v1/project-opportunities/${opportunity_id}/rebates/${rebate_id}`);
        return request.status === 200;
    }

    /**
    * Attach a loan to a opportunity.
    */
    static async attachLoan(opportunity_id, fields) {
        let request = await axios.post(`/api/v1/project-opportunities/${opportunity_id}/loans`, fields);
        return request.status === 200;
    }

    /**
    * detach a loan to a opportunity.
    */
    static async detachLoan(opportunity_id, loan_id) {
        let request = await axios.delete(`/api/v1/project-opportunities/${opportunity_id}/loans/${loan_id}`);
        return request.status === 200;
    }
}