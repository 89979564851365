<template>
  <h2 class="text-lg mb-6">
      <slot></slot>
  </h2>
</template>

<script>
export default {

}
</script>
