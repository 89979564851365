import CityAPI from '@api/cities';

const state = {
    city: {},
    cities: [],
};
 
const getters = {
    getCity(state)
    {
        return state.city;
    },
    getCities(state)
    {
        return state.cities;
    }
};

const actions = { 
    async getCity({commit}, id)
    {
        let city = await CityAPI.find(id);
        commit('setCity', city);
    },

    /**
    * Create a city.
    * @param {object} fields The form fields
    */
    async updateCity({commit}, data) {
        let successful = await CityAPI.update(data.id, data.fields);
        return successful;
    },

    // delete
    async deleteCity({commit}, id)
    {
        // await axios.delete(`/api/v1/cities/${id}`)
            // .then(response => commit('setCity', response.data.state))
    },

    async getCities({commit})
    {
        let cities = await CityAPI.all();
        commit('setCities', cities);
    }
};

const mutations = {
    setCity(state, city)
    {
        state.city = city;
    },
    setCities(state, cities)
    {
        state.cities = cities;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
