<template>
    <!-- Wrapper -->
    <div class="bg-gray-100 rounded">
        <input :type="type" :name="name" :id="id || name" :value="value" @change="handleChange" @input="handleInput" :placeholder="placeholder" :multiple="multiple" :autocomplete="autocomplete" class="bg-transparent w-full px-4 py-2">
    </div>
</template>

<script>
export default {
  props: {
    autocomplete: {},
    placeholder: {},
    value: {},
    name: {},
    id: {},
    multiple: {
      default: false
    },
    type: {
      default: 'file'
    }
  },
  methods: {
    handleInput (event) {
      this.$emit('input', event)
      // this.$emit('change', event.target.value)
    },
    handleChange (event) {
      // this.$emit('input', event.target.value)
      this.$emit('change', event)
    }
  }
}
</script>
