let formDefaults =  {
    appliance_type_id: {
        value: 1,
        rules: 'required|integer'
    },
    manufacturer_id: {
        value: 1,
        rules: 'required|string'
    },
    model_number: {
        value: 'ABCD',
        rules: 'required|string'
    },
    serial_number: {
        value: '12345',
        rules: 'string'
    },
    year: {
        value: '2015',
        rules: 'string'
    },
    warranty_expires_at: {
        value: null,
        rules: 'required|date'
    },
    room_id: {
        value: null,
        rules: 'integer'
    },
    utility_id: {
        value: null,
        rules: 'integer'
    },
    rented: {
        value: false,
        rules: 'boolean'
    },
    service_provider: {
        value: '',
        rules: 'string'
    },

}

let formConfig = {}

export { formDefaults, formConfig }