const state = {
    task: {},
    tasks: []
};
 
const getters = {
    getTask(state)
    {
        return state.task;
    },
    getTasks(state)
    {
        return state.tasks;
    }
};

const actions = { 
    async getTask({commit}, id)
    {
        await axios.get(`/api/v1/tasks/${id}`)
            .then(response => commit('setTask', response.data.task))
    },
    async getTasks({commit})
    {
        await axios.get(`/api/v1/tasks`)
            .then(response => commit('setTasks', response.data.tasks))
    },
    async completeTask({commit}, id)
    {
        await axios.post(`/api/v1/tasks/${id}/complete`)
            .then(response => {
                // commit('setTask', response.data.task);
            })
    },
    relaceTask({commit}, newTask)
    {
        // find task in tasks array matching id
        let tasks = state.tasks.filter(task => task.id != newTask.id)
        let newTasks = [...tasks, newTask];

        commit('setTasks', newTasks);    
    }

};

const mutations = {
    setTask(state, task)
    {
        state.task = task;
    },
    setTasks(state, tasks)
    {
        state.tasks = tasks;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
