<template>
  <button :disabled="disabled" class="py-2 px-4 focus:outline-none fill-current" :class="classes" @click="$emit('click')">
    <!-- Left Icon -->
    <v-icon v-if="iconLeft" class="mr-2 inline-block" :name="iconLeft"></v-icon>

    <!-- Content -->
    <span v-else class="flex items-center gap-x-2 justify-center">
      <v-icon v-if="loading" name="circle-notch" :spin="loading"></v-icon> 
      <slot></slot>
      <v-icon v-if="navigation" class="fill-current" scale="0.75" name="chevron-right"></v-icon>
    </span>
    <!-- Right Icon -->
    <v-icon v-if="iconRight" class="inline-block" :name="iconRight"></v-icon>
  </button>
</template>

<script>

let styles = {
  primary: ['text-white bg-orange-700 hover:bg-orange-600'],
  'primary-outline': ['text-black border border-orange-700 hover:bg-orange-100'],
  purple: ['text-white bg-purple-500 hover:bg-purple-600'],
  orange: ['text-white bg-orange-700 hover:bg-orange-600'],
  green: ['text-white bg-green-500 hover:bg-green-600'],
  yellow: ['text-white bg-yellow-500 hover:bg-yellow-600'],
  light: [`bg-orange-100 hover:bg-orange-200`],
  white: ['text-gray-900 bg-white hover:bg-orange-100'],
  danger: ['text-white bg-red-600 hover:bg-red-700'],
  gray: [`bg-gray-100 hover:bg-gray-200`],
  close: ['bg-transparent hover:bg-gray-100'],
};

export default {
  props: {
    context: {
      type: String,
      default: 'orange'
    },
    iconLeft: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    navigation: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      classes: []
    }
  },
  watch: {
    disabled()
    {
      this.classes = this.getClasses();
    }
  },
  methods: {
    getClasses () {

      if(this.disabled) {
        return styles.gray
      }
      
      return this.context ? styles[this.context] : styles.primary;
    }
  },
  mounted() {
    this.classes = this.getClasses();
  }
}
</script>
