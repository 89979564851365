let formDefaults =  {
    email: {
        value: '',
        rules: 'required|string|email'
    },
    password: {
        value: '',
        rules: 'required|string'
    },
    // remember: {
    //     value: false,
    //     rules: 'required|boolean'
    // }
}

let formConfig = {}

export { formDefaults, formConfig }
