<template>
    <p class="text-red-500 mt-1">
        <slot></slot>
    </p>
</template>

<script>
export default {

}
</script>
