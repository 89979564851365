<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
           <!-- Title -->
           <SectionTitle>Submit Feedback</SectionTitle>

            <!-- Form -->
            <div id="create-feedback-form" class="mb-8">

                <p class="mb-8">Please tell us how we can improve Paddio. Thank you in advance for helping us simplify home ownership.</p>
                <!-- Email -->
                <FormRow v-show="!isAuthenticated">
                    <Label for="email">Email</Label>
                    <TextInput name="email" type="email" v-model="form.email" class="2/3" placeholder=""/>
                    <Errors v-if="form.errors.has('email')">{{form.errors.first('email')}}</Errors>
                </FormRow>

                <!-- Comments -->
                <FormRow>
                    <Label for="comments">Comments</Label>
                    <TextareaInput name="comments" v-model="form.comments" class="2/3" placeholder="Write a short description..."/>
                    <Errors v-if="form.errors.has('comments')">{{form.errors.first('comments')}}</Errors>
                </FormRow>
            </div>

            <!-- Actions -->
            <FormRow>
                <square-button context="orange" class="mr-2" @click="submit">Submit</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@property/Forms/CreateFeedbackForm.js'
import { mapGetters } from 'vuex'


export default {
    components: {
        Modal
    },
    data()
    {
        return {
            name: 'create-feedback-modal',
            form: new Form(formDefaults, formConfig),
        }
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            user: 'auth/getUser',
        })
    },
    methods:
    {
        beforeOpen(event)
        {},
        submit()
        {
            this.form.post('/api/v1/feedback')
                .then(response => {
                    // close modal
                    this.$modal.hide(this.name)

                    // notify user
                    this.$notify({
                        title: 'Success',
                        text: response.data.message,
                        type: 'success'
                    });
                })
                .catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.data.message,
                        type: 'error'
                    })
                })
        }
    }
}

</script>
