<template>
    <div class="inline -ml-1">
        <a href="#" @click.prevent="$modal.show('create-appliance-modal', {rooms, room, property})"  class="relative inline-block mr-2 mb-2 p-4 rounded border bg-orange-50 md:h-32 w-full md:w-64 overflow-hidden">
            <div class="flex md:h-full items-center justify-center text-center">
                <!-- <div class="text-center"> -->
                    <v-icon name="plus" class="block md:mb-2 mr-2 md:mr-0"></v-icon>
                    <p v-if="!!room">Add an appliance</p>
                    <p v-else>Add your first appliance</p>
                <!-- </div> -->
            </div>
        </a>
        <!-- <CreateApplianceModal></CreateApplianceModal> -->
    </div>
</template>

<script>
import CreateApplianceModal from './Appliance/CreateApplianceModal.vue'

export default {
    components: {
        CreateApplianceModal
    },
    props: {
        rooms: {},
        room: {},
        property: {}
    }
}
</script>