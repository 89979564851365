<template>
    <!-- Wrapper -->
    <div class="flex bg-white border border-gray-300 rounded items-center">

        <div v-if="$slots.icon" class="px-2 text-gray-500">
          <slot name="icon" class="pl-4"></slot>
        </div>
        <input 
          :type="type" 
          :name="name" 
          :id="id || name" 
          :value="value" 
          @input="$emit('input', $event.target.value)" 
          @change="$emit('change', $event.target.value)" 
          @focus="$emit('focus', $event)" 
          @focusout="$emit('focusout', $event)" 
          :disabled="disabled"
          :placeholder="placeholder" 
          :autocomplete="autocomplete" 
          class="bg-transparent w-full pl-2 pr-4 py-2"/>
    </div>
</template>

<script>
export default {
  props: {
    autocomplete: {},
    placeholder: {},
    value: {},
    name: {},
    id: {},
    type: {
      default: 'text'
    },
    disabled: {
      default: false
    }
  },
  methods: {
    handleInput (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>
