<template>
    <!-- Wrapper -->
    <div class="flex items-center">
        <div v-if="$slots.icon" class="px-2 text-gray-500">
          <slot name="icon" class="pl-4"></slot>
        </div>
        <input 
          type="checkbox" 
          :name="name" 
          :id="id || name" 
          :checked="value"
          @input="$emit('input', $event.target.checked)" 
          @change="$emit('change', $event.target.checked)" 
          @focus="$emit('focus', $event)" 
          @focusout="$emit('focusout', $event)" 
          :disabled="disabled"
          class="custom-checkbox"
        />
        <label :for="id || name" class="flex-1 pl-4">
          <slot>{{ label }}</slot>
        </label>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {},
    id: {},
    label: {
      type: String,
      default: ''
    },
    disabled: {
      default: false
    }
  },
  methods: {
    handleInput (event) {
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style scoped>
.custom-checkbox {
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    background-color: white;
    transition: background-color 0.1s;
}

.custom-checkbox:checked {
    @apply bg-orange-700 border-orange-700;
    /* background-color: @apply;
    border-color: orange; */
}

.custom-checkbox:checked::after {
    content: '';
    position: absolute;
    left: 7px;
    top: 5px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.custom-checkbox:hover {
    border-color: #bbb;
}
</style>
