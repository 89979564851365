<template>
    <div draggable 
        @click="$emit('click')"
        @dragstart="onApplianceDrag"
        @dragend="onApplianceDragStop" 
        class="appliance-card relative inline-block md:mr-2 md:mb-2 p-4 rounded border hover:bg-gray-100 w-full md:h-32 md:w-64 overflow-hidden cursor-pointer" 
        :class="[dragging ? 'md:cursor-grabbing' : 'md:cursor-move']"
    >
        <!-- Mobile clickable cover -->
        <div :data-appliance-id="appliance.id" class="view-appliance-button-mobile md:hidden absolute top-0 left-0 w-full h-full bg-transparent"></div>
        
        <!-- Upper -->
        <div class="hidden md:flex justify-between items-start mb-2">
            <div class="flex items-center">
                <v-icon v-if="appliance.is_incomplete" name="exclamation-circle" class="mr-2 text-red-600 fill-current"></v-icon>
                <span>{{appliance.type.name}}</span>
            </div>
            <MoreButton class="view-appliance-button" :data-appliance-id="appliance.id" @click="$emit('click')"></MoreButton>
        </div>

        <!-- Lower -->
        <div class="flex">
            <!-- Left -->
            <div class="h-10 w-10 md:h-12 md:w-12 mr-4">
                <img :src="image(appliance.type.image_url)" :alt="appliance.type.name">
            </div>
            <!--Right  -->
            <div></div>
            
            <!-- If appliance is complete/incomplete -->
            <div>
                <p class="block md:hidden">{{appliance.type.name}}</p>
                <div v-if="!appliance.is_new">
                    <p class="hidden md:block">{{appliance.model || '&nbsp;'}}</p>
                    <p class="text-gray-700 text-sm">{{appliance.manufacturer ? appliance.manufacturer.name : '&nbsp;'}}</p>
                </div>
                <div v-else class="md:hidden text-red-600 text-sm">
                    Information Missing
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
import MoreButton from '../../../Components/Buttons/MoreButton.vue';

export default {
    components: {
        MoreButton   
    },
    props: {
        appliance: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            dragging: false
        }
    },
    methods: {
        onApplianceDrag(dragEvent)
        {
            this.dragging = true;
            dragEvent.dataTransfer.setData('appliance_id', this.appliance.id);
        },
        onApplianceDragStop(dragEvent)
        {
            this.dragging = false;
        }
    }
}
</script>