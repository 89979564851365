<template>
    <!-- <a class="hover:text-orange-600 cursor-pointer" @click.prevent="to == '#' ? $router.go(-1) : $router.push(to)">&larr; <slot></slot></a> -->

    <div class="flex flex-wrap gap-x-1 gap-y-2">
        <div v-for="(route, index) in routes" :key="index">
            <router-link :to="route.route" class="hover:underline">{{ route.name }}</router-link>
            <span v-if="index != routes.length" class="mx-1"> 
                >
            </span>
        </div>
        <div class="text-orange-700"><slot></slot></div>
    </div>
</template>

<script>
export default {
    props: {
        to: {
            type: String,
            default: '#'
        },
        routes: {
            type: Array,
            default: () => []
        }
    }
}
</script>
