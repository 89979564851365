import UserTypeAPI from '../../api/user_types';

const state = {
    userType: {
        //
    },
    userTypes: []
};
 
const getters = {
    getUserType(state) {
        return state.user;
    },
    getUserTypes(state) {
        return state.userTypes;
    }
};

const actions = { 
    async getUserType({commit}, id) {
        let userType = await UserTypeAPI.find(id);
        commit('setUserType', userType);
    },

    async getUserTypes({commit}) {
        let userTypes = await UserTypeAPI.all();
        commit('setUserTypes', userTypes);
    },
};

const mutations = {
    setUserType(state, userType = {})
    {   
        state.userType = userType;
    },
    setUserTypes(state, userTypes = [])
    {
        state.userTypes = userTypes;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
