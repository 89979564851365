<template>
    <div class="flex justify-end md:justify-between items-center w-full pr-4 border-t border-gray-100">
        <!-- Summary -->
        <div class="hidden md:flex round items-center bg-white p-2 md:px-4">
            <span v-if="!list.visibleCount()">0 records</span>
            <span v-else>Showing {{ list.startRecordIndex }} to {{ list.visibleCount() < list.endRecordIndex ? list.visibleCount() : list.endRecordIndex}}  of {{ list.visibleCount() }}</span>
        </div>

        <!-- Navigation -->
        <div class="py-2">
            <div class="flex">
                <!-- Back page -->
                <PaginationButton :disabled="list.currentPage == 1" @click="list.backPage()"> {{ "<" }} </PaginationButton>
                
                <!-- Next page -->
                <PaginationButton 
                    v-for="(page, index) in list.totalPages" 
                    :key="index" 
                    :currentPage="list.currentPage == page"
                    @click="list.setPage(page)"
                > 
                    {{ page }} 
                </PaginationButton>

                <!-- Next page -->
                <PaginationButton :disabled="list.currentPage == list.totalPages" @click="list.nextPage()"> {{ ">" }} </PaginationButton>

            </div>
        </div>
    </div>
  
</template>

<script>
import PaginationButton from './PaginationButton.vue';

export default {
    components: {
        PaginationButton,
    },
    props: {
        list: {
            type: Object,
            required: true,
        },
    },
}
</script>