import axios from 'axios';
import ApplianceAPI from '@api/appliances';

const state = {
    appliance: {
        alerts: []
    },
};
 
const getters = {
    getAppliance(state) {
        return state.appliance;
    }
};

const actions = { 
    async getAppliance({commit}, id)
    {
        let appliance = await ApplianceAPI.find(id)

        console.log(appliance)
        
        if(appliance) {
            commit('setAppliance', appliance)
        }
    },
    async updateAppliance({commit, dispatch}, data)
    {
        await axios.patch(`/api/v1/appliances/${data.id}`, {
            user_id: this.getters['auth/getUser'].id,
            ...data.fields
        })
            .then(response => {
                dispatch('properties/updateAppliance', response.data.appliance, {root: true})
                commit('setAppliance', response.data.appliance)
            });
    },
    async deleteAppliance({commit, dispatch}, appliance)
    {
        await axios.delete(`/api/v1/appliances/${appliance.id}`, {
            user_id: this.getters['auth/getUser'].id,
        })
            .then(response => {
                if(response.data.appliance) {

                    commit('deleteAppliance')
                    // Remove from Property
                    dispatch('properties/removeAppliance', response.data.appliance.id, {root: true})
                    // Remove from Room
                    dispatch('rooms/removeAppliance', response.data.appliance.id, {root: true})
                }
            });
    },
    addDocument({commit}, document)
    {   
        commit('addDocument', document);
    },
    async addServiceRecord({commit}, fields)
    {
        await axios.post(`/api/v1/appliances/${state.appliance.id}/service_records`, fields)
            .then(response => {
                commit('addServiceRecord', response.data.service_record)
            });
    },
    async setAppliance({commit}, appliance) {
        await commit('setAppliance', appliance);
    },
    async addAlert({commit}, fields)
    {
        await axios.post(`/api/v1/appliances/${state.appliance.id}/alerts`, fields)
            .then(response => {
                commit('addAlert', response.data.alert) 
            });
    },
    removeAlert({commit}, alert_id)
    {
        commit('removeAlert', alert_id) 
    }
};

const mutations = {
    setAppliance(state, appliance = {})
    {   
        state.appliance = appliance;
    },
    deleteAppliance(state)
    {   
        state.appliance = {}
    },
    addDocument(state, document)
    {   
        state.appliance.documents.push(document);
    },
    addServiceRecord(state, serviceRecord)
    {
        // console.log(state.appliance, serviceRecord)
        if(state.appliance) {
            state.appliance.service_records.unshift(serviceRecord)
        } else {
            state.property.service_records.unshift(serviceRecord)
        }
    },
    addAlert(state, alert)
    {   
        state.appliance.alerts.push(alert);
    },
    removeAlert(state, id)
    {
        if(state.appliance.alerts) {
            state.appliance.alerts = state.appliance.alerts.filter(alert => {
                return alert.id != id
            })
        };
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
