<template>
<div>
  <div class="flex flex-col min-h-screen">
    <div class="mx-auto container px-4 md:px-6 flex-1">
      <!-- Breadcrumb -->
      <!-- <div class="mb-10">
        <Breadcrumb to="/">Back to Home</Breadcrumb>
      </div> -->
      <UpdateUserForm></UpdateUserForm>
    </div>  
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UpdateUserForm from '../Forms/UpdateUserForm.vue'

export default {
  title: 'Settings',
  components: {
    UpdateUserForm
  },
  data()
  {
    return {
        name: 'User Settings',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
}
</script>