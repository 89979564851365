export default class ComponentType {

    /**
     * Find a component_types by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/component_types/${id}`);
        let component_types = request.data.component_types || {};

        return component_types;
    }

    /**
    * Get all component_types.
     */
    static async all() {
        let request = await axios.get(`/api/v1/component_types`);
        let component_types = request.data.component_types || [];

        return component_types;
    }

    /**
    * Create a component_types.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/component_types`, fields);
    //     let component_types = request.data.component_types || {};

    //     return component_types;
    // }

    /**
    * Update a component_types.
    * @param {int} id 
    * @param {object} fields 
    */
    // static async update(id, fields = {}) {
    //     let request = await axios.put(`/api/v1/component_types/${id}`, fields);
    //     let component_types = request.data.component_types || {};

    //     return component_types;
    // }
}