<template>
  <button 
    :disabled="disabled"
    class="flex items-center justify-center w-10 h-10 md:w-8 md:h-8 rounded-full" 
    :class="[
      disabled ? ' text-gray-500 cursor-default' : 'hover:bg-gray-100 hover:text-gray-900',
      currentPage ? 'bg-orange-700 text-white' : ''
    ]"
    @click.prevent="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style scoped lang="scss">
div {

}
</style>