import EnergyReport from '../../api/energy_reports';

const state = {
    energyReport: {}
};
 
const getters = {
    getEnergyReport(state) {
        return state.energyReport;
    },
};

const actions = { 
    /**
     * Get a energy report by ID.

     * @returns null
     */
    async getEnergyReport({commit}, id)
    {
        let energyReport = await EnergyReport.find(id);
        commit('setEnergyReport', energyReport);
        return;
    },
};

const mutations = {
    setEnergyReport(state, energyReport)
    {
        state.energyReport = energyReport;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
