const state = {
    service_record: {},
};
 
const getters = {
    getServiceRecord(state) {
        return state.service_record;
    },
};

const actions = { 
    async addDocument({commit}, document)
    {   
        commit('addDocument', document);
    },
    async setServiceRecord({commit}, service_record)
    {
        commit('setServiceRecord', service_record)
    }
};

const mutations = {
    addDocument(state, document)
    {   
        if(state.service_record.documents)
        {
            state.service_record.documents.push(document);
        }
    },
    setServiceRecord(state, service_record)
    {
        state.service_record = service_record;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
