import OpportunityAPI from '@api/project_opportunities';

const state = {
    opportunity: {
        project: {},
        rebates: [],
        grants: [],
        loans: [],
    }
};
 
const getters = {
    getOpportunity(state)
    {
        return state.opportunity;
    }
};

const actions = { 
    /**
     * Get a project opportunity.
     * @param {int} id
     */
    async getOpportunity({commit}, id)
    {
        let opportunity = await OpportunityAPI.find(id);
        commit('setOpportunity', opportunity);
        return;
    },

     /**
     * Create a project opportunity.
     * @param {object} fields
     */
    async createOpportunity({commit}, fields)
    {
        let opportunity = await OpportunityAPI.create(fields);
        commit('setOpportunity', opportunity);
        return;
    },

    /**
     * Delete a project opportunity.
     * @param {int} id
     */
    async deleteOpportunity({commit}, id)
    {
        let successful = await OpportunityAPI.delete(id);
        commit('setOpportunity', {});

        return successful;
    },

    /**
     * GRANT actions
     */

    /**
    * Attach a grant to an opportunity.
    * @return {bool} successful
    */
    async attachGrant({commit, state}, fields)
    {
        let successful = await OpportunityAPI.attachGrant(state.opportunity.id, fields);
        return successful;
    },
    /**
    * Detach a grant to am opportunity.
    * @return {bool} successful
    */
    async detachGrant({commit, state}, fields)
    {
        let successful = await OpportunityAPI.detachGrant(fields.opportunity_id, fields.grant_id);
        return successful;
    },

    /**
     * REBATE actions
     */
    
    /**
    * Attach a rebate to an opportunity.
    * @return {bool} successful
    */
    async attachRebate({commit, state}, fields)
    {
        let successful = await OpportunityAPI.attachRebate(state.opportunity.id, fields);
        return successful;
    },
    /**
    * Detach a rebate to am opportunity.
    * @return {bool} successful
    */
    async detachRebate({commit, state}, fields)
    {
        let successful = await OpportunityAPI.detachRebate(fields.opportunity_id, fields.rebate_id);
        return successful;
    },

    /**
     * LOAN actions
     */
    
    /**
    * Attach a loan to an opportunity.
    * @return {bool} successful
    */
    async attachLoan({commit, state}, fields)
    {
        let successful = await OpportunityAPI.attachLoan(state.opportunity.id, fields);
        return successful;
    },
    /**
    * Detach a loan to am opportunity.
    * @return {bool} successful
    */
    async detachLoan({commit, state}, fields)
    {
        let successful = await OpportunityAPI.detachLoan(fields.opportunity_id, fields.loan_id);
        return successful;
    },
};

const mutations = {
    setOpportunity(state, opportunity = {})
    {
        state.opportunity = opportunity;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
