<template>
    <Modal :name="name" width="600" height="auto" @before-open="beforeOpen">
        <div class="p-6 pt-16 md:p-16">
           <!-- Title -->
           <SectionTitle>Add Service Record</SectionTitle>

            <!-- Form -->
            <div id="create-service-record-form" class="mb-8">
                <!-- Title -->
                <FormRow>
                    <Label for="title">Title</Label>
                    <TextInput name="title" v-model="form.title" class="2/3" placeholder="Annual Servicing"/>
                    <Errors v-if="form.errors.has('title')">{{form.errors.first('title')}}</Errors>
                </FormRow>

                <!-- Service Date -->
                <FormRow>
                    <Label for="service_date">Service Date</Label>
                    <TextInput type="date" name="service_date" v-model="form.service_date" class="2/3"/>
                    <Errors v-if="form.errors.has('service_date')">{{form.errors.first('service_date')}}</Errors>
                </FormRow>

                <!-- Warranty Expires (Optional) -->
                <FormRow>
                    <Label for="warranty_expires_date">Warranty Expiry Date</Label>
                    <TextInput type="date" name="warranty_expires_date" v-model="form.warranty_expires_date" class="2/3"/>
                    <Errors v-if="form.errors.has('warranty_expires_date')">{{form.errors.first('warranty_expires_date')}}</Errors>
                </FormRow>

                <!-- Cost -->
                <FormRow>
                    <Label for="cost">Cost</Label>
                    <TextInput name="cost" v-model="form.cost" class="2/3" @focus="form.cost = cost_value" @focusout="updateCostField" placeholder="$100.53"/>
                    <Errors v-if="form.errors.has('cost')">{{form.errors.first('cost')}}</Errors>
                </FormRow>

                <!-- Description -->
                <FormRow>
                    <Label for="description">Description</Label>
                    <TextareaInput name="description" v-model="form.description" class="2/3" placeholder="Write a short summary of this service..."/>
                    <Errors v-if="form.errors.has('description')">{{form.errors.first('description')}}</Errors>
                </FormRow>
            </div>

            <!-- Actions -->
            <FormRow>
                <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import SectionTitle from '@components/SectionTitle'
import Form from '@lukejm/form-js'
import {formDefaults, formConfig} from '@property/Forms/CreateServiceRecordForm.js'
import SquareButton from '@components/Buttons/SquareButton.vue'
import AddButton from '../../Components/Buttons/AddButton.vue'

export default {
    components: {
        Modal,
        SectionTitle,
        SquareButton,
        AddButton
    },
    data()
    {
        return {
            name: 'create-service-record-modal',
            form: new Form(formDefaults),
            cost_value: 0
        }
    },
    methods:
    {
        updateCostField(event)
        {
            let value = event.target.value;

            if(value) {
                this.cost_value = parseFloat(value).toFixed(2);
                this.form.cost = `$ ${this.cost_value}`;
            }
        },
        async getRecordable(recordable_name)
        {
            // appliance
            if(recordable_name == 'appliance') {
                this.action = 'appliances/addServiceRecord'
                this.appliance = await this.$store.getters['appliances/getAppliance'];
                this.form.recordable_name = recordable_name
            };
            // room
            if(recordable_name == 'room') {
                this.action = 'rooms/addServiceRecord'
                this.room = await this.$store.getters['rooms/getRoom'];
                this.form.recordable_name = recordable_name
            };
            // property
            if(recordable_name == 'property') {
                this.action = 'properties/addServiceRecord'
                this.property = await this.$store.getters['properties/getCurrentProperty'];
                this.form.recordable_name = recordable_name
            };
        },
        beforeOpen(event)
        {
            if(event.params) {
                this.getRecordable(event.params.recordable);
            }

            this.form.service_date = (new Date()).toISOString().split('T')[0];
        },
        async submit()
        {
            this.form.cost = parseInt(this.cost_value*100);

            await this.$store.dispatch(this.action, this.form.data())
                .then((res) => {
                    this.$modal.hide(this.name)
                    this.form = new Form(formDefaults);
                    this.$notify({
                        title: 'Success',
                        text: 'Your service record has been added.',
                        type: 'success'
                    });
                }).catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        }
    },
}
</script>
