export default class Document {

    /**
     * Find a documents by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/documents/${id}`);
        let document = request.data.document || {};

        return document;
    }

    /**
    * Get all documents.
     */
    static async all() {
        let request = await axios.get(`/api/v1/documents`);
        let documents = request.data.documents || [];

        return documents;
    }

    /**
    * Create a documents.
    * @param {object} fields
    */
    // static async create(fields) {
    //     let request = await axios.post(`/api/v1/documents`, fields);
    //     let documents = request.data.documents || {};

    //     return documents;
    // }

    /**
    * Update a documents.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/documents/${id}`, fields);
        let documents = request.data.documents || {};

        return documents;
    }

    /**
    * Delete a document.
    */
    static async delete(id) {
        let request = await axios.delete(`/api/v1/documents/${id}`);
        let deleted = request.data.deleted || false;

        return deleted;
    }
}