let formDefaults =  {
    email: {
        value: '',
        rules: 'string'
    },
    comments: {
        value: '',
        rules: 'string'
    }
}

let formConfig = {}

export { formDefaults, formConfig }