<template>
  <body>
    <router-view/>
  </body>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      'isAuthenticated': 'auth/isAuthenticated'
    })
  },
  methods: {
    // /**
    //   * Scroll to div with Id.
    //   *   @param id
    //   */
    //   scrollToId(id)
    // {
    //     return window.document.querySelector(id).scrollIntoView({behavior: 'smooth'});
    // }
  },
  async created()
  {
    if(!this.isAuthenticated) {
      await this.$store.dispatch('auth/getUser')
    }
    
  }
}
</script>

<style lang="scss">
// a {
//   @apply text-orange-700 hover:text-orange-500 hover:underline
// }
</style>
