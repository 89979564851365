<template>
    <div class="mr-6">
        <!-- <a :href="document.download_path" target="_blank" class="flex items-center text-gray-600 hover:text-orange-500"> -->
        <a :href="`/downloads?path=${document.filepath}`" target="_blank" class="document-link flex items-center text-gray-600 hover:text-orange-500">

            <v-icon name="file-invoice" class="fill-current text-orange-500 mr-2"></v-icon>
            <span>{{document.name}}</span>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            default: () => new Object(),
        }
    }

}
</script>

<style>

</style>