export default class Component {

    /**
     * Find a component by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/components/${id}`);
        let component = request.data.component || {};

        return component;
    }

    /**
    * Create a component.
    * @param {object} fields
    */
    static async create(fields) {
        let request = await axios.post(`/api/v1/components`, fields);
        let component = request.data.component || {};

        return component;
    }

    /**
    * Update a component.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/components/${id}`, fields);
        let component = request.data.component || {};

        return component;
    }

    /**
    * Delete a component.
    * @param {int} id 
    */
    static async delete(id) {
        let request = await axios.delete(`/api/v1/components/${id}`);
        let successful = request.data.success || false;

        return successful;
    }
}