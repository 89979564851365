<template>
  <div class="mb-2 flex gap-x-2 items-center">
      <label class="pt-1"><slot></slot></label>
      <Tooltip v-if="tooltip" :text="tooltip"> TOOLTIP </Tooltip>
  </div>
</template>

<script>
import Tooltip from '../Tooltip.vue';

export default {
  props: {
    for: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: false
    }
  },
  components: {
    Tooltip
  },
}
</script>
