import PeriodOfAvailabilityAPI from '@api/periods_of_availability';

const state = {
    period_of_availability: {
        user: {},
    },
    periods_of_availability: [],
};
 
const getters = {
    getPeriodOfAvailability(state)
    {
        return state.period_of_availability;
    },
    getPeriodsOfAvailability(state)
    {
        return state.periods_of_availability;
    },
};

const actions = { 
    // async getPeriodOfAvailability({commit}, id)
    // {
    //     await axios.get(`/api/v1/periods_of_availability/${id}`)
    //         .then(response => commit('setPeriodOfAvailability', response.data.period_of_availability))
    // },

    /**
    * Create a period_of_availability.
    */
    // async create({commit}, fields) {
    //     // console.log('create', fields);
    //     let period_of_availability = await PeriodOfAvailabilityAPI.create(fields.user_id, fields);
    //     commit('setPeriodOfAvailability', period_of_availability);
    // },

    /**
    * Update a period_of_availability.
    * @param {object} fields The form fields
    */
    async update({commit}, fields) {
        let successful = await PeriodOfAvailabilityAPI.update(fields.id, fields);
        return successful;
    },

    // delete
    // async delete({commit}, id)
    // {
    //     let successful = await PeriodOfAvailabilityAPI.delete(id);
    //     return successful;
    // },

    // // get all periods_of_availability
    // async getPeriodsOfAvailability({commit}, user_id)
    // {
    //     let periods_of_availability = await PeriodOfAvailabilityAPI.findAll(user_id);
    //     commit('setPeriodsOfAvailability', periods_of_availability);
    // }

    // async getPeriodsOfAvailability({commit})
    // {
    //     await axios.get(`/api/v1/periods_of_availability`)
    //         .then(response => commit('setPeriodOfAvailabilitys', response.data.periods_of_availability))
    // },
   
};

const mutations = {
    setPeriodOfAvailability(state, period_of_availability)
    {
        state.period_of_availability = period_of_availability;
    },
    setPeriodsOfAvailability(state, periods_of_availability)
    {
        state.periods_of_availability = periods_of_availability;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
