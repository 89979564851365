const state = {
    room: {
        property: {},
        appliances: []
    },
    ROOM_NAMES: [
        'Foyer',
        'Kitchen',
        'Pantry',
        'Dining Room',
        'Living Room',
        'Parlor',
        'Family Room',
        'Sunroom',
        'Office',
        'Bathroom',
        'Powder Room',
        'Laundry Room',
        'Storage Room',
        'Bedroom',
        'Closet',
        'Theater',
        'Gym',
        'Garage',
        'Attic',
        'Loft'
    ]
};
 
const getters = {
    getRoom(state) {
        return state.room;
    },
    getRoomNames(state) {
        return state.ROOM_NAMES.sort();
    }
};

const actions = { 
    async getRoom({commit}, id)
    {
        await axios.get(`/api/v1/rooms/${id}`,{
            user_id: this.getters['auth/getUser'].id
        })
            .then(response => {
                commit('setRoom', response.data.room)
            })
    },
    async updateRoom({commit, state}, fields)
    {
        await axios.patch(`/api/v1/rooms/${state.room.id}`, fields)
            .then(response => {
                commit('setRoom', response.data.room)
            })
    },
    async deleteRoom({commit, state})
    {   
        await axios.delete(`/api/v1/rooms/${state.room.id}`)
            .then(response => {
                console.log(response.data);
                commit('setRoom', {})
            })
    },
    async addDocument({commit}, document)
    {   
        commit('addDocument', document);
    },
    async addServiceRecord({commit, dispatch}, fields)
    {
        await axios.post(`/api/v1/rooms/${state.room.id}/service_records`, fields)
            .then(response => {

                // Add to room
                commit('addServiceRecord', response.data.service_record)
            });
    },
    setRoom({commit}, room)
    {
        commit('setRoom', room)
    },
    async addAppliance({commit}, appliance)
    {  
        commit('addAppliance', appliance);
    },
    async removeAppliance({commit}, id)
    {  
        commit('removeAppliance', id);
    },
};

const mutations = {
    addDocument(state, document)
    {   
        state.room.documents.push(document);
    },
    setRoom(state, room = {})
    {
        state.room = room;
    },
    addAppliance(state, appliance)
    {   
        if(!state.room.appliances.length) {
            state.room.appliances = []
        };

        state.room.appliances.push(appliance);
    },
    removeAppliance(state, id)
    {
            if(state.room && state.room.appliances) {
                state.room.appliances = state.room.appliances.filter(item => item.id != id);
            };
    },
    addServiceRecord(state, serviceRecord)
    {
        if(state.room) {
            state.room.service_records.unshift(serviceRecord)
        } else {
            state.room.property.service_records.unshift(serviceRecord)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}