<template>
  <Button 
    :context="context" 
    :icon-left="iconLeft"
    :icon-right="iconRight"
    :loading="loading"
    :disabled="disabled"
    :navigation="navigation"
    class="rounded-full" 
    @click="$emit('click')"
  >
    <slot></slot>
  </Button>
</template>

<script>
import Button from './Button'

export default {
    components: {
      Button
    },
    props: {
        context: {
            type: String
        },
        iconLeft: {
          type: String,
          default: ''
        },
        iconRight: {
          type: String,
          default: ''
        },
        loading: {
          type: Boolean,
          default: false
        },
        navigation: {
          type: Boolean,
          default: false
        },
        disabled: {
          type: Boolean,
          default: false
        }
    }
}
</script>
