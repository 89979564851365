export default class User {


    /**
     * Find activities for user by their ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/users/${id}/activities`);
        return request.data?.activities || {};
    }
}