import axios from 'axios';
import ComponentAPI from '../../api/components';
import ComponentTypeAPI from '../../api/component_types';

const state = {
    component: {
        //
    },
    componentTypes: []
};
 
const getters = {
    getComponent(state) {
        return state.component;
    },
    getComponentTypes(state) {
        return state.componentTypes;
    }
};

const actions = { 
    async getComponent({commit}, id) {
        
        let component = await ComponentAPI.find(id);
        commit('setComponent', component);
    },

    async getComponentTypes({commit}) {
        let componentTypes = await ComponentTypeAPI.all();
        commit('setComponentTypes', componentTypes);
    },

    // async createComponent({commit}, data) {
    //     let component = await ComponentAPI.create(data);
    //     commit('setComponent', component);
    // },

    async updateComponent({commit}, data) {
        let component = await ComponentAPI.update(data.id, data.fields);
        commit('setComponent', component);
    },

    /**
     * Delete a component.
    */
    async deleteComponent({commit}, id) {
        let successful = await ComponentAPI.delete(id);
        if(successful) {
            commit('deleteComponent');
        }
    }


    // async updateComponent({commit, dispatch}, data)
    // {
    //     await axios.patch(`/api/v1/components/${data.id}`, {
    //         user_id: this.getters['auth/getUser'].id,
    //         ...data.fields
    //     })
    //         .then(response => {
    //             dispatch('properties/updateComponent', response.data.component, {root: true})
    //             commit('setComponent', response.data.component)
    //         });
    // },


    // async deleteComponent({commit, dispatch}, component)
    // {
    //     await axios.delete(`/api/v1/components/${component.id}`, {
    //         user_id: this.getters['auth/getUser'].id,
    //     })
    //         .then(response => {
    //             if(response.data.component) {

    //                 commit('deleteComponent')
    //                 // Remove from Property
    //                 dispatch('properties/removeComponent', response.data.component.id, {root: true})
    //                 // Remove from Room
    //                 dispatch('rooms/removeComponent', response.data.component.id, {root: true})
    //             }
    //         });
    // },
    // addDocument({commit}, document)
    // {   
    //     commit('addDocument', document);
    // },
    // async addServiceRecord({commit}, fields)
    // {
    //     await axios.post(`/api/v1/components/${state.component.id}/service_records`, fields)
    //         .then(response => {
    //             commit('addServiceRecord', response.data.service_record)
    //         });
    // },
    // async addAlert({commit}, fields)
    // {
    //     await axios.post(`/api/v1/components/${state.component.id}/alerts`, fields)
    //         .then(response => {
    //             commit('addAlert', response.data.alert) 
    //         });
    // },
    // removeAlert({commit}, alert_id)
    // {
    //     commit('removeAlert', alert_id) 
    // }
};

const mutations = {
    setComponent(state, component = {})
    {   
        state.component = component;
    },
    setComponentTypes(state, componentTypes = [])
    {
        state.componentTypes = componentTypes;
    },




    deleteComponent(state)
    {   
        state.component = {}
    },
    addDocument(state, document)
    {   
        state.component.documents.push(document);
    },
    addServiceRecord(state, serviceRecord)
    {
        // console.log(state.component, serviceRecord)
        if(state.component) {
            state.component.service_records.unshift(serviceRecord)
        } else {
            state.property.service_records.unshift(serviceRecord)
        }
    },
    addAlert(state, alert)
    {   
        state.component.alerts.push(alert);
    },
    removeAlert(state, id)
    {
        if(state.component.alerts) {
            state.component.alerts = state.component.alerts.filter(alert => {
                return alert.id != id
            })
        };
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
