export default class ServiceCompany {

    /**
     * Find a service_companies by it's ID.
     * @param {int} id 
     */
    static async find(id) {

        let request = await axios.get(`/api/v1/service_companies/${id}`);
        let service_company = request.data.service_company || {};

        return service_company;
    }

    /**
    * Get all service_companies.
     */
    static async all() {
        let request = await axios.get(`/api/v1/service_companies`);
        let service_companies = request.data.service_companies || [];

        return service_companies;
    }

    /**
    * Create a service_company.
    * @param {object} fields
    */
    static async create(fields) {
        let request = await axios.post(`/api/v1/service_companies`, fields);
        let service_company = request.data.service_company || {};

        return service_company;
    }

    /**
    * Update a service_company.
    * @param {int} id 
    * @param {object} fields 
    */
    static async update(id, fields = {}) {
        let request = await axios.patch(`/api/v1/service_companies/${id}`, fields);
        let service_company = request.data.service_company || {};

        return service_company;
    }

    /**
    * A cities to a service company.
    */
    static async addCities(service_company_id, fields = {}) {
        let request = await axios.post(`/api/v1/service_companies/${service_company_id}/cities`, fields);
        let cities = request.data.cities || [];

        return cities;
    }

    /**
    * Remove city from a service company.
    */
    static async removeCity(service_company_id, fields = {}) {
        let request = await axios.delete(`/api/v1/service_companies/${service_company_id}/cities/${fields.city_id}`);
        let successful = request.data.success || false;
    
        return successful;
    }

    /**
    * Add incentive programs to a service company.
    */
    static async addIncentivePrograms(service_company_id, fields = {}) {
        let request = await axios.post(`/api/v1/service_companies/${service_company_id}/incentive_programs`, fields);
        let incentive_programs = request.data.incentive_programs || [];

        return incentive_programs;
    }

    /**
    * Remove incentive program from a service company.
    */
    static async removeIncentiveProgram(service_company_id, fields = {}) {
        let request = await axios.delete(`/api/v1/service_companies/${service_company_id}/incentive_programs/${fields.incentive_program_id}`);
        let successful = request.data.success || false;
    
        return successful;
    }

    /**
    * Add a note to a service company.
    */
    static async addNote(service_company_id, fields = {}) {
        let request = await axios.post(`/api/v1/service_companies/${service_company_id}/notes`, fields);
        let note = request.data.note || {};

        return note;
    }
}