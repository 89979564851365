<template>
    <div id="update-user-settings-form" class="pt-10 lg:w-2/3 mx-auto">

        <Card>
            <!-- Head text -->
            <SectionTitle class="mb-6">Update Account</SectionTitle>

            <!-- Fields -->
            <form class="mb-8" autocomplete="off" @submit.prevent="null">
                <!-- HIDDEN INPUT TO DISABLE FORM AUTOCOMPLETE -->
                <input autocomplete="false" name="hidden" type="text" style="display:none;">

                <!-- Name -->
                <FormRow class="md:flex">
                    <div class="w-full mr-4 mb-4 md:mb-0">
                        <Label for="first_name">First Name</Label>
                        <TextInput name="first_name" v-model="form.first_name" class="" placeholder="Mike" autocomplete="off"/>
                        <Errors v-if="form.errors.has('first_name')">{{form.errors.first('first_name')}}</Errors>
                    </div>
                    <div class="w-full">
                        <Label for="last_name">Last Name</Label>
                        <TextInput name="last_name" v-model="form.last_name" class="" placeholder="Holmes" autocomplete="off"/>
                        <Errors v-if="form.errors.has('last_name')">{{form.errors.first('last_name')}}</Errors>
                    </div>
                </FormRow>

                <!-- Email -->
                <FormRow>
                    <Label for="email">Email Address</Label>
                    <TextInput name="email" v-model="form.email" class="w-full md:w-2/3" placeholder="mikeholmes@example.com" autocomplete="off"/>
                    <Errors v-if="form.errors.has('email')">{{form.errors.first('email')}}</Errors>
                </FormRow>

                <!-- Phone -->
                <FormRow>
                    <Label for="phone">Phone</Label>
                    <TextInput type="tel" name="phone" v-model="form.phone" class="w-full md:w-1/2" placeholder="1234567890" autocomplete="off"/>
                    <Errors v-if="form.errors.has('phone')">{{form.errors.first('phone')}}</Errors>
                </FormRow>

                <!-- Password -->
                <FormRow class="md:flex">
                    <div class="w-full mr-4 mb-4 md:mb-0">
                        <Label for="password">Password</Label>
                        <TextInput type="password" name="password" v-model="form.password" class="" placeholder="" autocomplete="off"/>
                        <Errors v-if="form.errors.has('password')">{{form.errors.first('password')}}</Errors>
                    </div>
                    <div class="w-full">
                        <Label for="password_confirmation">Confirm Password</Label>
                        <TextInput type="password" name="password_confirmation" v-model="form.password_confirmation" class="" placeholder="" autocomplete="off"/>
                        <Errors v-if="form.errors.has('password_confirmation')">{{form.errors.first('password_confirmation')}}</Errors>
                    </div>
                </FormRow>
                <div class="mb-4 md:mb-8">
                    <span class="text-sm">Please leave password fields blank if you do not wish to make changes. Your password must be at least 8 characters.</span>
                </div>

                <!-- Actions -->
                <FormRow class="mb-12">
                    <SquareButton context="primary" class="mr-2" @click="submit()">Update</SquareButton>
                </FormRow>
            </form>
        </Card>
    </div>
</template>

<script>
import Form from '@lukejm/form-js'
import { mapGetters } from 'vuex'
import {formRules, formConfig, formStubs} from '../../User/Forms/UpdateUserForm.js'

export default {
    data() {
        return {
            form: new Form(formRules)
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser'
        })
    },
    methods: {
        /**
        * Validate form and sent request.
        */
        submit()
        { 
            this.form.validate();

            if(this.form.isValid) { 
                this.$store.dispatch('users/updateUser', this.form.data())
                    // Success
                    .then(response => 
                        this.$notify({
                            title: 'Success',
                            text: response.data.message,
                            type: 'success'
                        }) 
                    )
                    // Error
                    .catch(error => 
                        this.$notify({
                            title: 'Error',
                            text: error.data.message || error.message,
                            type: 'error'
                        }) 
                    )
            }
        },
    },
    created()
    {
        this.form.signup_user_type = this.$store.getters.currentProfile;
        this.form.first_name = this.user.first_name;
        this.form.last_name = this.user.last_name;
        this.form.email = this.user.email;
        this.form.phone = this.user.phone;
    }
}
</script>

<style>

</style>