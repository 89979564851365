let formDefaults =  {
    property_id: {
        value: null,
        rules: 'required|integer'
    },
    appliance_type_id: {
        value: null,
        rules: 'required|integer'
    },
    manufacturer_id: {
        value: null,
        rules: 'required|string'
    },
    model_number: {
        value: '',
        rules: 'required|string'
    },
    serial_number: {
        value: '',
        rules: 'string'
    },
    year: {
        value: '',
        rules: 'string'
    },
    warranty_expires_at: {
        value: null,
        rules: 'required|date'
    },
    room_id: {
        value: null,
        rules: 'integer'
    }
}

let formConfig = {}

export { formDefaults, formConfig }