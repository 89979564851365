<template>
    <Modal :name="name" width="800" height="auto" @before-open="beforeOpen" :scrollable="true">
        <div class="p-6 pt-16 md:p-16">
           <!-- Title -->
           <SectionTitle>Add Appliance</SectionTitle>

            <!-- Form -->
            <div id="create-appliance-form" class="mb-8">

                <!-- Appliance Type -->
                <FormRow>
                    <Label for="appliance_type_id">Appliance Type</Label>
                    <SelectInput name="appliance_type_id" v-model="form.appliance_type_id" class="2/3">
                        <option v-for="typee in appliance_types" :key="typee.id" :value="typee.id">{{typee.name}}</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('appliance_type_id')">{{form.errors.first('appliance_type_id')}}</Errors>
                </FormRow>

                <!-- Manufacturer -->
                <FormRow>
                    <Label for="manufacturer_id">Manufacturer</Label>
                    <SelectInput name="manufacturer_id" v-model="form.manufacturer_id" class="2/3">
                        <option v-for="man in manufacturers" :key="man.id" :value="man.id">{{man.name}}</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('manufacturer_id')">{{form.errors.first('manufacturer_id')}}</Errors>
                </FormRow>
                
                <!-- Model Number -->
                <FormRow>
                    <Label for="model_number">Model Number</Label>
                    <TextInput name="model_number" v-model="form.model_number" class="2/3"/>
                    <Errors v-if="form.errors.has('model_number')">{{form.errors.first('model_number')}}</Errors>
                </FormRow>

                <!-- Serial Number (Optional) -->
                <FormRow>
                    <Label for="serial_number">Serial Number (Optional)</Label>
                    <TextInput name="serial_number" v-model="form.serial_number" class="2/3"/>
                    <Errors v-if="form.errors.has('serial_number')">{{form.errors.first('serial_number')}}</Errors>
                </FormRow>

                <!-- Warranty Expires (Optional) -->
                <FormRow>
                    <Label for="warranty_expires_at">Warranty Expiry Date (Optional)</Label>
                    <TextInput type="date" name="warranty_expires_at" v-model="form.warranty_expires_at" class="2/3"/>
                    <Errors v-if="form.errors.has('warranty_expires_at')">{{form.errors.first('warranty_expires_at')}}</Errors>
                </FormRow>

                <!-- Model Year (Optional) -->
                <FormRow>
                    <Label for="year">Model Year (Optional)</Label>
                    <TextInput name="year" v-model="form.year" class="2/3"/>
                    <Errors v-if="form.errors.has('year')">{{form.errors.first('year')}}</Errors>
                </FormRow>

                <!-- Room -->
                <FormRow v-show="rooms && rooms.length">
                    <Label for="room_id">Room (optional)</Label>
                    <SelectInput name="room_id" v-model="form.room_id" class="2/3">
                        <option v-for="room in rooms" :key="room.id" :value="room.id">{{room.nickname ? `${room.nickname} (${room.name})` : room.name}}</option>
                    </SelectInput>
                    <Errors v-if="form.errors.has('room_id')">{{form.errors.first('room_id')}}</Errors>
                </FormRow>
                
            </div>

            <!-- Actions -->
            <FormRow>
                <square-button context="primary" class="mr-2" @click="submit">Submit</square-button>
                <square-button context="gray" @click="$modal.hide(name)">Close</square-button>
            </FormRow>
            
        </div>
    </Modal>
</template>

<script>
import Modal from '@components/Modal'
import Form from '@lukejm/form-js'
import { mapGetters } from 'vuex'
import {formDefaults, formConfig} from '@property/Forms/CreateApplianceForm.js'

export default {
    components: {
        Modal,
    },
    data()
    {
        return {
            name: 'create-appliance-modal',
            form: new Form(formDefaults),
            appliance_types: [],
            // manufacturers: [],
            rooms: []
        }
    },
    computed: {
        ...mapGetters({
            room: 'rooms/getRoom',
            property: 'properties/getCurrentProperty',
            manufacturers: 'manufacturers/getManufacturers',
        })
    },
    methods:
    {
        beforeOpen(event)
        {
            // console.log(this.property);
            this.form.room_id = this.room.id
            this.form.property_id = this.property.id || this.room.property.id;
            this.rooms = this.property.rooms;
        },
        submit()
        {
            this.$store.dispatch('properties/addAppliance', this.form.data())
                .then((response) => {
                    this.$modal.hide(this.name)

                    this.$notify({
                        title: 'Success',
                        text: 'Your appliance has been added.',
                        type: 'success'
                    });
                }).catch(error => {
                    this.$notify({
                        title: 'Error',
                        text: error.message,
                        type: 'error'
                    })
                });
        },
        async getApplianceTypes()
        {
            await this.$store.dispatch('properties/getApplianceTypes');
            this.appliance_types = this.$store.getters['properties/getApplianceTypes'];
        },
        async getManufaturers()
        {
            await this.$store.dispatch('manufacturers/getManufacturers');
            // this.manufacturers = this.$store.getters['manufacturers/getManufacturers'];
        }
    },
    created() {
        this.getApplianceTypes();
        this.getManufaturers();

    },
    
}
</script>
