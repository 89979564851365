import User from '@models/User';
import axios from 'axios';

export default class Auth {
    
    /**
     * Get the authenticated user.
     */
    static async getUser() {
        let request = await axios.get('/api/v1/getAuthenticatedUser');
        return request.data?.user || {};
    }

    /**
     * Register a new user.
     */
    static async register(fields) {
        let response = await axios.post('/api/v1/register', fields);
        return response.data.user || {};
    }

    /**
     * Login a user.
     * @param {object} fields 
     */
    static async login(fields) {

        let request = await axios.post('/api/v1/login', fields);
        return request.data?.user || {};
    }

    /**
     * Logout a user.
     * @param {object} fields 
     */
    static async logout(fields) {

        let request = await axios.post('/api/v1/logout', fields);
        console.log(request);
        return request.status == 200;
    }

    /**
     * Get authentication token.
     */
    static async getAuthentificationToken()
    {
        let token = await axios.get('/sanctum/csrf-cookie');
        return token || null;
    }
}