let formDefaults =  {

    // Address Details
    address: {
        value: '',
        rules: 'string',
        section: 'search'
    },
    street: {
        value: '',
        rules: 'required|string',
        section: 'address',
        messages: {
            required: 'The street address is required.'
        }
    },
    unit_number: {
        value: '',
        rules: 'string',
        section: 'address'
    },
    city: {
        value: '',
        rules: 'required|string',
        section: 'address',
        messages: {
            required: 'Please enter a city name.'
        }
    },
    state_id: {
        value: '',
        rules: 'required|integer',
        section: 'address',
        messages: {
            required: 'Please select a province.'
        }
    },
    country_id: {
        value: '',
        rules: 'required|integer',
        section: 'address',
        messages: {
            required: 'Please select a country.'
        }
    },
    postal_code: {
        value: '',
        rules: 'required|string',
        section: 'address'
    },
    // location: {
    //     value: null,
    //     rules: 'object',
    //     section: 'address'
    // },


    // Basic Details
    property_type_id: {
        value: null,
        rules: 'required|integer',
        section: 'basic_details',
        messages: {
            required: 'Please select a property type.'
        }
    },
    bedrooms: {
        value: 2,
        rules: 'required|integer',
        section: 'basic_details'
    },
    bathrooms: {
        value: 2,
        rules: 'required|integer',
        section: 'basic_details'
    },
    nickname: {
        value: '',
        rules: 'string',
        section: 'basic_details'
    },

    // Connection Details
    water_types: {
        value: [],
        rules: 'array',
        section: 'connection_details'
    },
    sewage_types: {
        value: [],
        rules: 'array',
        section: 'connection_details'
    },
    electricity_types: {
        value: [],
        rules: 'array',
        section: 'connection_details'
    },
    heating_types: {
        value: [],
        rules: 'array',
        section: 'connection_details'
    },
    gas_types: {
        value: [],
        rules: 'array',
        section: 'connection_details'
    },
    utilities: {
        value: [],
        section: 'connection_details'
    },
    appliance_types: {
        value: [],
        section: 'appliances'
    },
    
}

let formConfig = {
    axios: {
        withCredentials: true
    }
}

export { formDefaults, formConfig }
