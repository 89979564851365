let formDefaults = {
    title: {
        value: '',
        rules: 'required|string'
    },
    comments: {
        value: '',
        rules: 'string'
    },
    alert_date: {
        value: null,
        rules: 'date'
    }
}

let formConfig = {}

export { formDefaults, formConfig }