import ProjectAPI from '@api/projects';

const state = {
    project: {
        jobs: [],
        tasks: [],
        property: {},
        state: {},
        payments: [],
        plan: {}
    },
    projects: [],
    project_states: [],
    job: {},
    jobs: [],
};
 
const getters = {
    getProject(state)
    {
        return state.project;
    },
    getProjects(state)
    {
        return state.projects;
    },
    getJob(state)
    {
        return state.job;
    },
    getProjectStates()
    {
        return state.project_states;
    },
};

const actions = { 
    async getProject({commit}, id)
    {
        await axios.get(`/api/v1/projects/${id}`)
            .then(response => commit('setProject', response.data.project))
    },

    /**
    * Create a project.
    * @param {object} fields The form fields
    */
    async updateProject({commit}, fields) {
        let successful = await ProjectAPI.update(fields.id, fields);
        return successful;
    },

    // delete
    async deleteProject({commit}, id)
    {
        await axios.delete(`/api/v1/projects/${id}`)
            // .then(response => commit('setProject', response.data.project))
    },

    async getProjects({commit})
    {
        await axios.get(`/api/v1/projects`)
            .then(response => commit('setProjects', response.data.projects))
    },
    async getJob({commit}, id)
    {
        await axios.get(`/api/v1/project-jobs/${id}`)
            .then(response => commit('setJob', response.data.job))
    },
    async getProjectStates({commit})
    {
        await axios.get(`/api/v1/projects/states`)
            .then(response => commit('setProjectStates', response.data.project_states))
    },
    async getTasks({commit}, id)
    {
        await axios.get(`/api/v1/project/${id}/tasks`)
            .then(response => {
                console.log(response.data.tasks);
                commit('setTasks', response.data.tasks);
            })
    },
    async advanceProjectState({commit}, id)
    {
        await axios.post(`/api/v1/projects/${id}/advanceState`)
            .then(response => commit('setProject', response.data.project))
    }
};

const mutations = {
    setProject(state, project)
    {
        state.project = project;
    },
    setProjects(state, projects)
    {
        state.projects = projects;
    },
    setJob(state, job)
    {
        state.job = job;
    },
    setProjectStates(state, project_states)
    {
        state.project_states = project_states;
    },
    setTasks(state, tasks)
    {
        state.project.tasks = tasks;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
