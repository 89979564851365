<template>
    <div class="text-gray-900 flex items-center w-full" :class="`hover:text-gray-600`" @click="$emit('click')">
        <!-- Icon -->
        <v-icon :name="icon" class="mr-4 fill-current" scale="1.2"/>
        <!-- Link -->
        <router-link :to="href" class="flex-1">
            <slot></slot>
        </router-link>

        <!-- Counter -->
        <!-- <div></div> -->
    </div>
</template>

<script>

export default {
    components: {
        //
    },
    props: {
        href: {
            default: '#',
        },
        icon: {
            default: 'home',
            type: String
        },
        counter: {
            default: 0,
            type: Number
        }
    },
}
</script>
