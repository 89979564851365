import RetrofitProgramAPI from '@api/retrofit_programs';

const state = {
    retrofit_programs: [],
    retrofit_program: {
        loans: [],
        grants: [],
        rebates: [],
        property: {}
    }
};
 
const getters = {
    getRetrofitProgram(state)
    {
        return state.retrofit_program;
    },
    getRetrofitPrograms(state)
    {
        return state.retrofit_programs;
    },

};

const actions = { 

    /**
     * Get all retrofit programs.
     */
    async getRetrofitPrograms({commit})
    {
        let retrofit_programs = await RetrofitProgramAPI.findAll();
        commit('setRetrofitPrograms', retrofit_programs);
        return;
    },

    /**
    * Get a retrofit program.
    */
    async getRetrofitProgram({commit}, id)
    {
        let retrofit_program = await RetrofitProgramAPI.find(id);
        commit('setRetrofitProgram', retrofit_program);
        return;
    },

    /**
     * Create a new retrofit program.
     */
    async createRetrofitProgram({commit}, fields)
    {
        let retrofit_program = await RetrofitProgramAPI.create(fields);
        commit('setRetrofitProgram', retrofit_program);
        return;
    },

    /**
    * Update a retrofit program.
    */
    async updateRetrofitProgram({commit}, {id, fields}) {
        let retrofit_program = await RetrofitProgramAPI.update(id, fields);
        commit('setRetrofitProgram', retrofit_program);
        return;
    },

    /**
     * Delete a retrofit program.
     */
    async deleteRetrofitProgram({commit}, id)
    {
        let successful = await RetrofitProgramAPI.delete(id);

        if(successful) 
        {
            commit('setRetrofitProgram', {});    
        }

        return;
    },

    /**
     * Activate a retrofit program.
    */
    async activateRetrofitProgram({commit}, id)
    {
        let successful = await RetrofitProgramAPI.activate(id);
        // commit('setRetrofitProgram', retrofit_program);
        return;
    },

    /**
    * Deactivate a retrofit program.
    */
    async deactivateRetrofitProgram({commit}, id)
    {
        let successful = await RetrofitProgramAPI.deactivate(id);
        // commit('setRetrofitProgram', retrofit_program);
        return;
    },

    /**
    * Create a Grant for a Retrofit Program.
    */
    async createGrant({commit}, {id, fields})
    {
        let grant = await RetrofitProgramAPI.createGrant(id, fields);

        if(grant.id) {
            commit('addGrant', grant);
        }

        return;
    },
};

const mutations = {
    setRetrofitPrograms(state, retrofit_programs = [])
    {
        state.retrofit_programs = retrofit_programs;
    },
    setRetrofitProgram(state, retrofit_program = {})
    {
        state.retrofit_program = retrofit_program;
    },
    addGrant(state, grant)
    {
        state.retrofit_program.grants.push(grant);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
