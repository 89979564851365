const state = {
    utility: {},
    utility_types: [],
    utilities: []
};
 
const getters = {
    getUtility(state) {
        return state.utility;
    },
    getUtilityTypes(state) {
        return state.utility_types;
    },
    getUtilities(state) {
        return state.utilities;
    },
};

const actions = { 
    // async getUtilities({commit})
    // {
    //     await axios.get('/api/v1/utilities', {
    //         user_id: this.getters['auth/getUser'].id
    //     })
    //         .then(response => {
    //             commit('setUtilities', response.data.utilities)
    //         })
    // },
    async setUtility({commit}, utility)
    {
        commit('setUtility', utility)
    },
    async updateUtility({commit, dispatch}, data)
    {
        await axios.patch(`/api/v1/properties/${data.property.id}/utilities/${data.utility.id}`, data.fields)
            .then(response => {
                dispatch('properties/updateUtility', response.data.utility, {root: true})
                commit('setUtility', response.data.utility)
            });
    },
    async deleteUtility({commit, dispatch}, data)
    {
        // console.
        await axios.delete(`/api/v1/properties/${data.property.id}/utilities/${data.utility.id}`)
            .then(response => {
                dispatch('properties/deleteUtility', response.data.utility, {root: true})
                commit('setUtility')
            });
    },
    async setUtilityTypes({commit}) {
        await axios.get(`/api/v1/utility_types`,{
            user_id: this.getters['auth/getUser'].id
        })
            .then(response => commit('setUtilityTypes', response.data.utility_types))
    },
    async setUtilities({commit}) {
        await axios.get(`/api/v1/utilities`,{
            user_id: this.getters['auth/getUser'].id
        })
            .then(response => commit('setUtilities', response.data.utilities))
    },
    async addDocument({commit}, document)
    {   
        commit('addDocument', document);
    },
    
};

const mutations = {
    setUtility(state, utility = {})
    {
        state.utility = utility;
    },
    setUtilityTypes(state, utility_types)
    {
        state.utility_types = utility_types;
    },
    setUtilities(state, utilities)
    {
        state.utilities = utilities;
    },
    addDocument(state, document)
    {   
        if(!state.utility.documents) {
            state.utility.documents = []
        }

        state.utility.documents.push(document);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
