<template>
    <div>
        <!-- Breadcrumb -->
        <div class="mb-10">
            <Breadcrumb @click.prevent="$router.go(`properties/${property.id}`)">Back to Property Overview</Breadcrumb>
        </div>

        <!-- Upper -->
        <div class="flex w-full mb-16">
            
            <!-- Left -->
            <div class="w-2/3">
                <div class="mb-6">
                    <!-- Title -->
                    <PropertyTitle id="name" class="text-lg" :class="['mb-2']">{{room.name}}</PropertyTitle>
                    <!-- Property Type -->
                    <div id="nickname" class="mb-6">{{room.nickname}}</div>

                    <div class="mb-4">
                        <table class="">
                             <!-- Flooring -->
                            <tr>
                                <td class="pr-8">Flooring: </td>
                                <td>
                                    <div v-if="room.floor_type" id="floor_type" class="text-gray-500">{{ room.floor_type }}</div>
                                    <span v-else class="text-gray-500">Unknown</span>
                                </td>
                            </tr>
                            <!-- Storey -->
                            <tr>
                                <td class="pr-8">Storey: </td>
                                <td>
                                    <div v-if="room.storey" class="text-gray-500">
                                        <span id="storey">{{ room.storey }}</span>
                                        {{ room.storey == 1 ? " - Ground" : null }}
                                    </div>
                                    <span v-else class="text-gray-500">Unknown</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <!-- Buttons -->
                <div>
                    <!-- <router-link :to="{name: 'service_requests.create', params: {property: property}}">
                        <SquareButton icon-left="wrench" class="mr-2">Request a Service</SquareButton>
                    </router-link> -->
                    <SquareButton icon-left="pen" context="gray" @click="$modal.show('update-room-modal')">Edit Info</SquareButton>
                </div> 
            </div>

            <!-- Right -->
            <div class="w-1/3">
                <!-- Photo -->
                <!-- <div class="w-full bg-gray-100 rounded overflow-hidden">
                    <img :src="image(property.image)" alt="property.address">
                </div> -->
            </div>
        </div>

        <!-- Appliances -->
        <div id="room-appliances" class="mb-16">
            <div class="flex items-center mb-8">
                <h2 class="text-lg mr-4">Appliances</h2>
                <AddButton id="add-appliance-button" @click="$modal.show('create-appliance-modal', {property: room.property, room})"></AddButton>
            </div>
            <div class="mb-4 w-full">
                <div class="inline" v-if="(room.appliances && room.appliances.length)">
                    <ApplianceCard v-for="appliance in room.appliances" :key="appliance.id" :appliance="appliance" @click="showApplianceModal(appliance)"></ApplianceCard> 
                </div>
                <!-- <CreateApplianceCard v-else :room="room" :property="room.property"></CreateApplianceCard> -->
            </div>
        </div>

        <!-- Documents -->
        <div id="room-documents" class="mb-16">
            <div class="flex items-center mb-8">
                <h2 class="text-lg mr-4">Documents</h2>
                <AddButton id="add-document-button" @click="$modal.show('add-document-modal', {documentable: 'room'})"></AddButton>
            </div>
            
            <div class="flex">
                <DocumentButton v-for="document in room.documents" :key="document.id" :document="document"></DocumentButton>
            </div>

            <div v-if="room.documents && !room.documents.length">
                <p>You do not have any documents yet.</p>
            </div>
        </div>


        <!-- Service Records -->
        <div id="room-service-records" class="mb-16">
            <div class="flex items-center mb-8">
                <h2 class="text-lg mr-4">Service Records</h2>
                <AddButton id="add-service-record-button" @click="$modal.show('create-service-record-modal', {recordable: 'room'})"></AddButton>
            </div>
            <div>
                <ServiceRecordCard v-for="record in room.service_records" :key="record.id" :service-record="record" :expanded="visible_record == record.id" @click="setServiceRecord(record.id)"></ServiceRecordCard>
            </div>

            <div v-if="room.service_records && !room.service_records.length">
                <p>You do not have any service records yet.</p>
            </div>
        </div>

        <!-- <AddDocumentModal></AddDocumentModal> -->
        <ApplianceModal></ApplianceModal>
        <CreateApplianceModal></CreateApplianceModal>
        <CreateServiceRecordModal></CreateServiceRecordModal>
        <UpdateApplianceModal></UpdateApplianceModal>
        <UpdateRoomModal></UpdateRoomModal>
        <AddDocumentModal></AddDocumentModal>
    </div>

    
</template>

<script>
import Breadcrumb from '@components/Breadcrumb'
import PropertyTitle from '@property/PropertyTitle'
import ApplianceCard from '../Components/Appliance/ApplianceCard.vue'
import SectionTitle from '@components/SectionTitle'
import ApplianceModal from '../Components/Appliance/ApplianceModal.vue'
import CreateApplianceCard from '../Components/CreateApplianceCard.vue'
import CreateApplianceModal from '../Components/Appliance/CreateApplianceModal.vue'
import AddButton from '../../Components/Buttons/AddButton.vue'
import CreateServiceRecordModal from '../Components/CreateServiceRecordModal.vue'
import ServiceRecordCard from '../Components/ServiceRecordCard.vue'
import { mapGetters } from 'vuex'
import AddDocumentModal from '../Components/AddDocumentModal.vue'
import DocumentButton from '../../Document/DocumentButton.vue'
import UpdateApplianceModal from '../Components/Appliance/UpdateApplianceModal.vue'
import UpdateRoomModal from '../Components/Room/UpdateRoomModal.vue'

export default {
    title: 'Privacy Policy',
    components: {
        Breadcrumb,
        PropertyTitle,
        ApplianceCard,
        SectionTitle,
        ApplianceModal,
        CreateApplianceCard,
        CreateApplianceModal,
        AddButton,
        CreateServiceRecordModal,
        ServiceRecordCard,
        AddDocumentModal,
        DocumentButton,
        UpdateApplianceModal,
        UpdateRoomModal
    },
    data() {
        return {
            visible_record: null
        }
    },
    computed: {
        ...mapGetters({
            room: 'rooms/getRoom',
            appliance: 'appliances/getAppliance',
            property: 'properties/getCurrentProperty'
        })
    },
    methods: {
        async showApplianceModal(appliance)
        {
            this.$store.commit('appliances/setAppliance', appliance);
            this.$modal.show('appliance-modal', {appliance: appliance})
        },
        setServiceRecord(id)
        {
            let service_record = this.room.service_records.filter(record => record.id == id)[0]

            this.$store.dispatch('service_records/setServiceRecord', service_record);

            // Set accordian visibility
            this.visible_record = this.visible_record == id ? null : service_record.id;
        },
    },
    async created()
    {
        // Get Room
        await this.$store.dispatch('rooms/getRoom', this.$route.params.id);

        document.title = `${this.room.nickname ?? this.room.name} | ${process.env.MIX_APP_NAME}`;

        // Load property if doesnt exist
        if(!this.property.id) {
            await this.$store.dispatch('properties/getProperty', this.room.property.id);
        }
    },
    // async destroyed()
    // {
    //     await this.$store.dispatch('rooms/setRoom');
    // }
}
</script>

<style>

</style>